import { Button } from '@mui/material';
import styled from 'layout/theme/styled';
import { keyframes } from '@emotion/react';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { Link } from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export const Wrapper = styled('div')({});

export const TopContainer = styled('div')({
  display: 'flex',
  gap: '8px',
  margin: '0 0 24px',
  '@media(max-width: 600px)': {
    flexWrap: 'wrap',
  },
});

export const Logo = styled('img')({
  width: '196px',
  maxHeight: '150px',
  objectFit: 'contain',
  objectPosition: 'top',
  padding: '18px 18px 18px 0',
});

export const LogoWide = styled(Logo)({
  width: '250px',
});

export const LogoExtraWide = styled(Logo)({
  width: 'auto',
});

export const DescriptionContainer = styled('div')({ maxWidth: '1250px', width: '100%', zIndex: 1 });

export const Title = styled('h2')({ margin: '0 auto 12px 0', textAlign: 'left' });

export const Subtitle = styled('h3')({ margin: '0 0 12px' });

export const Description = styled('div')({
  textAlign: 'left',
  marginRight: 'auto',
});

export const BackNavigator = styled(Link)(
  ({
    theme: {
      palette: { black },
    },
  }) => ({
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer',
    ':visited': { color: 'inherit' },
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    transition: '250ms',
    position: 'relative',
    left: '-9px',
    padding: '4px 4px 4px 0',
    ':hover': {
      color: black,
    },
  })
);

export const BackText = styled('p')({
  margin: 0,
});

export const BackNavigatorArrow = styled(ArrowLeftIcon)({
  width: '32px',
  height: '32px',
});

export const DescriptionText = styled('p')({});

export const AppFeatures = styled('ul')({});

export const AppFeature = styled('li')({
  lineHeight: '1.8',
  fontWeight: '500',
  transition: '300ms',
  width: 'fit-content',
  paddingInlineStart: '20px',
});

export const DocumentationBody = styled('div')({
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 0 32px',
  marginTop: '0',
  zIndex: 0,
  '@media(min-width: 1650px)': {
    padding: '0 200px 32px 0',
  },
});

export const CenteredParagraph = styled('p')(
  ({
    theme: {
      typography: { body1 },
    },
  }) => ({
    ...body1,
    textAlign: 'center',
  })
);

const ButtonFrames = keyframes({
  from: {
    left: '-50px',
  },
  '85%': {
    left: '-50px',
  },
  to: {
    left: '110%',
  },
});

export const VisitPageButton = styled(Button)(
  ({
    theme: {
      palette: { black, white, primary },
    },
  }) => ({
    background: `linear-gradient(#e4be65, #ed8228)`,
    fontWeight: '600',
    cursor: 'pointer',
    margin: '0 0 0 auto',
    color: primary.dark,
    borderRadius: '32px',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    zIndex: 1000,
    '@media(min-width: 700px)': {
      position: 'fixed',
      top: '224px',
      right: '32px',
      ':after': {
        content: '""',
        height: '200%',
        position: 'absolute',
        left: '-50px',
        zIndex: 1000,
        backgroundColor: white,
        opacity: '0.3',
        width: '18px',
        animation: `${ButtonFrames} 3s ease-out infinite`,
        rotate: '45deg',
        boxShadow: '-9px 14px 10px white',
      },
    },
    padding: '13px 22px',
    ':hover': {
      color: black,
      boxShadow: `2px 2px 8px ${black}ff`,
    },
  })
);

export const BlockVisitButton = styled(VisitPageButton)({
  position: 'relative !important' as 'relative',
  top: 'unset !important',
  right: 'unset !important',
  margin: '8px auto',
  width: '176px',
  left: '12px',
});

export const ButtonLabel = styled('span')({ zIndex: 1 });

export const TableOfContent = styled('ol')({
  zIndex: 1,
  margin: '0 auto 32px 0',
  maxWidth: '1250px',
  width: '100%',
  padding: '0 20px',
});

export const NestedUnordered = styled('ul')({
  margin: '4px 0',
  lineHeight: '110%',
});

export const UnorderedList = styled('ul')({
  textAlign: 'left',
  paddingInlineStart: '20px',
  marginRight: 'auto',
});

export const LeftP = styled('p')({
  textAlign: 'left',
  margin: '0 0 16px',
});

export const ListItem = styled('li')(
  ({
    theme: {
      palette: {
        primary: { main },
      },
    },
  }) => ({
    lineHeight: '150%',
    strong: {
      color: main,
    },
  })
);

export const TableItem = styled(AppFeature)<{ props?: { disabled?: boolean } }>(
  ({
    theme: {
      palette: { primary },
    },
    props: { disabled } = { disabled: false },
  }) => ({
    color: primary.main,
    ...(disabled
      ? {}
      : {
          cursor: 'pointer',
          ':hover': {
            color: primary.dark,
            textDecoration: 'underline',
          },
        }),
  })
);

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
  cursor: 'pointer',
  ':hover': { textDecoration: 'underline' },
  ':visited': { color: 'inherit' },
});

export const NoBulletItem = styled('li')({
  listStyle: 'none',
});

export const Article = styled('article')({
  margin: '-132px 0 16px',
  padding: '148px 0 0',
  maxWidth: '1250px',
  width: '100%',
});

export const Section = styled('section')({
  margin: '0 0 24px',
  maxWidth: '1250px',
  width: '100%',
  zIndex: 1,
});

export const FlexSection = styled(Section)({
  display: 'flex',
  flexDirection: 'row-reverse',
  flexWrap: 'wrap',
  gap: '12px',
});

export const MoviesSection = styled(Section)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  div: { flexGrow: '1', flexBasis: '300px' },
});

export const ArticleTitle = styled('a')({
  fontSize: '18px',
  fontWeight: '600',
  margin: '0 0 18px',
  textDecoration: 'none',
});

export const ArticleSteps = styled('ol')({
  display: 'flex',
  flexDirection: 'column',
});

export const ArticleStep = styled('li')({
  width: 'fit-content',
  margin: '0 0 8px 20px',
  p: {
    marginTop: '0',
  },
});

export const Image = styled('img')({
  margin: '12px 0',
  objectFit: 'contain',
  maxWidth: '1250px',
  width: '100%',
  height: 'auto',
});

export const SmallImage = styled(Image)({
  maxWidth: '750px',
});

export const TinyImage = styled(Image)({
  maxWidth: '450px',
});

export const InlineIcon = styled('img')({
  display: 'inline-block',
  width: '20px',
  height: '20px',
  objectFit: 'contain',
  margin: '0 4px',
});

export const Icon = styled('img')({
  width: '48px',
  height: '48px',
  objectFit: 'contain',
  margin: '0 auto 8px',
});

export const WorkflowImage = styled('img')({
  width: '100%',
  height: '600px !important',
  objectPosition: 'center !important',
});

export const ContainImage = styled('img')({
  width: '100%',
  height: 'fit-content !important',
  objectPosition: 'center !important',
  objectFit: 'contain !important' as 'contain',
});

export const BigContainImage = styled(ContainImage)({
  height: 'fit-content !important',
  minHeight: '156px',
});

export const INTWorkflowImage = styled('img')({
  width: '100%',
  height: 'fit-content !important',
  objectPosition: 'center !important',
  objectFit: 'contain !important' as 'contain',
  maxHeight: '500px',
});

export const CodeWrapper = styled('div')({
  div: {
    maxWidth: '1250px !important',
  },
});

export const FlexArticle = styled(Article)({
  display: 'flex',
  flexDirection: 'row-reverse',
  flexWrap: 'wrap',
  gap: '12px',
});

export const Flex = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
  width: '100%',
});

export const IntericaArrowContainer = styled(Flex)({
  alignItems: 'center',
  '@media(min-width: 1020px)': {
    flexWrap: 'nowrap',
  },
  '@media(max-width: 740px)': {
    flexDirection: 'column',
  },
  margin: '0 0 16px',
  justifyContent: 'center',
});

export const IntericaBox = styled('div')(
  ({
    theme: {
      palette: {
        primary: { main },
      },
    },
  }) => ({
    minWidth: '300px',
    flexBasis: '50px',
    border: `2px solid ${main}`,
    borderRadius: '8px',
    padding: '8px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    h3: {
      margin: '0 0 8px',
      textAlign: 'center',
    },
    p: {
      textAlign: 'center',
      margin: 0,
    },
  })
);

export const Column = styled('div')({
  flexBasis: '400px',
  flexGrow: 1,
});

export const FlexBody = styled('div')({
  flexBasis: '800px',
  flexGrow: '1',
});

export const HintBox = styled('div')(
  ({
    theme: {
      palette: {
        primary: { main },
      },
    },
  }) => ({
    border: `4px solid ${main}`,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '200px',
    flexGrow: '1',
  })
);

export const HintIcon = styled(EmojiObjectsIcon)(
  ({
    theme: {
      palette: {
        primary: { main },
      },
    },
  }) => ({
    color: main,
    margin: '0 auto 12px',
    width: '38px',
    height: '38px',
  })
);

export const CenteredColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '4px 0',
  margin: '0 0 auto',
});

export const HeavyText = styled('p')({
  fontWeight: '800',
  color: 'white',
  maxWidth: '150px',
  textAlign: 'center',
  margin: '0',
});

// verify below

export const StyledAnchor = styled('a')({
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '12px',
  marginLeft: '30px',
  marginRight: '30px',
});

export const PartnerLogo = styled('img')({
  height: '50px',
  objectFit: 'contain',
});

export const Lining47AsPartnerLogo = styled('img')({
  height: '58px',
  objectFit: 'contain',
});

export const WideLogo = styled('img')({
  height: '45px',
  objectFit: 'contain',
});
