import { useState, MouseEvent, ReactNode, useRef, LegacyRef } from 'react';
import { Collapse, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from 'layout/components/Popper/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import * as S from './CommonMenu.style';

interface IMenuOption {
  name: string;
  action: () => void;
  divider?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
}

interface ICommonMenu {
  handleMenuChange?: () => void;
  title: string;
  icon: ReactNode;
  menuOptions: IMenuOption[];
}

export default function CommonMenu({
  title = 'Help',
  menuOptions,
  handleMenuChange,
  icon,
}: ICommonMenu) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const accordionRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setIsMenuOpen(!isMenuOpen);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const handleClose = () => setAnchorEl(null);
  const handleClickAway = () => setAnchorEl(null);

  const handleMenuSelect = (menuHandler: () => void) => {
    menuHandler();
    handleClose();
  };

  const MenuItems = menuOptions.map(
    ({ name, action, divider, disabled, icon }: IMenuOption, index: number) => {
      return (
        <S.StyledMenuItem
          onClick={() => handleMenuSelect(action)}
          onChange={handleMenuChange}
          dense
          key={`kelpItem_${index}`}
          divider={divider}
          disabled={disabled}
        >
          {icon} <S.ItemText>{name}</S.ItemText>
        </S.StyledMenuItem>
      );
    }
  );

  return (
    <>
      {!isDesktop && (
        <Collapse ref={accordionRef} in={isMenuOpen}>
          {MenuItems}
        </Collapse>
      )}
      <ClickAwayListener onClickAway={handleClickAway}>
        <S.MenuWrapper onClick={handleClick}>
          <S.Title variant="body2">{title}</S.Title>
          {icon}
          <S.Button aria-label={'open/close menu'} color="inherit" size="large">
            {isDesktop ? <ArrowDropDownIcon /> : <S.MobileArrow props={{ isOpen: isMenuOpen }} />}
          </S.Button>
          {isDesktop && (
            <Popper anchorEl={anchorEl} handleClose={handleClose} placement="bottom-end">
              <S.MenuContainer>{MenuItems}</S.MenuContainer>
            </Popper>
          )}
        </S.MenuWrapper>
      </ClickAwayListener>
    </>
  );
}
