import Button from '@mui/material/Button';
import styled from 'layout/theme/styled';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EditIcon from '@mui/icons-material/Edit';
import Collapse from '@mui/material/Collapse';

export const Wrapper = styled('article')(
  ({
    theme: {
      palette: {
        border: { main },
      },
    },
  }) => ({
    minHeight: '52px',
    display: 'flex',
    flexDirection: 'column',
    borderColor: main,
    border: 'none',
    borderBottom: `1px solid ${main}`,
    width: '100%',
    boxSizing: 'border-box',
    padding: '0',
  })
);

export const HeaderRow = styled('div')<{ props: { isSelected: boolean } }>(
  ({
    props: { isSelected },
    theme: {
      palette: { grey2, white },
    },
  }) => ({
    margin: '0',
    padding: '8px 0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexWrap: 'wrap',
    backgroundColor: isSelected ? grey2 : white,
    transition: '250ms',
    gap: '8px',
  })
);

export const ArrowButton = styled(Button)({
  borderRadius: '100%',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const Arrow = styled('img')<{ props: { isOpen?: boolean } }>(({ props: { isOpen } }) => ({
  transform: `rotate(${isOpen ? 180 : 0}deg)`,
  transition: '250ms',
}));

export const IDField = styled('p')(
  ({
    theme: {
      typography: { body2 },
      palette: {
        text: { primary },
      },
    },
  }) => ({
    margin: '0',
    flexGrow: 2,
    flexBasis: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...body2,
    color: primary,
  })
);

export const WideField = styled(IDField)({
  flexGrow: 5,
  flexBasis: '100px',
  '@media(min-width: 630px)': {
    flexBasis: '200px',
  },
});

export const StandardField = styled('div')({
  flexBasis: '160px',
  flexGrow: 'unset',
  display: 'flex',
});

export const NarrowField = styled(IDField)({
  flexGrow: 1,
  flexBasis: '100px',
});

export const Capitalize = styled('span')({ textTransform: 'capitalize' });

const IconStyles = { width: '16px', height: '16px' };

export const ReadIcon = styled(AutoStoriesIcon)(IconStyles);

export const EditionIcon = styled(EditIcon)(IconStyles);

export const Accordion = styled(Collapse)(
  ({
    theme: {
      palette: {
        border: { main },
      },
    },
  }) => ({
    borderBottom: `1px solid ${main}`,
    width: '100%',
  })
);

export const DescriptionContainer = styled('div')(
  ({
    theme: {
      palette: {
        border: { main },
      },
    },
  }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    borderTop: `1px solid ${main}`,
    borderBottom: `1px solid ${main}`,
    width: '100%',
    boxSizing: 'border-box',
    padding: '8px 0',
    ['@media(min-width: 760px)']: {
      padding: '8px 0 8px 58px',
    },
  })
);
