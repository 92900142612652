export interface ISchemaItem {
  schemaIdentity: {
    authority: string;
    source: string;
    entityType: string;
    schemaVersionMajor: number;
    schemaVersionMinor: number;
    schemaVersionPatch: number;
    id: string;
  };
  createdBy: string;
  dateCreated: Date;
  status: string;
  scope: string;
}

export const emptySchema: ISchemaItem = {
  schemaIdentity: {
    authority: '',
    source: '',
    entityType: '',
    schemaVersionMajor: 1,
    schemaVersionMinor: 0,
    schemaVersionPatch: 0,
    id: '',
  },
  createdBy: '',
  dateCreated: new Date(),
  status: 'DEVELOPMENT',
  scope: 'INTERNAL',
};

export interface ISchemaPayload {
  offset?: number;
  limit?: number;
  status?: string;
  scope?: string;
  authority?: string;
  source?: string;
  entityType?: string;
  schemaVersionMajor?: string;
  schemaVersionMinor?: string;
  latestVersion?: boolean;
}

export interface ISchemaList extends ISchemaPayload {
  dataPartition?: string;
}

export interface ISchemaListResp {
  offset: number;
  count: number;
  totalCount: number;
  schemaInfos: ISchemaItem[];
}

export const statusValidOptions = [
  {
    label: 'Published',
    value: 'PUBLISHED',
  },
  {
    label: 'Development',
    value: 'DEVELOPMENT',
  },
  {
    label: 'Obsolete',
    value: 'OBSOLETE',
  },
]

export const statusOptions = [
  ...statusValidOptions,
  {
    label: 'All',
    value: '',
  },
];

export const scopeOptions = [
  {
    label: 'Shared',
    value: 'SHARED',
  },
  {
    label: 'Internal',
    value: 'INTERNAL',
  },
  {
    label: 'All',
    value: '',
  },
];

export const initialPagination = {
  page: 0,
  rowsPerPage: 10,
  count: 0,
};
