import { ReactNode, useState, useRef, LegacyRef, ChangeEvent, useEffect } from 'react';
import { Tooltip, useMediaQuery } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import PlatformStatusComponent from './Components/PlatformStatusComponent';
import useDebounce from 'hooks/useDebounce';
import { Auth } from 'aws-amplify';
import jwtParser from 'Utils/jwtParser';
import { RootState, settingsActions } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './SideBar.style';

interface SideBarProps {
  onClose: (e: Event) => void;
  open: boolean;
  variant?: 'permanent' | 'persistent' | 'temporary';
  items: ReactNode;
  userMenu: ReactNode;
}

export default function SideBar({ onClose, open, variant, items, userMenu }: SideBarProps) {
  const {
    user: { attributes },
  } = useAuthenticator(({ user }) => [user]);

  const { isMultiTenantHost, isPlatformAdmin } = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();

  const { setDataPartitionId } = settingsActions;
  const [tempDataPartitionId, setTempDataPartitionId] = useState('');
  const debounceDataPartition = useDebounce(tempDataPartitionId, 500);

  useEffect(() => {
    dispatch(setDataPartitionId(debounceDataPartition));
  }, [debounceDataPartition]);

  useEffect(() => {
    if (attributes && 'custom:data_partition_id' in attributes) {
      const partition = attributes['custom:data_partition_id'] || 'osdu';
      return setTempDataPartitionId(partition as string);
    } else {
      Auth.currentSession()
        .then((session) => {
          const rawToken = session.getIdToken().getJwtToken();
          const parsed = jwtParser(rawToken);
          const dataPartitionId = 'partition_id' in parsed ? parsed['partition_id'] : 'osdu';
          setTempDataPartitionId(dataPartitionId as string);
        })
        .catch(() => setTempDataPartitionId('osdu'));
    }
  }, []);

  const [isHover, setIsHover] = useState(false);
  const menuRef: LegacyRef<HTMLUListElement> = useRef(null);
  const menuWrapperRef: LegacyRef<HTMLDivElement> = useRef(null);

  const hasMenuScroll =
    menuRef.current && menuWrapperRef.current
      ? menuRef.current.scrollHeight > menuWrapperRef.current.scrollHeight
      : false;

  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return (
    <S.StyledDrawer anchor="left" onClose={onClose} open={open} variant={variant}>
      <PlatformStatusComponent />
      {(!isMultiTenantHost || isPlatformAdmin) && (
        <Tooltip title={'Data partition ID'} placement="top">
          <S.DataPartitionWrapper>
            <S.Input
              value={tempDataPartitionId}
              onChange={(e: ChangeEvent<{ value: string }>) =>
                setTempDataPartitionId(e.target.value)
              }
              placeholder="The id of the data partition to be searched. Partitions are managed by the partition service."
            />
          </S.DataPartitionWrapper>
        </Tooltip>
      )}
      <S.MenuWrapper
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        ref={menuWrapperRef}
      >
        <S.ScrollIndicatorTop props={{ isVisible: hasMenuScroll && isHover }} />
        <S.StyledList ref={menuRef}>{items}</S.StyledList>
        <S.ScrollIndicatorBottom props={{ isVisible: hasMenuScroll && isHover }} />
      </S.MenuWrapper>
      {!isDesktop && <S.UserMenu>{userMenu}</S.UserMenu>}
    </S.StyledDrawer>
  );
}
