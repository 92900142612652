import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPlatformState } from 'store/platformStateSelectors';
import { SecondsToTimeFragments } from './general';

export default function usePlatformTimeRemaining() {
  const { platformStateTermination } = useSelector(selectPlatformState);
  const [ttl, setTtl] = useState(0);

  useEffect(() => {
    if (!platformStateTermination) {
      return;
    }
    const timerInterval = setInterval(() => {
      const now = new Date();
      const terminationTime = new Date(platformStateTermination);
      const timeLeft = terminationTime.getTime() - now.getTime();
      setTtl(timeLeft / 1000);
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [platformStateTermination]);

  if (!platformStateTermination) {
    return '';
  }

  const readableTTL = ttl > 0 ? SecondsToTimeFragments(ttl).display : '';

  return readableTTL;
}
