import { useRef, useState, useEffect, CSSProperties } from 'react';
import * as S from './CommandPreview.style';

export type ICommandRow = { indentLevel: 0 | 1 | 2 | 3 | 4; text: string; backgroundColor?: string };

interface ICommandPreview {
  title?: string;
  subtitle?: string;
  description?: string;
  command: ICommandRow[];
  isOpenDefault?: boolean;
  isCollapsible?: boolean;
  copyCommand?: () => void;
  style?: CSSProperties;
}

export default function CommandPreview({
  title,
  subtitle,
  description,
  command,
  isOpenDefault = true,
  isCollapsible = false,
  copyCommand,
  style,
}: ICommandPreview) {
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  const ref = useRef();
  const indentLevels = {
    0: S.IndentLevelZero,
    1: S.IndentLevelOne,
    2: S.IndentLevelTwo,
    3: S.IndentLevelTree,
    4: S.IndentLevelFour,
  };

  useEffect(() => {
    if (isOpenDefault !== isOpen) {
      setIsOpen(isOpenDefault);
    }
  }, [isOpenDefault]);

  return (
    <S.CurlWrapper props={{ style }}>
      {title && <h4>{title}</h4>}
      {description && <S.Description>{description}</S.Description>}
      {subtitle && <h5>{subtitle}</h5>}
      <S.Row>
        {isCollapsible && (
          <S.StyledButton onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? 'Hide' : 'View'} command
          </S.StyledButton>
        )}
        {copyCommand && (
          <S.StyledButton onClick={copyCommand}>Copy with credentials</S.StyledButton>
        )}
      </S.Row>
      <S.CurlCommandContainer in={isOpen} ref={ref}>
        {command.map(({ indentLevel, text, backgroundColor }, index) => {
          const Level = indentLevels[indentLevel];
          return (
            <Level props={{ color: backgroundColor }} key={index}>
              {text}
            </Level>
          );
        })}
      </S.CurlCommandContainer>
    </S.CurlWrapper>
  );
}
