import styled from 'layout/theme/styled';

export const Parameter = styled('div')(
  ({
    theme: {
      palette: {
        border: { main },
        text: { primary },
      },
      typography: { body2 },
    },
  }) => ({
    display: 'flex',
    margin: '0 16px 8px 16px',
    borderBottom: `1px solid ${main}`,
    padding: '0 0 4px',
    maxWidth: '100%',
    flexWrap: 'wrap',
    ...body2,
    color: primary,
  })
);

export const Label = styled('label')({
  fontWeight: '600',
  width: 'fit-content',
  margin: '0 4px 0 0',
  lineHeight: '160%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
});

export const Value = styled('p')({
  margin: 0,
  lineHeight: '160%',
  flexGrow: '1',
});
