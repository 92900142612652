import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import awsAuthRoutes from 'Utils/awsAuthRoutes';
import pages from 'pages';
import RPage from 'pages/AuthPage/RPage';
import Toast from 'components/Toast/Toast';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, settingsActions, platformStateActions } from 'store';
import SignOutPage from 'pages/AuthPage/SignOutPage';

const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL as string;
const IS_MULTI_TENANT_HOST = process.env.REACT_APP_IS_MULTI_TENANT_HOST as string;

export default function App() {
  const { route, user: { username } = { username: '' } } = useAuthenticator(
    ({ route, user, isPending }) => [route, user, isPending]
  );
  const toastState = useSelector((state: RootState) => state.settings.toastState);
  const { setToastState, setIsPlatformAdmin, setIsMultiTenantHost } = settingsActions;
  const { getPlatformState } = platformStateActions;
  const dispatch = useDispatch();

  const handleTurnOff = () => dispatch(setToastState({ errorSeverity: 'info', message: '' }));

  useEffect(() => {
    if (!username) {
      return;
    }

    dispatch(setIsPlatformAdmin(ADMIN_EMAIL === username));
    dispatch(
      setIsMultiTenantHost(['true', '1'].includes(IS_MULTI_TENANT_HOST.toLocaleLowerCase()))
    );
  }, [ADMIN_EMAIL, username, IS_MULTI_TENANT_HOST]);

  useEffect(() => {
    if (!username) {
      return;
    }
    const interval = setInterval(() => {
      dispatch(getPlatformState());
    }, 10000);
    return () => clearInterval(interval);
  }, [username]);

  const loggedInRouter = (
    <Routes>
      {pages.map((page) => {
        if (page.component) {
          const Page = page.component;
          return <Route path={page.path} element={<Page />} key={page.path} />;
        }
        return null;
      })}
      <Route path="/" element={<Navigate to="/Home" />} />
      <Route path="/login" element={<Navigate to="/Home" />} />
      <Route path="/r" element={<RPage route={route} />} />
      <Route path="/logout" element={<SignOutPage />} />
      <Route path="*" element={<Navigate to="/Home" />} />
    </Routes>
  );

  const authRouter = (
    <Routes>
      <Route path="/login" element={<div />} />
      <Route path="/r" element={<div />} />
      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );

  return (
    <>
      {awsAuthRoutes.includes(route) ? authRouter : loggedInRouter}
      <Toast {...toastState} timeout={3000} handleTurnOff={handleTurnOff} />
    </>
  );
}
