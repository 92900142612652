import { ICustomSwaggerPage } from 'pages/CustomSwaggerPage/CustomSwaggerPage';
import * as yup from 'yup';

const entitlementsSwagger: ICustomSwaggerPage = {
  header: 'Entitlements service',
  description: `Entitlements service is used to enable authorization in OSDU Data Ecosystem. The service allows for the creation of groups. A group name defines a permission. Users who are added to that group obtain that permission.
  The main motivation for entitlements service is data authorization, but the functionality enables three use cases: data groups, service groups and user groups`,
  endpoints: [
    {
      path: '/api/entitlements/v2/groups',
      methods: [
        {
          method: 'GET',
          id: '1',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
          },
          responses: {
            '200': {
              desId: 'string',
              memberEmail: 'string',
              groups: [{ name: 'string', description: 'string', email: 'string' }],
            },
          },
          description: `List all groups`,
        },
        {
          method: 'POST',
          id: '2',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            body: [
              {
                name: 'name',
                type: 'string',
              },
              {
                name: 'description',
                type: 'string',
                description: 'Available values: OWNER, MEMBER',
              },
            ],
          },
          responses: {
            '201': { name: 'string', description: 'string', email: 'string' },
          },
          description: `Create group`,
        },
      ],
    },
    {
      path: '/api/entitlements/v2/groups',
      methods: [
        {
          method: 'PATCH',
          id: '1',
          pathParamPattern: '/{groupEmail}',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            path: [
              {
                name: 'groupEmail',
                type: 'string',
                required: true,
              },
            ],
            body: [
              {
                name: 'updateGroupRequest',
                type: 'array',
                required: true,
                schema: yup.array().of(yup.object()),
              },
            ],
          },
          responses: {},
          description: `Update group.`,
        },
        {
          method: 'DELETE',
          id: '2',
          pathParamPattern: '/{groupEmail}',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            path: [
              {
                name: 'groupEmail',
                type: 'string',
                required: true,
              },
            ],
          },
          responses: {
            '204': 'No Content',
            '401': 'Unauthorized',
            '403': 'Forbidden',
          },
          description: `This allows for the removal of groups by group email.`,
        },
      ],
    },
    {
      path: '/api/entitlements/v2/groups',
      methods: [
        {
          method: 'GET',
          id: '1',
          pathParamPattern: '/{groupEmail}/members',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            path: [
              {
                name: 'groupEmail',
                type: 'string',
                required: true,
              },
            ],
            params: [
              {
                name: 'role',
                type: 'string',
                description: 'Available values: OWNER, MEMBER',
              },
              // // TODO: uncomment when boolean field available
              // {
              //   name: 'includeType',
              //   type: 'string',
              // },
            ],
          },
          responses: {},
          description: `List members of a given group`,
        },
        {
          method: 'POST',
          id: '2',
          pathParamPattern: '/{groupEmail}/members',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            path: [
              {
                name: 'groupEmail',
                type: 'string',
                required: true,
              },
            ],
            body: [
              {
                name: 'email',
                type: 'string',
                description: 'Users email address',
              },
              {
                name: 'role',
                type: 'string',
                description: 'Available values: OWNER, MEMBER',
              },
            ],
          },
          responses: {
            '200': {
              email: 'string',
              role: 'string',
            },
          },
          description: `Add member to a group`,
        },
        {
          method: 'DELETE',
          id: '3',
          pathParamPattern: '/{groupEmail}/members/{memberEmail}',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            path: [
              {
                name: 'groupEmail',
                type: 'string',
                required: true,
              },
              {
                name: 'memberEmail',
                type: 'string',
                required: true,
              },
            ],
          },
          responses: {
            '204': 'No Content',
            '401': 'Unauthorized',
            '403': 'Forbidden',
          },
          description: `This allows for the removal of member from a group.`,
        },
      ],
    },
    {
      path: '/api/entitlements/v2/members',
      methods: [
        {
          method: 'GET',
          id: '1',
          pathParamPattern: '/{groupEmail}/groups',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            path: [
              {
                name: 'groupEmail',
                type: 'string',
                required: true,
              },
            ],
            params: [
              {
                name: 'type',
                type: 'string',
              },
              {
                name: 'apiid',
                type: 'string',
              },
            ],
          },
          responses: {
            400: {
              message: 'Request failed with status code 400',
              code: 'ERR_BAD_REQUEST',
              status: 400,
              data: {
                code: 400,
                reason: 'Bad Request',
                message: 'Invalid filter',
              },
            },
          },
          description: `For deployment available public /info endpoint, which provides build and git related information.`,
        },
      ],
    },
    {
      path: '/api/entitlements/v2/info',
      methods: [
        {
          method: 'GET',
          id: '1',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
          },
          responses: {
            200: {
              groupId: 'string',
              artifactId: 'string',
              version: 'string',
              buildTime: 'Date',
              branch: 'string',
              commitId: 'string',
              commitMessage: 'string',
              connectedOuterServices: [],
            },
          },
          description: `For deployment available public /info endpoint, which provides build and git related information.`,
        },
      ],
    },
  ],
};

export default entitlementsSwagger;
