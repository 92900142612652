import { ChangeEvent, useState, useRef, LegacyRef, FormEvent, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { API } from '@aws-amplify/api';
import Modal from 'components/Modal/Modal';
import Paper from '@mui/material/Paper';
import RecordExample from './Components/RecordExample/RecordExample';
import useMediaQuery from '@mui/material/useMediaQuery';
import Response, { IResponse } from './Components/Response/Response';
import CircularProgress from '@mui/material/CircularProgress';
import exampleWell from 'assets/example_Well.1.0.0.json';
import exampleGeo from 'assets/example_geoPoliticalEntityType.1.0.0.json';
import { IToastState } from 'components/Toast/Toast';
import TextareaWithDrop from 'components/TextareaWithDrop/TextareaWithDrop';
import CommandPreview from 'components/CommandPreview/CommandPreview';
import useDebounce from 'hooks/useDebounce';
import JSONParser from 'Utils/JSONParser';
import { IErrorResponse } from 'Utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { settingsActions, RootState } from 'store';
import * as S from './LoadRecords.style';

export enum LOAD_TYPE {
  MASTER_DATA = 'MASTER_DATA',
  REFERENCE_DATA = 'REFERENCE_DATA',
}

const header = {
  [LOAD_TYPE.MASTER_DATA]: 'Load master data',
  [LOAD_TYPE.REFERENCE_DATA]: 'Load reference data',
};

const name = {
  [LOAD_TYPE.MASTER_DATA]: 'Master data',
  [LOAD_TYPE.REFERENCE_DATA]: 'Reference data',
};

const description = {
  [LOAD_TYPE.MASTER_DATA]: `Upload of the key data that represents resource attributes in the platform, such as UnitOfMeasure via storage endpoint.`,
  [LOAD_TYPE.REFERENCE_DATA]: `Upload of the key data that represents resource attributes in the platform, such as UnitOfMeasure via storage endpoint.`,
};

const sampleData = {
  [LOAD_TYPE.MASTER_DATA]: exampleWell,
  [LOAD_TYPE.REFERENCE_DATA]: exampleGeo,
};

interface ILoadRecords {
  type: LOAD_TYPE;
}

const acceptedFiles = ['application/json', 'text/plain'];

export default function LoadRecords({ type }: ILoadRecords) {
  const [records, setRecords] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [exampleData, setExampleData] = useState<Record<string, unknown>[]>([{}]);
  const [response, setResponse] = useState<IResponse>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRecordsError, setIsRecordsError] = useState(false);
  const inputRef: LegacyRef<HTMLInputElement> = useRef(null);
  const [recordsError, setRecordsError] = useState<IToastState>({
    errorSeverity: 'error',
    message: '',
  });

  const { setToastState } = settingsActions;
  const dispatch = useDispatch();
  const dataPartitionId = useSelector((state: RootState) => state.settings.dataPartitionId);

  const debouncedRecords = useDebounce(records, 500);

  const isDesktop = useMediaQuery('(min-width: 1024px)', {
    defaultMatches: true,
  });

  const copyExample = () => {
    dispatch(setToastState({ errorSeverity: 'success', message: 'Example copied to clipboard' }));
    navigator.clipboard.writeText(JSON.stringify(exampleData));
  };

  const handleReadFile = (file: File) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        setRecords(String(reader.result));
      },
      false
    );

    reader.readAsText(file, 'utf-8');
  };

  const handleClose = () => setIsModalOpen(false);

  const handleClearResponse = () => setResponse(undefined);

  const handleInputFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    file && handleReadFile(file);
  };

  const handleSend = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    API.put('api', '/api/storage/v2/records', {
      headers: {
        'data-partition-id': dataPartitionId,
        'Content-Type': 'application/json',
      },
      body: JSON.parse(records),
    })
      .then((response: IResponse) => {
        setRecords('');
        setIsLoading(false);
        setResponse(response);
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }: IErrorResponse) => {
          dispatch(setToastState({ errorSeverity: 'error', message }));
          setIsLoading(false);
        }
      );
  };

  const handleCopyCurl = () =>
    Auth.currentSession().then((session) => {
      navigator.clipboard.writeText(
        `curl --request PUT \\
        --url '${process.env.REACT_APP_BASE_API_URL_DISPLAY}/api/storage/v2/records' \\
        --header 'accept: application/json' \\
        --header 'authorization: Bearer ${session.getAccessToken().getJwtToken()}' \\
        --header 'content-type: application/json' \\
        --header 'data-partition-id: ${dataPartitionId}' \\
        --data '${records}'
        `
      );
      dispatch(
        setToastState({ errorSeverity: 'success', message: 'Curl command copied to clipboard' })
      );
    });

  const onFileIsInvalid = () => dispatch(setToastState({ errorSeverity: 'success', message: '' }));

  const handleCopyIds = () => {
    const recordIds = response?.recordIds;
    if (!recordIds) {
      return;
    }
    navigator.clipboard.writeText(JSON.stringify(recordIds));
    dispatch(
      setToastState({ errorSeverity: 'success', message: 'Curl command copied to clipboard' })
    );
    handleClearResponse();
  };

  useEffect(() => {
    if (!records) {
      return;
    }

    const parsedRecords = JSONParser(records);

    if (!parsedRecords) {
      setIsRecordsError(true);
      setRecordsError({ errorSeverity: 'error', message: 'Record list is not a valid JSON' });
      return;
    }
    setRecordsError({ errorSeverity: 'info', message: '' });
    setIsRecordsError(false);
  }, [debouncedRecords]);

  useEffect(() => {
    const replaced = JSON.stringify(sampleData[type]).replace(/osdu/g, dataPartitionId);
    setExampleData(JSON.parse(replaced));
  }, [type, dataPartitionId]);

  return (
    <S.Container container component={Paper} spacing={3}>
      <S.Input
        type="file"
        multiple
        accept={acceptedFiles.join(', ')}
        onChange={handleInputFile}
        ref={inputRef}
        aria-hidden
      />
      <S.Header>{header[type]}</S.Header>
      <S.Description variant="body1">{description[type]}</S.Description>
      <S.Body>
        <S.Form onSubmit={handleSend}>
          <TextareaWithDrop
            value={records}
            setValue={setRecords}
            label="Records"
            isError={isRecordsError}
            onFileIsInvalid={onFileIsInvalid}
          >
            <S.StyledButton onClick={() => setIsModalOpen(true)}>View example</S.StyledButton>
          </TextareaWithDrop>
          <S.FormRow>
            {recordsError.message && (
              <S.Message props={{ type: recordsError.errorSeverity }}>
                {recordsError.message}
              </S.Message>
            )}
            <S.SubmitButton disabled={!records || isLoading || isRecordsError} type={'submit'}>
              {isLoading ? <CircularProgress size={24} color="primary" /> : 'Send'}
            </S.SubmitButton>
          </S.FormRow>
        </S.Form>

        <CommandPreview
          title={'Curl command*'}
          subtitle="Upload file to presigned url"
          description={
            '*For users convenience displayed commands present only command structure with no data, tokens and full URL. To get command ready for execution, press "Copy with credentials" button.'
          }
          isCollapsible={!isDesktop}
          isOpenDefault={isDesktop}
          copyCommand={handleCopyCurl}
          command={[
            {
              indentLevel: 0,
              text: `curl  --request PUT --url '/api/storage/v2/records' \\`,
            },
            { indentLevel: 1, text: `--header 'authorization: <Bearer Token>' \\` },
            { indentLevel: 1, text: `--header 'Content-type: application/json' \\` },
            { indentLevel: 1, text: `--header 'data-partition-id: ${dataPartitionId}' \\` },
            { indentLevel: 1, text: '' },
            { indentLevel: 1, text: `--upload-file YOUR_FILE.json` },
            { indentLevel: 2, text: `OR` },
            { indentLevel: 1, text: `--data YOUR_RECORD_LIST` },
          ]}
        />
      </S.Body>
      <Modal
        isOpen={isModalOpen}
        handleClose={handleClose}
        header={`${name[type]} example`}
        component={<RecordExample file={exampleData} onClose={handleClose} onApply={copyExample} />}
      />
      <Modal
        isOpen={Boolean(response)}
        handleClose={handleClearResponse}
        header={'Modified data'}
        component={
          <Response onApply={handleCopyIds} response={response} onClose={handleClearResponse} />
        }
      />
    </S.Container>
  );
}
