import Button from '@mui/material/Button';
import styled from 'layout/theme/styled';

export const Wrapper = styled('div')({});

export const Body = styled('div')(
  ({
    theme: {
      palette: { grey4 },
    },
  }) => ({
    maxHeight: '70vh',
    overflow: 'auto',
    padding: '8px',
    boxSizing: 'border-box',
    '::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    scrollbarWidth: 'thin',
    scrollbarHeight: 'thin',
    scrollbarColor: `${grey4} transparent`,
    '::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: grey4,
    },
  })
);

export const CopyButton = styled(Button)(
  ({
    theme: {
      palette: {
        white,
        primary: { main, light },
      },
    },
  }) => ({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    color: main,
    fontSize: '12px',
    borderRadius: '4px',
    border: `1px solid ${main}`,
    fontWeight: '600',
    padding: '8px',
    height: 'fit-content',
    minWidth: 'unset',
    margin: '0 0 8px',
    '&:hover': {
      backgroundColor: light,
      color: white,
    },
    transition: '250ms',
    '&:disabled': {
      color: main,
      filter: 'saturate(0) opacity(0.5)',
    },
  })
);
