import styled from 'layout/theme/styled';
import FormControl from '@mui/material/FormControl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const CustomFormControl = styled(FormControl)<{
  props?: { width?: string };
}>(({ props: { width } = { width: undefined } }) => ({
  flexGrow: 2,
  padding: '0 6px 8px',
  minWidth: width ? undefined : '270px',
  width,
  maxWidth: width,
}));

export const CustomSelect = styled(Select)<{ props?: { color?: string } }>(
  ({ props: { color } = {} }) => ({
    padding: '0',
    backgroundColor: color,
    zIndex: 0,
  })
);

export const OptionGroupHeader = styled(ListSubheader)(
  ({
    theme: {
      palette: {
        text: { primary },
      },
    },
  }) => ({
    margin: '0 0 4px',
    padding: '0 8px',
    color: primary,
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: '120%',
  })
);

export const Option = styled(MenuItem)(
  ({
    theme: {
      palette: {
        text: { primary },
      },
    },
  }) => ({
    color: primary,
    cursor: 'pointer',
    margin: '0 0 4px',
    padding: '0 16px',
  })
);

export const HelpIcon = styled(HelpOutlineIcon)({
  margin: '0 0 0 2px',
  width: '15px',
  height: '15px',
});

export const Label = styled(InputLabel)<{ props?: { hasHover?: boolean } }>(
  ({
    props: { hasHover } = { hasHover: false },
    theme: {
      palette: {
        primary: { main },
      },
    },
  }) => ({
    zIndex: 1,
    transition: '250ms',
    left: '6px',
    ':hover': hasHover
      ? {
        color: main,
      }
      : {},
  })
);
