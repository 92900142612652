import styled from 'layout/theme/styled';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { PaperTypeMap } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';

export const Container = styled(Grid)<{ component: OverridableComponent<PaperTypeMap<'div'>> }>({
  minHeight: 'calc(100vh - 224px)',
  padding: '12px 6px 0',
  margin: '8px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const loading = {
  width: '100%',
  height: '4px',
};

export const NarrowInput = styled(TextField)({
  flexGrow: 2,
  padding: '0 6px',
  minWidth: '270px',
});

export const Loading = styled(LinearProgress)({
  ...loading,
});

export const NotLoading = styled(LinearProgress)({
  ...loading,
  opacity: 0,
});

export const InputsRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexWrap: 'wrap',
});

export const Body = styled('div')({
  flexGrow: 1,
  padding: '12px',
  boxSizing: 'border-box',
  width: '100%',
  '@media (min-width: 1024px)': {
    minHeight: 'calc(100vh - 292px)',
    maxHeight: 'calc(100vh - 292px)',
  },
});

export const HeaderWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 18px 0 0',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const ResultLabelsWrapper = styled('div')(
  ({
    theme: {
      palette: {
        border: { main },
      },
    },
  }) => ({
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${main}`,
    padding: '0 0 8px',
    '@media (min-width: 1024px)': {
      padding: '0 5px 8px 0',
    },
  })
);

export const ResultsContainer = styled('div')(
  ({
    theme: {
      palette: { grey4 },
    },
  }) => ({
    '@media (min-width: 1024px)': {
      height: 'calc(100vh - 500px)',
      maxHeight: 'calc(100vh - 500px)',
      overflow: 'auto',
      display: 'block',
      '::-webkit-scrollbar': {
        width: '5px',
      },
      scrollbarWidth: 'thin',
      scrollbarColor: `${grey4} transparent`,
      '::-webkit-scrollbar-track-piece': {
        backgroundColor: 'transparent',
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        backgroundColor: grey4,
      },
    },
    '@media (min-width: 1098px)': {
      height: 'calc(100vh - 464px)',
      maxHeight: 'calc(100vh - 464px)',
    },
    '@media (min-width: 1200px)': {
      height: 'calc(100vh - 500px)',
      maxHeight: 'calc(100vh - 500px)',
    },
    '@media (min-width: 1298px)': {
      height: 'calc(100vh - 464px)',
      maxHeight: 'calc(100vh - 464px)',
    },
  })
);

export const HeaderRow = styled('div')(
  ({
    theme: {
      palette: {
        border: { main },
      },
    },
  }) => ({
    padding: '8px 0',
    margin: '0 8px',
    display: 'flex',
    alignItems: 'center',
    borderColor: main,
  })
);

export const IDField = styled('p')(
  ({
    theme: {
      typography: { body2 },
      palette: {
        text: { primary },
      },
    },
  }) => ({
    ...body2,
    padding: '8px 0',
    color: primary,
    margin: '0 8px 0 0',
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    fontWeight: 600,
  })
);

export const KeyField = styled(IDField)({
  textAlign: 'left',
});

export const TypeFiled = styled(IDField)({
  flexShrink: 0,
});
export const AuthorityField = styled(TypeFiled)({
  flexBasis: '80px',
});

export const CountField = styled(AuthorityField)({
  textAlign: 'left',
});

export const SourceField = styled(AuthorityField)({});

export const DateField = styled(TypeFiled)({});

export const KindField = styled(IDField)({});

export const Filler = styled('div')({
  width: '64px',
  height: '1px',
});

export const Actions = styled('div')({ display: 'flex' });

export const CopyButton = styled(Button)(
  ({
    theme: {
      palette: {
        white,
        primary: { main, light },
      },
    },
  }) => ({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    color: main,
    fontSize: '12px',
    borderRadius: '4px',
    border: `1px solid ${main}`,
    fontWeight: '600',
    padding: '8px',
    height: 'fit-content',
    minWidth: 'unset',
    '&:hover': {
      backgroundColor: light,
      color: white,
    },
    transition: '250ms',
    '&:disabled': {
      color: main,
      filter: 'saturate(0) opacity(0.5)',
    },
  })
);

export const InvisibleLink = styled('a')({ visibility: 'hidden' });

export const ButtonWithIcon = styled(CopyButton)(
  ({
    theme: {
      palette: {
        primary: { main },
      },
    },
  }) => ({
    border: 'none',
    cursor: 'pointer',
    transition: '250ms',
    backgroundColor: 'transparent',
    color: main,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '1px 8px',
    margin: '0 8px 0 0',
    position: 'relative',
  })
);
