import ClickAwayListener from '@mui/material/ClickAwayListener';
import * as S from './Modal.style';

interface IModal {
  isOpen: boolean;
  handleClose: () => void;
  header: string;
  component?: JSX.Element;
  children?: JSX.Element;
}

export default function Modal({ isOpen, handleClose, component, header, children }: IModal) {
  if (!isOpen) {
    return null;
  }
  return (
    <S.ExternalWrapper>
      <ClickAwayListener mouseEvent={'onMouseUp'} onClickAway={handleClose}>
        <S.Wrapper>
          <S.HeaderWrapper>
            <S.Header>{header}</S.Header>
            <S.CloseButton onClick={handleClose}>
              <S.StyledCloseIcon />
            </S.CloseButton>
          </S.HeaderWrapper>
          {component}
          {children}
        </S.Wrapper>
      </ClickAwayListener>
    </S.ExternalWrapper>
  );
}
