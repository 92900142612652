import {IGeoPoint, SearchResults} from "../types/MapViewer";
import {get as _get} from "lodash";
import {API} from "@aws-amplify/api";
import {Icon} from "leaflet";
import PinIcon from "layout/assets/Icons/map-pin-black-icon.svg";

export async function searchGeoPoints(dataPartitionId: string, newCursor: string | null, kind: string, query: string, withCursor: boolean): Promise<SearchResults> {
    const {
        results,
        cursor
    } = await searchQuery(dataPartitionId, kind, query, withCursor, newCursor);
    const tempPoints: Array<IGeoPoint> = [];

    for (const {data, id} of results) {
        const geoData: Array<{
            coordinates: [number, number];
            type: 'point'
        }> = _get(data, [
            'SpatialLocation.Wgs84Coordinates',
            'geometries',
        ]);
        const FacilityName = data.FacilityName
        if (!geoData) {
            return {
                points: [],
                cursor: null
            };
        }
        const coordinatesWithId: Array<IGeoPoint> = geoData
            .filter(({type}) => type === 'point')
            .map(({coordinates}) => {
                const [latitude, longitude] = coordinates;
                return {
                    id,
                    FacilityName,
                    coordinates: [longitude, latitude],
                };
            });

        tempPoints.push(...coordinatesWithId);
    }
    return {
        points: tempPoints,
        cursor: cursor
    };
}

async function searchQuery(dataPartitionId: string, kind: string, query: string, withCursor: boolean, cursor: string | null): Promise<any> {
    try {
        if (withCursor) {
            return API.post('api', '/api/search/v2/query_with_cursor', {
                headers: {
                    'data-partition-id': dataPartitionId,
                },
                body: {
                    "cursor": cursor,
                    kind: kind,
                    query: query,
                    limit: 1000,
                    sort: {field: ['id'], order: ['ASC']},
                    "returnedFields": [
                        "id",
                        "data.SpatialLocation.Wgs84Coordinates",
                        "data.FacilityName"
                    ]
                },
            });
        } else {
            return await API.post('api', '/api/search/v2/query', {
                headers: {
                    'data-partition-id': dataPartitionId,
                },
                body: {
                    kind: kind,
                    query: query,
                    sort: {field: ['id'], order: ['ASC']},
                    "returnedFields": [
                        "id",
                        "data.SpatialLocation.Wgs84Coordinates",
                        "data.FacilityName"
                    ]
                },
            });
        }
    } catch (error) {
        return {
            points: [],
            cursor: null
        };
    }
}

export const Pin = new Icon({
    iconUrl: PinIcon,
    iconSize: [12, 30], // size of the icon
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

export interface MapContainerProps {
    geoPoints: any[];
    coordinates: number[];
}