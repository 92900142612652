import styled from 'layout/theme/styled';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const MenuWrapper = styled('div')(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  display: 'flex',
  flexGrow: 1,
  cursor: 'pointer',
  alignContent: 'center',
  color: theme.palette.white,
  [theme.breakpoints.up('lg')]: {
    position: 'relative',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.white,
  flexGrow: 1,
  alignSelf: 'center',
  marginRight: theme.spacing(2),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const MenuContainer = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  // paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  width: 'fit-content',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 0,
    paddingBottom: 0,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.white,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    color: theme.palette.grey5,
  },
}));

export const HelpIcon = styled(HelpOutlineIcon)({
  alignSelf: 'center',
});

export const ItemText = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const Button = styled(IconButton)({
  marginRight: '52px',
  '@media(min-width: 899px)': {
    marginRight: 0,
  },
});

export const MobileArrow = styled(ArrowRightIcon)<{ props: { isOpen: boolean } }>(
  ({ props: { isOpen } }) => ({
    transition: '250ms',
    transform: `rotate(${isOpen ? '-90deg' : '0deg'})`,
  })
);
