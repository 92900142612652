import * as S from './Modal.style';

interface IModalFooter {
  onApply?: () => void;
  onCancel?: () => void;
  applyLabel?: string;
  cancelLabel?: string;
  isForm?: boolean;
}

export default function ModalFooter({
  onApply,
  onCancel,
  applyLabel = 'Apply',
  cancelLabel = 'Cancel',
  isForm,
}: IModalFooter) {
  return (
    <S.FooterWrapper>
      {(isForm || onApply) && (
        <S.AcceptButton type={'submit'} onClick={onApply}>
          {applyLabel}
        </S.AcceptButton>
      )}
      {onCancel && (
        <S.CancelButton type={'button'} onClick={onCancel}>
          {cancelLabel}
        </S.CancelButton>
      )}
    </S.FooterWrapper>
  );
}
