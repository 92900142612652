const awsAuthRoutes = [
  'signIn',
  'signUp',
  'confirmSignIn',
  'confirmSignUp',
  'setupTOTP',
  'forceNewPassword',
  'resetPassword',
  'confirmResetPassword',
  'verifyUser',
  'confirmVerifyUser',
  'signOut',
];

export default awsAuthRoutes;
