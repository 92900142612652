import styled from 'layout/theme/styled';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandIcon from '@mui/icons-material/Expand';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GroupIcon from '@mui/icons-material/Group';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MoodIcon from '@mui/icons-material/Mood';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

export const ButtonWithIcon = styled(Button)<{
  props: { width?: string; isWarning?: boolean; isHover: boolean };
}>(
  ({
    props: { width, isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main, light },
        secondary: { main: warningColor, light: warningLight },
        white,
      },
    },
  }) => ({
    width: width ? width : 'fit-content',
    borderRadius: '4px',
    minWidth: 'unset',
    height: '48x',
    ':disabled': {
      filter: 'saturate(0) opacity(0.5)',
    },
    ':hover': {
      backgroundColor: isHover ? (isWarning ? warningLight : light) : 'transparent',
    },
    border: 'none',
    cursor: 'pointer',
    transition: '250ms',
    backgroundColor: isHover ? (isWarning ? light : warningLight) : 'transparent',
    color: isHover ? (isWarning ? warningColor : main) : white,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '2px 4px',
    margin: '0 8px 0 0',
    position: 'relative',
  })
);

export const Label = styled('label')<{ props: { isHover: boolean; isWarning?: boolean } }>(
  ({
    props: { isHover, isWarning = false },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: waringColor },
        white,
      },
    },
  }) => ({
    margin: '0',
    fontSize: '12px',
    fontWeight: '600',
    color: isHover ? white : isWarning ? waringColor : main,
    cursor: 'inherit',
    transition: '250ms',
  })
);

export const TrashIcon = styled(DeleteIcon)<{ props: { isHover: boolean; isWarning?: boolean } }>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const PenIcon = styled(EditIcon)<{ props: { isHover: boolean; isWarning?: boolean } }>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const UsersIcon = styled(GroupIcon)<{ props: { isHover: boolean; isWarning?: boolean } }>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

const iconStyles = { width: '28px', height: '28px', transition: '250ms' };

export const CopyIcon = styled(ContentCopyIcon)<{
  props: { isHover: boolean; isWarning?: boolean };
}>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const DownloadIcon = styled(FileDownloadIcon)<{
  props: { isHover: boolean; isWarning?: boolean };
}>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const ExpandAllIcon = styled(ExpandIcon)<{
  props: { isHover: boolean; isWarning?: boolean };
}>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const PrettyIcon = styled(MoodIcon)<{ props: { isHover: boolean; isWarning?: boolean } }>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const JSONIcon = styled(CodeIcon)<{ props: { isHover: boolean; isWarning?: boolean } }>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const CommonQueriesIcon = styled(QueryStatsIcon)<{
  props: { isHover: boolean; isWarning?: boolean };
}>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const SearchIcon = styled(ManageSearchIcon)<{
  props: { isHover: boolean; isWarning?: boolean };
}>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const PlusIcon = styled(AddCircleOutlineIcon)<{
  props: { isHover: boolean; isWarning?: boolean };
}>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const AddUser = styled(PersonAddAltIcon)<{
  props: { isHover: boolean; isWarning?: boolean };
}>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const Close = styled(HighlightOffIcon)<{
  props: { isHover: boolean; isWarning?: boolean };
}>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const ReadIcon = styled(AutoStoriesIcon)<{
  props: { isHover: boolean; isWarning?: boolean };
}>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);

export const HistoryIcon = styled(HistoryToggleOffIcon)<{
  props: { isHover: boolean; isWarning?: boolean };
}>(
  ({
    props: { isWarning = false, isHover },
    theme: {
      palette: {
        primary: { main },
        secondary: { main: warningColor },
        white,
      },
    },
  }) => ({
    ...iconStyles,
    color: isHover ? white : isWarning ? warningColor : main,
  })
);
