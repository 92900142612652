import './index.css';
import { StrictMode, Suspense } from 'react';
import { Authenticator, AmplifyProvider } from '@aws-amplify/ui-react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Amplify } from '@aws-amplify/core';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import theme from 'layout/theme/theme';
import App from './App';
import awsConfig from './AWSConfig';
import LoadingPage from 'pages/LoadingPage/LoadingPage';
import Navigation from 'components/Navigation/Navigation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { store } from './store';
import { Provider } from 'react-redux';

Amplify.configure(awsConfig);

const container = document.getElementById('root');
const root = container && createRoot(container);
root &&
  root.render(
    <StrictMode>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <AmplifyProvider>
            <Authenticator.Provider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Provider store={store}>
                  <Navigation>
                    <Suspense fallback={<LoadingPage />}>
                      <App />
                    </Suspense>
                  </Navigation>
                </Provider>
              </LocalizationProvider>
            </Authenticator.Provider>
          </AmplifyProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StrictMode>
  );
