import { useState, useEffect, ChangeEvent } from 'react';
import ModalFooter from 'components/Modal/ModalFooter';
import CustomSelect from 'components/Partials/CustomSelect/CustomSelect';
import CustomSelectWithGrouping from 'components/Partials/CustomSelect/CustomSelectWithGrouping';
import { fieldOptions, operators, typeOptions } from './staticData';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchInput from '../SearchInput/SearchInput';
import * as S from './QueryComposer.style';

interface IQueryComposer {
  kind: string;
  query: string;
  setKind: (value: string) => void;
  setQuery: (value: string) => void;
  handleClose: () => void;
}

interface IQueryField {
  fieldName: string;
  value: string;
  operator?: string;
  isExact?: boolean;
}

const emptyField: IQueryField = {
  fieldName: '',
  value: '',
  operator: '',
};

export default function QueryComposer({
  kind,
  query,
  setKind,
  setQuery,
  handleClose,
}: IQueryComposer) {
  const [tempKind, setTempKind] = useState(kind);
  const [tempQuery, setTempQuery] = useState(query);
  const [authority, setAuthority] = useState('');
  const [source, setSource] = useState('');
  const [type, setType] = useState('');
  const [version, setVersion] = useState('');
  const [queryFields, setQueryFields] = useState<IQueryField[]>([emptyField]);

  const handleAddField = () => setQueryFields([...queryFields, emptyField]);

  const handleRemoveField = (index: number) => {
    const prevItems = queryFields.slice(0, index);
    const nextItems = queryFields.slice(index + 1);
    setQueryFields([...prevItems, ...nextItems]);
  };

  const handleChangeFieldValue = (
    index: number,
    field: 'fieldName' | 'value' | 'operator' | 'isExact',
    value: string | boolean
  ) => {
    const item = queryFields[index];
    const prevItems = queryFields.slice(0, index);
    const nextItems = queryFields.slice(index + 1);
    setQueryFields([...prevItems, { ...item, [field]: value }, ...nextItems]);
  };

  const makeStringQuery = (fields: IQueryField[]) => {
    let newTempQuery = '';
    for (let index = 0; index < fields.length; index++) {
      const { fieldName, operator = 'OR', value, isExact } = fields[index];
      const parsedValue = isExact ? `"${value}"` : value;
      if (!newTempQuery && fieldName && value) {
        newTempQuery = `${fieldName}:${parsedValue}`;
      } else if (fieldName && value) {
        newTempQuery = `${newTempQuery} ${operator} ${fieldName}:${parsedValue}`;
      }
    }
    setTempQuery(newTempQuery);
  };

  useEffect(() => {
    const [currAuthority = '*', currSource = '*', currType = '*', currVersion = '*'] =
      kind.split(':');
    setAuthority(currAuthority);
    setSource(currSource);
    setType(currType);
    setVersion(currVersion);
    setTempKind(kind);
  }, [kind]);

  useEffect(() => {
    makeStringQuery(queryFields);
  }, [queryFields]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTempKind(
        `${authority ? authority : '*'}:${source ? source : '*'}:${type ? type : '*'}:${
          version ? version : '*'
        }`
      );
    }, 400);
    return () => clearTimeout(timeout);
  }, [authority, source, type, version]);

  const handleApply = () => {
    setKind(tempKind);
    setQuery(tempQuery);
    handleClose();
  };

  const firstQueryFiled = queryFields[0];
  return (
    <>
      <S.Wrapper>
        <S.QueryWrapper>
          <S.NarrowInput disabled value={tempKind} label="Kind" />
          <S.WideInput disabled value={tempQuery} label="Query" />
        </S.QueryWrapper>
        <S.MainWrapper>
          <S.KindFields>
            <S.SubHeader>Main data:</S.SubHeader>
            <SearchInput
              label={'Authority'}
              tooltip={'The implementation instance designation as used for OSDU'}
              value={authority}
              setValue={setAuthority}
            />
            <SearchInput
              label={'Source'}
              tooltip={'Database name'}
              value={source}
              setValue={setSource}
            />
            <CustomSelect
              tooltip="Work product type (master data and reference data)"
              value={type}
              setValue={setType}
              options={typeOptions}
              label="Type"
            />
            <SearchInput
              label={'Version'}
              tooltip={'Schema version e.g 1.0.0'}
              value={version}
              setValue={setVersion}
            />

            <S.Disclaimer>*Asterisk for any</S.Disclaimer>
          </S.KindFields>
          <S.QueryFields>
            <S.SubHeader>Query fields:</S.SubHeader>
            <S.FieldsRowWrapper>
              <S.OperatorFiller aria-hidden />
              <CustomSelectWithGrouping
                width="150px"
                label={'Field'}
                options={fieldOptions}
                value={firstQueryFiled.fieldName}
                setValue={(value: string) => handleChangeFieldValue(0, 'fieldName', value)}
              />
              <S.Input
                value={firstQueryFiled.value}
                onChange={(e: ChangeEvent<{ value: string }>) =>
                  handleChangeFieldValue(0, 'value', e.target.value)
                }
                label="Value"
              />
              <FormControlLabel
                control={
                  <S.StyledCheckbox
                    checked={!!firstQueryFiled.isExact}
                    onClick={() => handleChangeFieldValue(0, 'isExact', !firstQueryFiled.isExact)}
                  />
                }
                label="Exact"
              />
              <S.TrashFiller aria-hidden />
            </S.FieldsRowWrapper>
            {queryFields
              .slice(1)
              .map(({ fieldName, value, operator = '', isExact = false }, i: number) => {
                const index = i + 1;
                return (
                  <S.FieldsRowWrapper key={`field_${index}`}>
                    <CustomSelect
                      width="100px"
                      label={'Operator'}
                      options={operators}
                      value={operator}
                      setValue={(value: string) => handleChangeFieldValue(index, 'operator', value)}
                    />
                    <CustomSelectWithGrouping
                      width="150px"
                      label={'Field'}
                      options={fieldOptions}
                      value={fieldName}
                      setValue={(value: string) =>
                        handleChangeFieldValue(index, 'fieldName', value)
                      }
                    />
                    <S.Input
                      value={value}
                      onChange={(e: ChangeEvent<{ value: string }>) =>
                        handleChangeFieldValue(index, 'value', e.target.value)
                      }
                      label="Value"
                    />
                    <FormControlLabel
                      control={
                        <S.StyledCheckbox
                          checked={!!isExact}
                          onClick={() => handleChangeFieldValue(index, 'isExact', !isExact)}
                        />
                      }
                      label="Exact"
                    />
                    <S.TrashButton onClick={() => handleRemoveField(index)}>
                      <S.TrashIcon />
                    </S.TrashButton>
                  </S.FieldsRowWrapper>
                );
              })}
            <S.AddButton onClick={handleAddField}>
              <S.AddIcon /> Add field
            </S.AddButton>
          </S.QueryFields>
        </S.MainWrapper>
      </S.Wrapper>
      <ModalFooter onApply={handleApply} onCancel={handleClose} />
    </>
  );
}
