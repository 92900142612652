import styled from 'layout/theme/styled';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import { CSSProperties } from 'react';

export const CurlWrapper = styled('div')<{ props: { style?: CSSProperties } }>(
  ({ props: { style } }) => ({
    width: '100%',
    maxWidth: '800px',
    ...style,
  })
);

export const CurlCommandContainer = styled(Collapse)(
  ({
    theme: {
      palette: { grey2 },
      typography: { body1 },
    },
  }) => ({
    ...body1,
    backgroundColor: grey2,
    padding: '12px',
    boxSizing: 'border-box',
    fontFamily: 'Roboto Mono',
    whiteSpace: 'pre-line',
  })
);

export const IndentLevelZero = styled('p')<{ props?: { color?: string } }>(
  ({ props: { color } = {} }) => ({
    margin: '0 0 4px',
    padding: '2px',
    borderRadius: '2px',
    backgroundColor: color,
    width: 'fit-content',
  })
);

export const IndentLevelOne = styled(IndentLevelZero)({
  marginLeft: '51px',
});

export const IndentLevelTwo = styled(IndentLevelZero)({
  marginLeft: '102px',
});

export const IndentLevelTree = styled(IndentLevelZero)({
  marginLeft: '152px',
});

export const IndentLevelFour = styled(IndentLevelZero)({
  marginLeft: '203px',
});

export const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 8px',
  flexWrap: 'wrap',
});

export const StyledButton = styled(Button)(
  ({
    theme: {
      palette: {
        white,
        primary: { main, light },
      },
    },
  }) => ({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    color: main,
    fontSize: '12px',
    borderRadius: '4px',
    border: `1px solid ${main}`,
    fontWeight: '600',
    padding: '8px',
    height: 'fit-content',
    minWidth: 'unset',
    margin: '0 12px 8px 0',
    '&:hover': {
      backgroundColor: light,
      color: white,
    },
    transition: '250ms',
    '&:disabled': {
      color: main,
      filter: 'saturate(0) opacity(0.5)',
    },
  })
);

export const Description = styled('p')(
  ({
    theme: {
      typography: { subtitle2 },
    },
  }) => ({
    ...subtitle2,
    whiteSpace: 'pre-line',
  })
);
