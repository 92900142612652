import { lazy } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { IPageInformation } from '../Pages.d';
const SettingsContainer = lazy(() => import('./Components/SettingsContainer'));

const TITLE = 'Settings';
const URL = '/'.concat(TITLE.replace(/\s+/g, ''));

const pages: IPageInformation[] = [
  {
    title: TITLE,
    path: URL,
    component: () => <SettingsContainer />,
    addToSideMenu: true,
    icon: <SettingsIcon />,
  },
];

export default pages;