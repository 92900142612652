import { useDispatch } from 'react-redux';
import { platformStateActions } from 'store/index';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';

export default function SignOutPage() {
  const dispatch = useDispatch();
  const { logout } = platformStateActions;
  const { signOut } = useAuthenticator((context) => [context.user]);
  
  useEffect(() => {
    dispatch(logout());
    signOut();
  });
  
  return <div />;
}
