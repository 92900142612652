import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { TableRowClasses } from '@mui/material/TableRow/tableRowClasses';
import { palette } from '../palette';

export const muiTableRow: Partial<
  OverridesStyleRules<keyof TableRowClasses, 'MuiTableRow', unknown>
> = {
  root: {
    '&$selected': {
      backgroundColor: `${palette.grey2} !important`,
    },
  },
};
