import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import * as S from './CustomSelect.style';

interface ICustomSelect {
  value: string;
  setValue: (value: string) => void;
  options: { label: string; value: string }[];
  label?: string;
  width?: string;
  tooltip?: string;
  disabled?: boolean;
  color?: string;
  required?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
}

export default function CustomSelect({
  value,
  setValue,
  options,
  label,
  width,
  tooltip,
  disabled = false,
  required = false,
  color,
  onBlur,
  onFocus,
}: ICustomSelect) {
  const [isHover, setIsHover] = useState(false);
  const selectId = `${label}Select`;
  const select = () => (
    <S.CustomFormControl props={{ width }} variant="standard">
      {label && (
        <S.Label
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          htmlFor={selectId}
          props={{
            hasHover: Boolean(tooltip),
          }}
        >
          {label}
          {tooltip && <S.HelpIcon />}
        </S.Label>
      )}
      <S.CustomSelect
        onMouseEnter={() => !label && setIsHover(true)}
        onMouseLeave={() => !label && setIsHover(false)}
        required={required}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        labelId={label}
        id={selectId}
        value={value}
        props={{ color }}
        onChange={(e: SelectChangeEvent<unknown>) => {
          e.preventDefault(), e.stopPropagation(), setValue(e.target.value as string);
        }}
      >
        {options.map(({ label, value }, index: number) => (
          <MenuItem key={`label_${index}`} value={value}>
            {label}
          </MenuItem>
        ))}
      </S.CustomSelect>
    </S.CustomFormControl>
  );

  if (tooltip) {
    return (
      <Tooltip open={isHover} placement="top" title={tooltip}>
        {select()}
      </Tooltip>
    );
  }

  return select();
}
