import { ChangeEvent, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import * as S from './SearchInput.style';

interface ISearchInput {
  value: string;
  setValue: (v: string) => void;
  tooltip?: string;
  label: string;
  grow?: number;
  color?: string;
  disabled?: boolean;
  required?: boolean;
}

export default function SearchInput({
  value,
  setValue,
  label,
  tooltip,
  grow = 2,
  color,
  disabled = false,
  required = false,
}: ISearchInput) {
  const [isHover, setIsHover] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const isShrink = Boolean(value) || isFocused;
  const fieldId = `${label}Field`;

  const field = (
    <S.StyledFormControl props={{ flexGrow: grow }} variant="outlined">
      <S.Label
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        shrink={isShrink}
        htmlFor={fieldId}
      >
        {label}
        {tooltip && <S.HelpIcon />}
      </S.Label>
      <S.Input
        required={required}
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onMouseEnter={() => !isShrink && setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        type="text"
        value={value}
        onChange={(event: ChangeEvent<{ value: string }>) => {
          setValue(event.target.value);
        }}
        id={fieldId}
        props={{ color }}
      />
    </S.StyledFormControl>
  );

  if (tooltip) {
    return (
      <Tooltip open={isHover} placement="top" title={tooltip}>
        {field}
      </Tooltip>
    );
  }
  return field;
}
