import styled from 'layout/theme/styled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

export const Input = styled(TextField)<{ props: { color?: string } }>(({ props: { color } }) => ({
  flexGrow: 2,
  padding: '0 6px',
  minWidth: '270px',
  zIndex: 0,
  backgroundColor: color,
  backgroundClip: 'content-box',
}));

export const HelpIcon = styled(HelpOutlineIcon)({
  margin: '0 0 0 2px',
  width: '15px',
  height: '15px',
});

export const Label = styled(InputLabel)(
  ({
    theme: {
      palette: {
        primary: { main },
      },
    },
  }) => ({
    zIndex: '20',
    left: '-8px',
    transition: '250ms',
    ':hover': {
      color: main,
    },
  })
);

export const StyledFormControl = styled(FormControl)<{ props: { flexGrow: number } }>(
  ({ props: { flexGrow } }) => ({
    flexGrow,
    padding: '10px 0 0',
    margin: '8px 0 7px',
    zIndex: '10',
  })
);
