import styled from 'layout/theme/styled';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const Container = styled('div')<{ props: { isGrayedOut?: boolean } }>(
  ({
    props: { isGrayedOut },
    theme: {
      palette: {
        text: { primary },
        black,
      },
    },
  }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minHeight: '52px',
    maxWidth: '100%',
    width: '100%',
    padding: '0 4px 0 0',
    flexWrap: 'wrap',
    color: isGrayedOut ? black : primary,
    boxSizing: 'border-box',
  })
);

export const Section = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

export const Text = styled(Typography)({
  fontSize: '14px',
  color: 'inherit',
});

export const Select = styled('select')<{ props: { color?: string } }>(({ props: { color } }) => ({
  width: '50px',
  height: '30px',
  fontSize: '14px',
  padding: '6px',
  border: 'none',
  margin: '0 26px 0 8px',
  cursor: 'pointer',
  backgroundColor: '#fff',
  '&:focus': {
    outline: 'none',
    backgroundColor: '#e9e9e9',
  },
  ':disabled': {
    cursor: 'default',
    backgroundColor: color,
    color: color ? '#000' : undefined,
  },
}));

export const Counter = styled(Typography)({
  fontSize: '14px',
  margin: '0 12px 0 0',
  color: 'inherit',
});

export const ColorSpan = styled('span')<{ props: { color?: string } }>(({ props: { color } }) => ({
  backgroundColor: color,
  padding: '6px',
  height: '100%',
}));

export const StyledButton = styled(Button)({
  width: '34px',
  height: '34px',
  margin: '0 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  boxSizing: 'border-box',
  cursor: 'pointer',
  padding: 0,
  '&:focus': {
    outline: 'none',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:disabled': {
    cursor: 'default',
    opacity: 0.4,
  },
});
