import { createTheme } from '@mui/material/styles';
import { palette } from './palette';
import { typography } from './typography';
import { components } from './overrides/index';
import './index.d';

const theme = createTheme({
  palette,
  typography,
  components,
  height: {
    topBar: 100,
    topBarBorder: 5,
  },
  width: {
    drawer: 200,
    snackbar: 320,
  },
  zIndex: {
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400
  },
  popper: 1300,
});

export default theme;
