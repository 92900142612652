import { useRef, LegacyRef, MouseEvent } from 'react';
import { useTheme } from '@mui/material';
import arrowSVG from 'layout/assets/Icons/icon_arrowdown.svg';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import useMediaQuery from '@mui/material/useMediaQuery';
import dateFormatter from 'Utils/dateFormatter';
import { ISchemaItem } from 'pages/SchemaPage/SchemaPageTypes';
import ButtonWithIcon from 'components/Partials/ButtonWithIcon/ButtonWithIcon';
import { IToastState } from 'components/Toast/Toast';
import * as S from './SchemaResult.style';

export default function SchemaResult({
  result,
  result: {
    schemaIdentity: { id },
    createdBy,
    dateCreated,
    status,
    scope,
  },
  handleView,
  handleEdit,
  handleOpen,
  isOpen,
}: {
  dataPartition: string;
  result: ISchemaItem;
  isOpen: boolean;
  handleOpen: (v: string) => void;
  handleEdit: () => void;
  handleView: () => void;
  handleRefresh: () => void;
  handleToast: (value: IToastState) => void;
}) {
  const JSONViewer = ReactJson as (props: ReactJsonViewProps) => JSX.Element;

  const isOpenDisabled = false;
  const readableDate = dateFormatter(dateCreated);
  const accordionRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const isTablet = useMediaQuery('(min-width: 778px)', {
    defaultMatches: true,
  });

  const isDisabled = status !== 'DEVELOPMENT';

  return (
    <S.Wrapper>
      <S.HeaderRow props={{ isSelected: false }} onClick={() => !isOpenDisabled && handleOpen(id)}>
        <S.ArrowButton>
          <S.Arrow alt={'arrow'} src={arrowSVG} props={{ isOpen: isOpen }} />
        </S.ArrowButton>
        <S.WideField>{id}</S.WideField>
        {isDesktop && (
          <>
            <S.NarrowField>{createdBy}</S.NarrowField>
            <S.NarrowField>{readableDate}</S.NarrowField>
          </>
        )}
        {isTablet && (
          <>
            <S.NarrowField>
              <S.Capitalize>{status.toLowerCase()}</S.Capitalize>
            </S.NarrowField>
            <S.NarrowField>
              <S.Capitalize>{scope.toLowerCase()}</S.Capitalize>
            </S.NarrowField>
          </>
        )}
        <S.StandardField>
          <ButtonWithIcon
            iconName="read"
            label={'View'}
            onClick={(e: MouseEvent) => {
              e.stopPropagation(), handleView();
            }}
          />
          <ButtonWithIcon
            disabled={isDisabled}
            iconName="edit"
            label={'Edit'}
            onClick={(e: MouseEvent) => {
              e.stopPropagation(), handleEdit();
            }}
          />
        </S.StandardField>
      </S.HeaderRow>
      <S.Accordion in={isOpen} ref={accordionRef}>
        <S.DescriptionContainer>
          <JSONViewer src={result} collapsed={false} />
        </S.DescriptionContainer>
      </S.Accordion>
    </S.Wrapper>
  );
}
