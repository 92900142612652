import { useEffect } from 'react';
import * as S from './Toast.style';

export type AlertType = 'error' | 'warning' | 'info' | 'success';

export interface IToastState {
  errorSeverity: AlertType;
  message: string;
}

export default function Toast({
  errorSeverity,
  timeout,
  message,
  handleTurnOff,
}: {
  errorSeverity: AlertType;
  message: string;
  timeout: number;
  handleTurnOff: () => void;
}) {
  useEffect(() => {
    const timer = setTimeout(() => {
      handleTurnOff();
    }, timeout);
    return () => clearTimeout(timer);
  }, [timeout, message]);

  if (!message) {
    return null;
  }
  return (
    <S.Wrapper>
      <S.Toast severity={errorSeverity} onClose={handleTurnOff}>
        {message}
      </S.Toast>
    </S.Wrapper>
  );
}
