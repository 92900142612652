import { useState, useEffect } from 'react';
import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { ISchemaItem, emptySchema } from 'pages/SchemaPage/SchemaPageTypes';
import { IToastState } from 'components/Toast/Toast';
import CommandPreview from 'components/CommandPreview/CommandPreview';
import dateFormatter from 'Utils/dateFormatter';
import Field from 'components/Partials/Field/Field';
import ModalFooter from 'components/Modal/ModalFooter';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import * as S from './ViewSchema.style';

interface IViewSchema {
  dataPartition: string;
  handleClose: () => void;
  schemaItem?: ISchemaItem;
  handleToast: (state: IToastState) => void;
}

export default function ViewSchema({
  dataPartition,
  handleClose,
  schemaItem,
  handleToast,
}: IViewSchema) {
  const {
    status,
    createdBy,
    dateCreated,
    scope,
    schemaIdentity: {
      authority,
      entityType,
      schemaVersionMajor,
      schemaVersionMinor,
      schemaVersionPatch,
      source,
      id,
    },
  } = schemaItem || emptySchema;

  const JSONViewer = ReactJson as (props: ReactJsonViewProps) => JSX.Element

  const [schemaValue, setSchemaValue] = useState({});

  const handleCopySchema = () => {
    navigator.clipboard.writeText(JSON.stringify(schemaValue, null, 2));
    handleToast({ errorSeverity: 'success', message: 'Schema copied to clipboard' });
  };

  const handleCopyCurl = () =>
    Auth.currentSession().then((session) => {
      navigator.clipboard.writeText(
        `curl --request GET \\
  --url '${process.env.REACT_APP_BASE_API_URL_DISPLAY}/api/schema-service/v1/schema/${id}' \\
  --header 'accept: application/json' \\
  --header 'authorization: Bearer ${session.getAccessToken().getJwtToken()}' \\
  --header 'content-type: application/json' \\
  --header 'data-partition-id: ${dataPartition}'
  `
      );
      handleToast({ errorSeverity: 'success', message: 'Curl command copied to clipboard' });
    });

  useEffect(() => {
    if (!id) {
      return;
    }
    API.get('api', `/api/schema-service/v1/schema/${id}`, {
      headers: {
        'data-partition-id': dataPartition,
      },
    })
      .then((response) => setSchemaValue(response))
      .catch(
        ({
          response: {
            data: {
              error: { message },
            },
          },
        }) => handleToast({ message, errorSeverity: 'error' })
      );
  }, [id]);

  return (
    <>
      <S.Wrapper>
        <S.FieldSetHeader>Schema identification fields</S.FieldSetHeader>
        <S.Row>
          <S.Column>
            <Field label="ID" value={id} />
            <Field label="Authority" value={authority} />
            <Field label="Source" value={source} />
            <Field label="Entity type" value={entityType} />
          </S.Column>
          <S.Column>
            <Field label="Scope" value={scope} />
            <Field label="Status" value={status} />
            <Field
              label="Version"
              value={`${schemaVersionMajor}:${schemaVersionMinor}:${schemaVersionPatch}`}
            />
            <Field label="Created by" value={createdBy} />
            <Field label="Created at" value={dateFormatter(dateCreated)} />
          </S.Column>
        </S.Row>
        <S.FieldSetHeader>Schema</S.FieldSetHeader>
        <S.SchemaContainer>
          <JSONViewer src={schemaValue} collapsed={false} />
        </S.SchemaContainer>
        <CommandPreview
          style={{ maxWidth: '100%' }}
          description={`*For users convenience displayed commands present only command structure with no data, tokens and full URL. To get command ready for execution, press "Copy with credentials" button.`}
          title="Curl command"
          isCollapsible
          copyCommand={handleCopyCurl}
          command={[
            {
              indentLevel: 0,
              text: `curl --method GET --url "/api/schema-service/v1/schema/${id}"`,
            },
            { indentLevel: 1, text: "--header 'accept: application/json' \\" },
            { indentLevel: 1, text: "--header 'authorization: <Bearer Token>' \\" },
            { indentLevel: 1, text: `--header 'data-partition-id': "${dataPartition}"'` },
          ]}
        />
      </S.Wrapper>
      <ModalFooter onApply={handleCopySchema} applyLabel="Copy" onCancel={() => handleClose()} />
    </>
  );
}
