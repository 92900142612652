import styled from 'layout/theme/styled';

export const Wrapper = styled('div')(
  ({
    theme: {
      palette: { grey4 },
    },
  }) => ({
    padding: '16px',
    boxSizing: 'border-box',
    height: '64vh',
    // 172px is height of floating "Support" button, 167px is the value of wrapper
    maxHeight: 'calc(100vh - 167px - 172px)',
    overflow: 'auto',
    '::-webkit-scrollbar': {
      width: '5px',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: `${grey4} transparent`,
    '::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: grey4,
    },
  })
);

export const VersionRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  margin: '26px 0 10px',
  padding: '0 0 0 6px',
});

export const VersionSpacer = styled('p')(
  ({
    theme: {
      typography: { body1 },
    },
  }) => ({
    ...body1,
    margin: '0',
  })
);

export const MainInputs = styled('div')({
  display: 'flex',
  gap: '12px',
  flexWrap: 'wrap',
});

export const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '-6px',
});

export const Input = styled('input')(
  ({
    theme: {
      typography: { body1 },
      palette: {
        grey4,
        primary: { main },
      },
    },
  }) => ({
    ...body1,
    border: `0px solid ${grey4}`,
    borderBottomWidth: '1px',
    width: '100%',
    maxWidth: '54px',
    padding: '0 8px',
    margin: '0 0 1px',
    ':focus': {
      margin: '0',
      outline: 'none',
      borderBottomWidth: '2px',
      borderBottomColor: main,
    },
    ':hover': {
      margin: '0',
      borderBottomWidth: '2px',
    },
    '::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
      MozAppearance: 'textfield',
    },
    '::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
      MozAppearance: 'textfield',
    },
  })
);

export const FieldSetHeader = styled('h4')({ marginTop: '0' });

export const Message = styled('p')(
  ({
    theme: {
      palette: {
        secondary: { main: red },
      },
      typography: { body2 },
    },
  }) => ({
    ...body2,
    color: red,
    margin: 'auto 8px auto 0',
    transition: '250ms',
    height: 'fit-content',
  })
);
