import { lazy } from 'react';
const EnterpriseAppIntegration = lazy(() => import('./Components/EnterpriseAppIntegration'));
const ScalabilityAndPerformance = lazy(() => import('./Components/ScalabilityAndPerformance'));
const FinancialImplications = lazy(() => import('./Components/FinancialImplications'));
const DeploymentAndMaintenance = lazy(
  () => import('./Components/DeploymentAndMaintenance/DeploymentAndMaintenance')
);
const OperateInProduction = lazy(() => import('./Components/OperateInProduction'));
const Cards = lazy(() => import('components/Cards/Cards'));
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Link } from 'react-router-dom';
import { IPageInformation } from '../Pages.d';

const TITLE = 'In Production';
const URL = '/'.concat(TITLE.replace(/\s+/g, ''));
const DESCRIPTION = (
  <>
    Once you have seen how the OSDU Data Platform can be adapted to your data and use cases,
    47Lining can help you assess what it will take to&nbsp;
    <Link className="text-link" to="/DeploymentAndMaintenance">
      deploy and maintain
    </Link>
    &nbsp;the OSDU Data Platform while&nbsp;
    <Link className="text-link" to="/OperateInProduction">
      operating in production
    </Link>
    . You will typically need to consider factors such as&nbsp;
    <Link className="text-link" to="/EnterpriseAppIntegrations">
      integration
    </Link>
    &nbsp;with enterprise applications,&nbsp;
    <Link className="text-link" to="/ScalabilityAndPerformance">
      scalability and performance
    </Link>
    , and&nbsp;
    <Link className="text-link" to="/FinancialImplications">
      {' '}
      financial evaluation{' '}
    </Link>
    .
  </>
);

const ITEMS: IPageInformation[] = [
  {
    title: 'Enterprise App Integrations',
    description: (
      <>
        When an organization decides to rollout the OSDU® Data Platform, it will be necessary to
        integrate with corporate standard applications and protocols. In many large organizations
        those enterprise applications are dictated and must be used regardless of software running
        on-premise or in the cloud via SaaS.
      </>
    ),
    path: '/EnterpriseAppIntegrations',
    component: EnterpriseAppIntegration,
  },
  {
    title: 'Scalability and Performance',
    description: (
      <>
        While planning to rollout the OSDU® Data Platform in each organization, companies will need
        to ensure that the performance of the system can meet the needs of their use cases. That
        vetting should occur across the array of OSDU services used by each organization. The OSDU
        services that are likely to be stressed the most are: ingestion, search, and delivery.
        Evaluating these services requires a strategy that is unique to each service.
      </>
    ),
    path: '/ScalabilityAndPerformance',
    component: ScalabilityAndPerformance,
  },
  {
    title: 'Financial Implications',
    description: (
      <>
        When companies are evaluating if the OSDU® Data Platform is the right direction for their
        organization, they must assess the financial commitment of the various alternatives and
        ensure that their use cases deliver value over and above the budget allotted to the
        initiative.
      </>
    ),
    path: '/FinancialImplications',
    component: FinancialImplications,
  },
  {
    title: 'Deployment and Maintenance',
    description: (
      <>
        A key aspect to evaluate the OSDU® Data Platform is the activities required of deploying and
        maintaining the OSDU Data Platform. The OSDU Forum provides open source common code that
        itself it not a complete implementation. It has Service Provider Interfaces(SPI) that must
        be implemented by a Cloud Provider in order to function properly within the Cloud Providers
        environment.
      </>
    ),
    path: '/DeploymentAndMaintenance',
    component: DeploymentAndMaintenance,
  },
  {
    title: 'Operate in Production',
    description: (
      <>
        One of the major components to evaluating the OSDU® Data Platform is determining a model for
        how your organization will operate an OSDU Data Platform in production. There are a number
        of factors to consider that all present different cost, security, accessibility, speed, and
        support trade-offs.
      </>
    ),
    path: '/OperateInProduction',
    component: OperateInProduction,
  },
];

const pages: IPageInformation[] = [
  {
    title: TITLE,
    path: URL,
    component: () => <Cards header={{ title: TITLE, description: DESCRIPTION }} items={ITEMS} />,
    addToSideMenu: false,
    icon: <ApartmentIcon />,
  },
  ...ITEMS.filter((i) => i.component).map((i) => {
    return {
      path: i.path,
      component: i.component,
    };
  }),
];

export default pages;
