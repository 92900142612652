import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import ModalFooter from 'components/Modal/ModalFooter';
import * as S from './RecordExample.style';

interface IRecordExample {
  onClose: () => void;
  onApply: () => void;
  file: Record<string, unknown>[];
}

export default function RecordExample({ onClose, onApply, file }: IRecordExample) {
  const JSONViewer = ReactJson as (props: ReactJsonViewProps) => JSX.Element
  return (
    <S.Wrapper>
      <S.Body>
        <JSONViewer src={file} collapsed={false} />
      </S.Body>
      <ModalFooter onApply={onApply} onCancel={onClose} applyLabel="Copy" />
    </S.Wrapper>
  );
}
