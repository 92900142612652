import * as S from './SideBarItem.style';

interface SideBarItemProps {
  name: string;
  icon?: JSX.Element;
  isActive?: boolean;
  onClick?: () => void;
}

export default function SideBarItem({ name, icon, isActive, onClick }: SideBarItemProps) {
  return (
    <S.Wrapper props={{ isActive }}>
      <S.StyledButton onClick={onClick}>
        {icon && <S.Icon>{icon}</S.Icon>}
        {name}
      </S.StyledButton>
    </S.Wrapper>
  );
}
