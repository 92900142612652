import CloseIcon from '@mui/icons-material/Close';
import styled from 'layout/theme/styled';

export const ExternalWrapper = styled('div')(
  ({
    theme: {
      palette: { grey5 },
      zIndex: { modal },
    },
  }) => ({
    backgroundColor: `${grey5}db`,
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    padding: '16px',
    boxSizing: 'border-box',
    zIndex: modal,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  })
);

export const Wrapper = styled('div')(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    backgroundColor: white,
    borderRadius: '4px',
    height: 'fit-content',
    margin: 'auto',
    width: '100%',
    maxWidth: '1200px',
    // 172px is height of floating "Support" button
    maxHeight: 'max-height: calc(100vh - 172px);',
  })
);

export const HeaderWrapper = styled('div')(
  ({
    theme: {
      palette: {
        border: { main },
      },
    },
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${main}`,
  })
);

export const Header = styled('h1')(
  ({
    theme: {
      typography: { h5 },
      palette: {
        text: { primary },
      },
    },
  }) => ({
    ...h5,
    color: primary,
    margin: 0,
  })
);

export const CloseButton = styled('button')(
  ({
    theme: {
      palette: {
        text: { primary },
        grey4,
      },
    },
  }) => ({
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: '300ms',
    color: primary,
    '&:hover': {
      backgroundColor: 'transparent',
      color: grey4,
    },
  })
);

export const StyledCloseIcon = styled(CloseIcon)({
  width: '26px',
  height: '26px',
});

export const FooterWrapper = styled('div')(
  ({
    theme: {
      palette: {
        border: { main },
      },
    },
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '16px',
    width: '100%',
    boxSizing: 'border-box',
    borderTop: `1px solid ${main}`,
  })
);

export const AcceptButton = styled('button')(
  ({
    theme: {
      palette: {
        white,
        primary: { light, main },
      },
      typography: { body1 },
    },
  }) => ({
    ...body1,
    fontWeight: '600',
    border: 'none',
    color: white,
    borderRadius: '4px',
    padding: '8px 16px',
    cursor: 'pointer',
    transition: '250ms',
    backgroundColor: main,
    '&:hover': {
      backgroundColor: light,
    },
  })
);

export const CancelButton = styled(AcceptButton)(
  ({
    theme: {
      palette: { textField, black, page },
    },
  }) => ({
    margin: '0 0 0 16px',
    color: black,
    backgroundColor: textField,
    '&:hover': {
      backgroundColor: page,
    },
  })
);
