export { default as MapViewerPage } from './MapViewerPage';
import MapIcon from '@mui/icons-material/Map';
import MapViewerPage from './MapViewerPage';

export default {
  title: 'Map Viewer',
  path: '/MapViewer',
  component: () => <MapViewerPage />,
  addToSideMenu: true,
  childPages: [],
  // here should be icon that appears in side menu
  icon: <MapIcon />,
};
