import * as S from './SearchNavigation.style';

interface ISearchNavigation {
  isFetchingData: boolean;
  hasNotFoundResults: boolean;
  isAggregateDisabled?: boolean;
  viewType: VIEW_TYPE;
  setViewType: (view: VIEW_TYPE) => void;
  hasAggregations?: boolean;
}

export enum VIEW_TYPE {
  RESULTS = 'RESULTS',
  AGGREGATIONS = 'AGGREGATIONS',
  REQUEST = 'REQUEST',
}

export default function SearchNavigation({
  isFetchingData,
  isAggregateDisabled = true,
  hasNotFoundResults,
  viewType,
  setViewType,
  hasAggregations = true,
}: ISearchNavigation) {
  return (
    <S.Header>
      {isFetchingData ? (
        'Fetching data...'
      ) : hasNotFoundResults ? (
        'No results found'
      ) : (
        <>
          <S.HeaderButton
            props={{ isSelected: viewType === VIEW_TYPE.RESULTS }}
            onClick={() => setViewType(VIEW_TYPE.RESULTS)}
          >
            Results
          </S.HeaderButton>
          <S.Spacer />
          {hasAggregations && (
            <>
              <S.HeaderButton
                disabled={isAggregateDisabled}
                props={{ isSelected: viewType === VIEW_TYPE.AGGREGATIONS }}
                onClick={() => setViewType(VIEW_TYPE.AGGREGATIONS)}
              >
                Aggregations
              </S.HeaderButton>
              <S.Spacer />
            </>
          )}
          <S.HeaderButton
            props={{ isSelected: viewType === VIEW_TYPE.REQUEST }}
            onClick={() => setViewType(VIEW_TYPE.REQUEST)}
          >
            Request
          </S.HeaderButton>
        </>
      )}
    </S.Header>
  );
}
