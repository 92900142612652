import styled from 'layout/theme/styled';
import Alert from '@mui/material/Alert';

export const Wrapper = styled('div')(
  ({
    theme: {
      zIndex: { snackbar },
    },
  }) => ({
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    display: 'flex',
    top: '0',
    left: '0',
    padding: '16px 32px 64px',
    boxSizing: 'border-box',
    alignItems: 'flex-end',
    justifyContent: 'center',
    pointerEvents: 'none',
    zIndex: snackbar,
    whiteSpace: 'pre-wrap'
  })
);

export const Toast = styled(Alert)({
  pointerEvents: 'auto',
});
