import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchInput from "../Search/Components/SearchInput/SearchInput";
import * as Search from '../Search/Search.style';
import QueryComposer from "../Search/Components/QueryComposer/QueryComposer";

interface Props {
  kindValue: string;
  queryValue: string;
  setKindValue: (kindValue: string) => void;
  setQueryValue: (queryValue: string) => void;
  handleSearch: () => void;
}

const MapController: React.FC<Props> = ({
    kindValue,
    queryValue,
    setKindValue,
    setQueryValue,
    handleSearch,
  }) => {
  const [unfolded, setUnfolded] = useState(false);

  const handleUnfold = (event: React.MouseEvent<HTMLDivElement>) => {
    const isExpandMoreIconClick = (event.target as HTMLDivElement).classList.contains('expand-more-icon');
    if (isExpandMoreIconClick) {
      setUnfolded(!unfolded);
    }
  };

  return (
      <Accordion expanded={unfolded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon className="expand-more-icon"/>} onClick={handleUnfold}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography>
              {!unfolded && (
                  <Search.InputsRow>
                    <SearchInput
                        label={'Kind'}
                        tooltip={
                          'String or string array of authority/data-partition-id: data-source-id: entity-type: schema-version, each field can be replaced by wildcard (*)'
                        }
                        value={kindValue}
                        setValue={setKindValue}
                    />
                    <SearchInput
                        label={'Query'}
                        tooltip={'Query string based on Lucene Query Syntax'}
                        value={queryValue}
                        setValue={setQueryValue}
                        grow={6}
                    />
                    <Search.CopyButton onClick={() => handleSearch()}>
                      <>Search</>
                    </Search.CopyButton>
                  </Search.InputsRow>
              )}
            </Typography>


          </div>
        </AccordionSummary>
        <AccordionDetails style={{flexDirection: 'column'}}>
          {unfolded && (
              <div>
                <QueryComposer
                    kind={kindValue}
                    query={queryValue}
                    setKind={setKindValue}
                    setQuery={setQueryValue}
                    handleClose={() => setUnfolded(false)}
                />

              </div>
          )}
        </AccordionDetails>
      </Accordion>
  );
};

export default MapController;