import * as yup from 'yup';

type IProperty = {
  description?: string;
  title: string;
  type: string;
  pattern: string;
  example?: string | number | boolean | Record<string, unknown>;
  additionalProperties?: Record<string, string>;
};

export interface ISchema {
  'x-osdu-license'?: string;
  $id?: string;
  $schema?: string;
  'x-osdu-schema-source'?: string;
  title?: string;
  description?: string;
  type?: string;
  properties: Record<string, IProperty>;
  data: {
    allOf: Array<{
      type: string;
      properties: Record<string, IProperty>;
    }>;
  };
  required: string[];
  additionalProperties: boolean;
  'x-osdu-governance-model': string;
  'x-osdu-virtual-properties': {
    'data.VirtualProperties.DefaultName': {
      type: string;
      priority: Array<Record<string, string>>;
    };
  };
  'x-osdu-inheriting-from-kind': string[];
}

// const propertySchema = yup.object().shape({
//   description: yup.string().optional(),
//   title: yup.string().optional(),
//   type: yup.string().optional(),
//   pattern: yup.string().optional(),
//   example: yup.mixed(),
//   additionalProperties: yup.object().optional(),
// });

// const propertiesSchema = yup
//   .object()
//   .shape({
//     type: yup.string().required(),
//     properties: yup.lazy((obj: Record<string, unknown>) => {
//       const object: Record<string, unknown> = {};
//       for (const key of Object.keys(obj)) {
//         object[key] = propertySchema;
//       }
//       return object as unknown as yup.BaseSchema;
//     }),
//   })
//   .optional();

export const schemaSchema = yup.object().shape({
  'x-osdu-license': yup.string().optional().typeError('x-osdu-license is not a string'),
  $id: yup.string().optional().typeError('$id is not a string'),
  $schema: yup.string().optional().typeError('$schema is not a string'),
  'x-osdu-schema-source': yup.string().optional().typeError('x-osdu-schema-source is not a string'),
  title: yup.string().optional().typeError('title is not a string'),
  description: yup.string().optional().typeError('description is not a string'),
  type: yup.string().optional().typeError('type is not a string'),
  required: yup.array().of(yup.string()).optional(),
  additionalProperties: yup.boolean().optional(),
  'x-osdu-governance-model': yup.string().optional(),
  'x-osdu-virtual-properties': yup.object().shape({
    type: yup.string().optional(),
    priority: yup.object().optional(),
  }),
  'x-osdu-inheriting-from-kind': yup.array().of(yup.string()).optional(),
  properties: yup.object(),
  data: yup.object().shape({
    allOf: yup.array().of(yup.object()),
  }),
});
