import { IOptionWithGrouping } from 'components/Partials/CustomSelect/CustomSelectWithGrouping';

export const typeOptions = [
  { label: 'Any', value: '*' },
  { label: 'Generic File', value: 'dataset--File.Generic' },
  { label: 'Well', value: 'master-data--Well' },
  { label: 'Well log', value: 'work-product-component--WellLog' },
  { label: 'Wellbore', value: 'master-data--Wellbore' },
  { label: 'Wellbore Trajectory', value: 'work-product-component--WellboreTrajectory' },
];

export const availableFields = [
  'authority',
  'createTime',
  'createUser',
  'id',
  'namespace',
  'source',
  'tags',
  'type',
  'version',
  'legal.otherRelevantDataCountries',
  'legal.status',
  'legal.legaltags',
  'data.AcquisitionRemark',
  'data.ActiveIndicator',
  'data.ActivityType',
  'data.AzimuthReferenceType',
  'data.BaseDepthMeasuredDepth',
  'data.BottomMeasuredDepth',
  'data.CalculationMethodType',
  'data.CurrentOperatorID',
  'data.Datasets',
  'data.DataSourceOrganisationID',
  'data.DefaultVerticalCRSID',
  'data.DefaultVerticalMeasurementID',
  'data.DefinitiveTrajectoryID',
  'data.Description',
  'data.DrillingFluidProperty',
  'data.DrillingReasons',
  'data.ExistenceKind',
  'data.FacilityEvents',
  'data.FacilityID',
  'data.FacilityName',
  'data.FacilityOperators',
  'data.FacilityStates',
  'data.FacilityTypeID',
  'data.GeoContexts',
  'data.GeographicCRSID',
  'data.HoleTypeLogging',
  'data.InitialOperatorID',
  'data.InterestTypeID',
  'data.IsDiscoverable',
  'data.IsExtendedLoad',
  'data.KickOffWellbore',
  'data.LogActivity',
  'data.LoggingDirection',
  'data.LoggingService',
  'data.LogRun',
  'data.LogSource',
  'data.LogVersion',
  'data.Name',
  'data.NameAliases',
  'data.OperatingEnvironmentID',
  'data.PrimaryMaterialID',
  'data.ProjectedCRSID',
  'data.ResourceCurationStatus',
  'data.ResourceHomeRegionID',
  'data.ResourceLifecycleStatus',
  'data.ResourceSecurityClassification',
  'data.SequenceNumber',
  'data.ServiceCompanyID',
  'data.Source',
  'data.SubmitterName',
  'data.SurveyReferenceIdentifier',
  'data.SurveyToolTypeID',
  'data.SurveyVersion',
  'data.TargetFormation',
  'data.ToolStringDescription',
  'data.TopDepthMeasuredDepth',
  'data.TopMeasuredDepth',
  'data.TrajectoryTypeID',
  'data.VersionCreationReason',
  'data.VerticalMeasurementID',
  'data.VerticalMeasurements',
  'data.WellboreID',
  'data.WellID',
  'data.WellLogTypeID',
  'data.GeographicBottomHoleLocation.CoordinateQualityCheckPerformedBy',
  'data.GeographicBottomHoleLocation.QualitativeSpatialAccuracyTypeID',
  'data.GeographicBottomHoleLocation.QuantitativeAccuracyBandID',
  'data.GeographicBottomHoleLocation.SpatialGeometryTypeID',
  'data.GeographicBottomHoleLocation.SpatialParameterTypeID',
  'data.ProjectedBottomHoleLocation.CoordinateQualityCheckPerformedBy',
  'data.ProjectedBottomHoleLocation.QualitativeSpatialAccuracyTypeID',
  'data.ProjectedBottomHoleLocation.QuantitativeAccuracyBandID',
  'data.ProjectedBottomHoleLocation.SpatialGeometryTypeID',
  'data.ProjectedBottomHoleLocation.SpatialParameterTypeID',
  'data.SpatialArea.CoordinateQualityCheckPerformedBy',
  'data.SpatialArea.QualitativeSpatialAccuracyTypeID',
  'data.SpatialArea.QuantitativeAccuracyBandID',
  'data.SpatialArea.SpatialGeometryTypeID',
  'data.SpatialArea.SpatialParameterTypeID',
  'data.SpatialLocation.CoordinateQualityCheckPerformedBy',
  'data.SpatialLocation.QualitativeSpatialAccuracyTypeID',
  'data.SpatialLocation.QuantitativeAccuracyBandID',
  'data.SpatialLocation.SpatialGeometryTypeID',
  'data.SpatialLocation.SpatialParameterTypeID',
  'data.SpatialLocation.Wgs84Coordinates',
  'data.SpatialPoint.CoordinateQualityCheckPerformedBy',
  'data.SpatialPoint.QualitativeSpatialAccuracyTypeID',
  'data.SpatialPoint.QuantitativeAccuracyBandID',
  'data.SpatialPoint.SpatialGeometryTypeID',
  'data.SpatialPoint.SpatialParameterTypeID',
  'data.VerticalMeasurement.VerticalCRSID',
  'data.VerticalMeasurement.VerticalMeasurementDescription',
  'data.VerticalMeasurement.VerticalMeasurementPathID',
  'data.VerticalMeasurement.VerticalMeasurementSourceID',
  'data.VerticalMeasurement.VerticalMeasurementTypeID',
  'data.VerticalMeasurement.VerticalMeasurementUnitOfMeasureID',
  'data.VerticalMeasurement.VerticalReferenceID',
  'data.VerticalMeasurement.WellboreTVDTrajectoryID',
]

export const fieldOptions: IOptionWithGrouping[] = [
  {
    group: 'General',
    selectOptions: [
      {
        label: 'Authority',
        value: 'authority',
      },
      {
        label: 'Create time',
        value: 'createTime',
      },
      {
        label: 'Create user',
        value: 'createUser',
      },
      {
        label: 'Kind',
        value: 'kind',
      },
      {
        label: 'Id',
        value: 'id',
      },
      {
        label: 'Name space',
        value: 'namespace',
      },
      {
        label: 'Source',
        value: 'source',
      },
      {
        label: 'Tags',
        value: 'tags',
      },
      {
        label: 'Type',
        value: 'type',
      },
      {
        label: 'Version',
        value: 'version',
      },
    ],
  },
  {
    group: 'Legal',
    selectOptions: [
      {
        label: 'Relevant countries',
        value: 'legal.otherRelevantDataCountries',
      },
      {
        label: 'Status',
        value: 'legal.status',
      },
      {
        label: 'Tags',
        value: 'legal.legaltags',
      },
    ],
  },
  {
    group: 'Data',
    selectOptions: [
      {
        label: 'Acquisition remark',
        value: 'data.AcquisitionRemark',
      },
      {
        label: 'Active indicator',
        value: 'data.ActiveIndicator',
      },
      {
        label: 'Activity type',
        value: 'data.ActivityType',
      },
      {
        label: 'Azimuth reference type',
        value: 'data.AzimuthReferenceType',
      },
      {
        label: 'Base depth measured depth',
        value: 'data.BaseDepthMeasuredDepth',
      },
      {
        label: 'Bottom measured depth',
        value: 'data.BottomMeasuredDepth',
      },
      {
        label: 'Calculation method type',
        value: 'data.CalculationMethodType',
      },
      {
        label: 'Current operator ID',
        value: 'data.CurrentOperatorID',
      },
      {
        label: 'Datasets',
        value: 'data.Datasets',
      },
      {
        label: 'Data source organisation ID',
        value: 'data.DataSourceOrganisationID',
      },
      {
        label: 'Default vertical CRSID',
        value: 'data.DefaultVerticalCRSID',
      },
      {
        label: 'Default Vertical Measurement ID',
        value: 'data.DefaultVerticalMeasurementID',
      },
      {
        label: 'Definitive trajectory ID',
        value: 'data.DefinitiveTrajectoryID',
      },
      {
        label: 'Description',
        value: 'data.Description',
      },
      {
        label: 'Drilling fluid property',
        value: 'data.DrillingFluidProperty',
      },
      {
        label: 'Drilling reasons',
        value: 'data.DrillingReasons',
      },
      {
        label: 'Existence kind',
        value: 'data.ExistenceKind',
      },
      {
        label: 'Facility events',
        value: 'data.FacilityEvents',
      },
      {
        label: 'Facility ID',
        value: 'data.FacilityID',
      },
      {
        label: 'Facility name',
        value: 'data.FacilityName',
      },
      {
        label: 'Facility operators',
        value: 'data.FacilityOperators',
      },
      {
        label: 'Facility states',
        value: 'data.FacilityStates',
      },
      {
        label: 'Facility type ID',
        value: 'data.FacilityTypeID',
      },
      {
        label: 'Geo contexts',
        value: 'data.GeoContexts',
      },
      {
        label: 'Geographic CRSID',
        value: 'data.GeographicCRSID',
      },
      {
        label: 'Hole type logging',
        value: 'data.HoleTypeLogging',
      },
      {
        label: 'Initial operator ID',
        value: 'data.InitialOperatorID',
      },
      {
        label: 'Interest type ID',
        value: 'data.InterestTypeID',
      },
      {
        label: 'IsDiscoverable',
        value: 'data.IsDiscoverable',
      },
      {
        label: 'IsExtendedLoad',
        value: 'data.IsExtendedLoad',
      },
      {
        label: 'Kick off wellbore',
        value: 'data.KickOffWellbore',
      },
      {
        label: 'Log activity',
        value: 'data.LogActivity',
      },
      {
        label: 'Logging direction',
        value: 'data.LoggingDirection',
      },
      {
        label: 'Logging service',
        value: 'data.LoggingService',
      },
      {
        label: 'Log run',
        value: 'data.LogRun',
      },
      {
        label: 'Log source',
        value: 'data.LogSource',
      },
      {
        label: 'Log version',
        value: 'data.LogVersion',
      },
      {
        label: 'Name',
        value: 'data.Name',
      },
      {
        label: 'Name aliases',
        value: 'data.NameAliases',
      },
      {
        label: 'Operating environment ID',
        value: 'data.OperatingEnvironmentID',
      },
      {
        label: 'Primary material ID',
        value: 'data.PrimaryMaterialID',
      },
      {
        label: 'Projected CRSID',
        value: 'data.ProjectedCRSID',
      },
      {
        label: 'Resource curation status',
        value: 'data.ResourceCurationStatus',
      },
      {
        label: 'Resource home region ID',
        value: 'data.ResourceHomeRegionID',
      },
      {
        label: 'Resource lifecycle status',
        value: 'data.ResourceLifecycleStatus',
      },
      {
        label: 'Resource security classification',
        value: 'data.ResourceSecurityClassification',
      },
      {
        label: 'Sequence number',
        value: 'data.SequenceNumber',
      },
      {
        label: 'Service company ID',
        value: 'data.ServiceCompanyID',
      },
      {
        label: 'Source',
        value: 'data.Source',
      },
      {
        label: 'Submitter name',
        value: 'data.SubmitterName',
      },
      {
        label: 'Survey reference identifier',
        value: 'data.SurveyReferenceIdentifier',
      },
      {
        label: 'Survey tool type ID',
        value: 'data.SurveyToolTypeID',
      },
      {
        label: 'Survey version',
        value: 'data.SurveyVersion',
      },
      {
        label: 'Target formation',
        value: 'data.TargetFormation',
      },
      {
        label: 'Tool string description',
        value: 'data.ToolStringDescription',
      },
      {
        label: 'Top depth measured depth',
        value: 'data.TopDepthMeasuredDepth',
      },
      {
        label: 'Top measured depth',
        value: 'data.TopMeasuredDepth',
      },
      {
        label: 'Trajectory type ID',
        value: 'data.TrajectoryTypeID',
      },
      {
        label: 'Version creation reason',
        value: 'data.VersionCreationReason',
      },
      {
        label: 'Vertical measurement ID',
        value: 'data.VerticalMeasurementID',
      },
      {
        label: 'Vertical measurements',
        value: 'data.VerticalMeasurements',
      },
      {
        label: 'Wellbore ID',
        value: 'data.WellboreID',
      },
      {
        label: 'Well ID',
        value: 'data.WellID',
      },
      {
        label: 'Well log type ID',
        value: 'data.WellLogTypeID',
      },
    ],
  },
  {
    group: 'Geographic bottom hole location',
    selectOptions: [
      {
        label: 'Coordinate quality check performed by',
        value: 'data.GeographicBottomHoleLocation.CoordinateQualityCheckPerformedBy',
      },
      {
        label: 'Qualitative spatial accuracy type ID',
        value: 'data.GeographicBottomHoleLocation.QualitativeSpatialAccuracyTypeID',
      },
      {
        label: 'Quantitative accuracy band ID',
        value: 'data.GeographicBottomHoleLocation.QuantitativeAccuracyBandID',
      },
      {
        label: 'Spatial geometry type ID',
        value: 'data.GeographicBottomHoleLocation.SpatialGeometryTypeID',
      },
      {
        label: 'Spatial parameter type ID',
        value: 'data.GeographicBottomHoleLocation.SpatialParameterTypeID',
      },
    ],
  },
  {
    group: 'Projected bottom hole location',
    selectOptions: [
      {
        label: 'Coordinate quality check performed by',
        value: 'data.ProjectedBottomHoleLocation.CoordinateQualityCheckPerformedBy',
      },
      {
        label: 'Qualitative spatial accuracy type ID',
        value: 'data.ProjectedBottomHoleLocation.QualitativeSpatialAccuracyTypeID',
      },
      {
        label: 'Quantitative accuracy band ID',
        value: 'data.ProjectedBottomHoleLocation.QuantitativeAccuracyBandID',
      },
      {
        label: 'Spatial geometry type ID',
        value: 'data.ProjectedBottomHoleLocation.SpatialGeometryTypeID',
      },
      {
        label: 'Spatial parameter type ID',
        value: 'data.ProjectedBottomHoleLocation.SpatialParameterTypeID',
      },
    ],
  },
  {
    group: 'Spatial area',
    selectOptions: [
      {
        label: 'Coordinate quality check performed by',
        value: 'data.SpatialArea.CoordinateQualityCheckPerformedBy',
      },
      {
        label: 'Qualitative spatial accuracy type ID',
        value: 'data.SpatialArea.QualitativeSpatialAccuracyTypeID',
      },
      {
        label: 'Quantitative accuracy band ID',
        value: 'data.SpatialArea.QuantitativeAccuracyBandID',
      },
      {
        label: 'Spatial geometry type ID',
        value: 'data.SpatialArea.SpatialGeometryTypeID',
      },
      {
        label: 'Spatial parameter type ID',
        value: 'data.SpatialArea.SpatialParameterTypeID',
      },
    ],
  },
  {
    group: 'Spatial location',
    selectOptions: [
      {
        label: 'Coordinate quality check performed by',
        value: 'data.SpatialLocation.CoordinateQualityCheckPerformedBy',
      },
      {
        label: 'Qualitative spatial accuracy type ID',
        value: 'data.SpatialLocation.QualitativeSpatialAccuracyTypeID',
      },
      {
        label: 'Quantitative accuracy band ID',
        value: 'data.SpatialLocation.QuantitativeAccuracyBandID',
      },
      {
        label: 'Spatial geometry type ID',
        value: 'data.SpatialLocation.SpatialGeometryTypeID',
      },
      {
        label: 'Spatial parameter type ID',
        value: 'data.SpatialLocation.SpatialParameterTypeID',
      },
      {
        label: 'Wgs84 coordinates',
        value: 'data.SpatialLocation.Wgs84Coordinates',
      },
    ],
  },
  {
    group: 'Spatial point',
    selectOptions: [
      {
        label: 'Coordinate quality check performed by',
        value: 'data.SpatialPoint.CoordinateQualityCheckPerformedBy',
      },
      {
        label: 'Qualitative spatial accuracy type ID',
        value: 'data.SpatialPoint.QualitativeSpatialAccuracyTypeID',
      },
      {
        label: 'Quantitative accuracy band ID',
        value: 'data.SpatialPoint.QuantitativeAccuracyBandID',
      },
      {
        label: 'Spatial geometry type ID',
        value: 'data.SpatialPoint.SpatialGeometryTypeID',
      },
      {
        label: 'Spatial parameter type ID',
        value: 'data.SpatialPoint.SpatialParameterTypeID',
      },
    ],
  },
  {
    group: 'Vertical measurement',
    selectOptions: [
      {
        label: 'Vertical CRSID',
        value: 'data.VerticalMeasurement.VerticalCRSID',
      },
      {
        label: 'Description',
        value: 'data.VerticalMeasurement.VerticalMeasurementDescription',
      },
      {
        label: 'Path ID',
        value: 'data.VerticalMeasurement.VerticalMeasurementPathID',
      },
      {
        label: 'Source ID',
        value: 'data.VerticalMeasurement.VerticalMeasurementSourceID',
      },
      {
        label: 'Type ID',
        value: 'data.VerticalMeasurement.VerticalMeasurementTypeID',
      },
      {
        label: 'Unit of measure ID',
        value: 'data.VerticalMeasurement.VerticalMeasurementUnitOfMeasureID',
      },
      {
        label: 'Vertical reference ID',
        value: 'data.VerticalMeasurement.VerticalReferenceID',
      },
      {
        label: 'Wellbore TVD trajectory ID',
        value: 'data.VerticalMeasurement.WellboreTVDTrajectoryID',
      },
    ],
  },
];

export const operators = [
  {
    label: 'or',
    value: 'OR',
  },
  {
    label: 'and',
    value: 'AND',
  },
  {
    label: 'not',
    value: 'NOT',
  },
];
