import TextField from '@mui/material/TextField';
import styled from 'layout/theme/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Checkbox from '@mui/material/Checkbox';

export const Wrapper = styled('div')(
  ({
    theme: {
      palette: { grey4 },
    },
  }) => ({
    padding: '16px',
    boxSizing: 'border-box',
    height: '64vh',
    overflowY: 'auto',
    // 172px is height of floating "Support" button, 167px is the value of wrapper
    maxHeight: 'calc(100vh - 167px - 172px)',
    '::-webkit-scrollbar': {
      width: '5px',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: `${grey4} transparent`,
    '::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: grey4,
    },
  })
);

export const QueryWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  margin: '0 0 16px',
  gap: '0 16px',
});

export const NarrowInput = styled(TextField)({
  flexGrow: 1,
  padding: '0 0 8px',
  minWidth: '270px',
  height: 'fit-content',
});

export const WideInput = styled(TextField)({
  flexGrow: 4,
  padding: '0 0 8px',
  minWidth: '270px',
  height: 'fit-content',
});

export const Input = styled(TextField)({
  flexGrow: 2,
  padding: '0 0 12px',
  minWidth: '270px',
  height: 'fit-content',
});

export const MainWrapper = styled('div')({
  display: 'flex',
  gap: '20px',
  flexWrap: 'wrap',
});

export const KindFields = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  flexGrow: 1,
  flexBasis: '252px',
});

export const QueryFields = styled('ul')({
  gap: '0 16px',
  height: 'fit-content',
  flexBasis: '270px',
  flexGrow: 15,
  padding: '0',
});

export const SubHeader = styled('h4')(
  ({
    theme: {
      typography: { body1 },
      palette: {
        text: { primary },
      },
    },
  }) => ({
    ...body1,
    color: primary,
    padding: '0 6px 16px',
    boxSizing: 'border-box',
    margin: 0,
    width: '100%',
    height: 'fit-content',
  })
);

export const Disclaimer = styled('p')(
  ({
    theme: {
      typography: { body1 },
    },
  }) => ({
    ...body1,
    fontSize: '12px',
    width: '100%',
    margin: 'auto 0 0',
    padding: '32px 0 0',
    textAlign: 'right',
  })
);

export const FieldsRowWrapper = styled('li')(
  ({
    theme: {
      palette: {
        border: { main },
      },
    },
  }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: '0 0 8px',
    gap: '16px',
    borderBottom: `1px solid ${main}`,
  })
);

export const TrashButton = styled(IconButton)(
  ({
    theme: {
      palette: {
        secondary: { main },
        primary: { dark },
      },
    },
  }) => ({
    color: dark,
    transition: '250ms',
    ':hover': {
      color: main,
    },
  })
);

export const TrashIcon = styled(DeleteIcon)({
  width: '24px',
  height: '24px',
});

export const OperatorFiller = styled('div')({
  minHeight: '1px',
  width: '100px',
});

export const TrashFiller = styled('div')({
  minHeight: '1px',
  width: '40px',
});

export const AddButton = styled(Button)(
  ({
    theme: {
      palette: {
        primary: { dark, main },
      },
    },
  }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: dark,
    padding: '8px 0',
    backgroundColor: 'transparent',
    ':hover': {
      color: main,
      backgroundColor: 'transparent',
    },
  })
);

export const AddIcon = styled(AddCircleOutlineIcon)({
  width: '28px',
  height: '28px',
});

export const StyledCheckbox = styled(Checkbox)({
  margin: '0',
});
