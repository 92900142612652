import styled from 'layout/theme/styled';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { PaperTypeMap } from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ExpandIcon from '@mui/icons-material/Expand';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export const Container = styled(Grid)<{ component: OverridableComponent<PaperTypeMap<'div'>> }>({
  minHeight: 'calc(100vh - 224px)',
  overflow: 'hidden',
  padding: '12px',
  margin: '8px',
  width: '100%',
  '@media(min-width: 1024px)': {
    maxHeight: 'calc(100vh - 224px)',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
});

export const Header = styled('h1')(
  ({
    theme: {
      typography: { h5 },
    },
  }) => ({ ...h5, marginTop: 0, padding: '0 6px' })
);

export const HelpLink = styled('a')(
  ({
    theme: {
      palette: {
        primary: { main, light },
        white,
      },
    },
  }) => ({
    fontSize: '12px',
    borderRadius: '4px',
    fontWeight: '600',
    minWidth: 'unset',
    height: '48x',
    ':hover': {
      backgroundColor: light,
      color: white,
    },
    border: 'none',
    cursor: 'pointer',
    transition: '250ms',
    backgroundColor: 'transparent',
    color: main,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '1px 8px',
    margin: '0 8px 0 0',
    position: 'relative',
    textDecoration: 'none',
  })
);

const IconStyles = { width: '28px', height: '28px' };

export const ExpandAllIcon = styled(ExpandIcon)(IconStyles);

export const HelpIcon = styled(HelpOutlineIcon)(IconStyles);

export const CreateIcon = styled(AddCircleOutlineIcon)(IconStyles);

export const InputsRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexWrap: 'wrap',
  '> div': {
    maxWidth: '500px',
  },
});

const loading = {
  width: '100%',
  height: '4px',
};

export const Loading = styled(LinearProgress)({
  ...loading,
});

export const NotLoading = styled(LinearProgress)({
  ...loading,
  opacity: 0,
});

export const ResultsContainer = styled('div')(
  ({
    theme: {
      palette: { grey4 },
    },
  }) => ({
    minHeight: '10vh',
    width: '100%',
    flexGrow: 1,
    '@media (min-width: 1024px)': {
      overflow: 'auto',
      '::-webkit-scrollbar': {
        width: '5px',
      },
      scrollbarWidth: 'thin',
      scrollbarColor: `${grey4} transparent`,
      '::-webkit-scrollbar-track-piece': {
        backgroundColor: 'transparent',
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        backgroundColor: grey4,
      },
    },
  })
);

export const TableHeaderRow = styled('div')(
  ({
    theme: {
      palette: {
        border: { main },
      },
    },
  }) => ({
    display: 'flex',
    width: '100%',
    minHeight: '47px',
    borderBottom: `1px solid ${main}`,
    padding: '0 0 8px',
    gap: '8px',
  })
);

export const Filler = styled('div')({
  width: '64px',
  height: '1px',
});

export const Field = styled('p')(
  ({
    theme: {
      typography: { body2 },
      palette: {
        text: { primary },
      },
    },
  }) => ({
    ...body2,
    padding: '8px 0',
    color: primary,
    margin: '0',
    flexGrow: 5,
    textAlign: 'center',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    flexBasis: '100px',
  })
);

export const WideField = styled(Field)({
  flexBasis: '100px',
  '@media(min-width: 630px)': {
    flexBasis: '200px',
  },
});

export const StandardField = styled(Field)({
  flexBasis: '160px',
  flexGrow: 'unset',
});

export const NarrowField = styled(Field)({
  flexGrow: 1,
  flexBasis: '100px',
});

export const ButtonsSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: 'auto 0 auto auto',
});
