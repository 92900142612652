import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: '"AmazonEmber","Helvetica Neue","Helvetica","Arial","sans-serif"',
  h1: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    fontsize: '96px',
    letterSpacing: '-1.5px',
    lineHeight: '127px',
  },
  h2: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    fontSize: '60px',
    letterSpacing: '-0.53px',
    lineHeight: '79px',
  },
  h3: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    fontSize: '48px',
    letterSpacing: '0',
    lineHeight: '63px',
  },
  h4: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    fontSize: '34px',
    letterSpacing: '0.24px',
    lineHeight: '45px',
  },
  h5: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    fontSize: '24px',
    letterSpacing: '0',
    lineHeight: '32px',
  },
  h6: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.26px',
    lineHeight: '26px',
  },
  body1: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '16px',
    letterSpacing: '0.49px',
    lineHeight: '21px',
  },
  body2: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    letterSpacing: '0.24px',
    lineHeight: '19px',
  },
  subtitle1: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '21px',
  },
  subtitle2: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    letterSpacing: '0.24px',
    lineHeight: '19px',
  },
  button: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    letterSpacing: '1.25px',
    lineHeight: '19px',
    textTransform: 'uppercase',
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
  },
  overline: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '1.88px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  allVariants: {
    textTransform: 'none',
  },
};
