import { API } from "@aws-amplify/api";

/* eslint-disable @typescript-eslint/camelcase */
export interface FetchTrajectoryPointsResponse {
    wellbore_id: string;
    data: TrajectoryData;
}

/* eslint-enable @typescript-eslint/camelcase */

/* eslint-disable @typescript-eslint/camelcase */
interface TrajectoryData {
    measured_depths: number[];
    azimuths: number[];
    inclinations: number[];
    surface_x: number[];
    surface_y: number[];
}

/* eslint-enable @typescript-eslint/camelcase */

/**
 * Returns trajectory data for a given wellbore
 * Usable for a creation of a 3d model of the trajectory
 * Error handler is included
 * @param {String} wellboreId
 * @param {String} dataPartitionId
 */
export function fetchTrajectoryPoints(wellboreId: string, dataPartitionId: string): Promise<FetchTrajectoryPointsResponse> {
    return API.get('api', `/api/sample-app/v1/fetch/trajectory_by_wellbore_id?wellbore_id=${wellboreId}`, {
        headers: {
            'data-partition-id': dataPartitionId
        }
    })
}
