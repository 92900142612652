import styled from 'layout/theme/styled';

export const Wrapper = styled('div')(
  ({
    theme: {
      palette: { grey4 },
    },
  }) => ({
    padding: '16px',
    boxSizing: 'border-box',
    height: '64vh',
    // 172px is height of floating "Support" button, 167px is the value of wrapper
    maxHeight: 'calc(100vh - 167px - 172px)',
    overflow: 'auto',
    '::-webkit-scrollbar': {
      width: '5px',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: `${grey4} transparent`,
    '::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: grey4,
    },
  })
);

export const Row = styled('div')({
  display: 'flex',
  gap: '12px',
  flexWrap: 'wrap',
});

export const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '-6px',
});

export const FieldSetHeader = styled('h4')({ marginTop: '0' });

export const SchemaContainer = styled('div')({
  padding: '0 16px 8px 16px',
});
