import Button from '@mui/material/Button';
import styled from 'layout/theme/styled';

export const Wrapper = styled('li')<{ props: { isActive?: boolean } }>(
  ({ theme, props: { isActive } }) => ({
    width: '100%',
    transition: 'background 300ms',
    backgroundColor: isActive ? theme.palette.sideBarMenuHover : 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.sideBarMenuHover,
    },
  })
);

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '16px 32px',
  textDecoration: 'none',
  color: theme.palette.primary.contrastText,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '12px',
  lineHeight: 'initial',
  fontFamily: theme.typography.fontFamily,
  letterSpacing: '1.2px',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    textAlign: 'left',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    fontSize: '14px',
  },
}));

export const Icon = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    marginRight: 0,
  },
}));
