import Switch from '@mui/material/Switch';
import * as S from './SwitchWithLabel.style';

interface ISwitchWithLabel {
  label?: string;
  value: boolean;
  setValue: (value: boolean) => void;
  color?: string;
}

export default function SwitchWithLabel({ label, value, setValue, color }: ISwitchWithLabel) {
  return (
    <S.Wrapper props={{ color }}>
      {label && <S.Label>{label}</S.Label>}
      <Switch
        color="primary"
        checked={value}
        onChange={({ target: { checked } }) => setValue(checked)}
      />
    </S.Wrapper>
  );
}
