import styled from 'layout/theme/styled';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { PaperTypeMap } from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AlertType } from 'components/Toast/Toast';

export const Container = styled(Grid)<{ component: OverridableComponent<PaperTypeMap<'div'>> }>({
  minHeight: 'calc(100vh - 224px)',
  padding: '12px',
  margin: '8px',
  display: 'block',
  width: '100%',
});

export const Header = styled('h1')(
  ({
    theme: {
      typography: { h5 },
    },
  }) => ({ ...h5, marginTop: 0, padding: '0 6px' })
);

export const Description = styled(Typography)({
  padding: '0 6px',
  margin: '0 0 16px',
});

export const Form = styled('form')({
  maxWidth: '800px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const Textarea = styled('textarea')(
  ({
    theme: {
      typography: { subtitle1 },
      palette: {
        text: { secondary, primary },
        primary: { main },
      },
    },
  }) => ({
    resize: 'none',
    padding: '8px',
    borderRadius: '4px',
    width: '100%',
    height: '300px',
    border: `1px solid ${secondary}`,
    outline: `0px solid ${main}`,
    ':focus': {
      outline: `2px solid ${main}`,
    },
    ...subtitle1,
    color: primary,
    margin: '0 0 12px',
  })
);

export const StyledButton = styled(Button)(
  ({
    theme: {
      palette: {
        white,
        primary: { main, light },
      },
    },
  }) => ({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    color: main,
    fontSize: '12px',
    borderRadius: '4px',
    border: `1px solid ${main}`,
    fontWeight: '600',
    padding: '8px',
    height: 'fit-content',
    minWidth: 'unset',
    margin: '0 12px 8px 0',
    '&:hover': {
      backgroundColor: light,
      color: white,
    },
    transition: '250ms',
    '&:disabled': {
      color: main,
      filter: 'saturate(0) opacity(0.5)',
    },
  })
);

export const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 8px',
  flexWrap: 'wrap',
});

export const Body = styled('div')({
  display: 'flex',
  margin: '0 0 8px',
  justifyContent: 'space-between',
  gap: '16px',
  padding: '0 6px',
  flexWrap: 'wrap',
  '@media(min-width: 1024px)': {
    flexWrap: 'nowrap',
  },
});

export const Input = styled('input')({
  visibility: 'hidden',
  height: 0,
  width: 0,
});

export const SubmitButton = styled(Button)(
  ({
    theme: {
      palette: {
        white,
        primary: { light, main },
        grey2,
        black,
      },
      typography: { body1 },
    },
  }) => ({
    ...body1,
    fontWeight: '600',
    border: 'none',
    width: '74px',
    height: '38px',
    color: white,
    borderRadius: '4px',
    padding: '8px 16px',
    cursor: 'pointer',
    transition: '250ms',
    backgroundColor: main,
    ':hover': {
      color: white,
      backgroundColor: light,
    },
    ':disabled': {
      color: black,
      backgroundColor: grey2,
    },
    margin: '0 0 0 auto',
  })
);

export const Message = styled('p')<{ props: { type: AlertType } }>(
  ({
    props: { type },
    theme: {
      palette: {
        primary: { main: blue },
        secondary: { main: red },
        black,
      },
      typography: { body2 },
    },
  }) => ({
    ...body2,
    color: type === 'error' ? red : type === 'success' ? blue : black,
    margin: 'auto 8px auto 0',
    transition: '250ms',
    height: 'fit-content',
  })
);

export const FormRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});
