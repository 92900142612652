import { useState, ChangeEvent, useEffect, FormEvent } from 'react';
import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { ISchemaItem, emptySchema, statusOptions } from 'pages/SchemaPage/SchemaPageTypes';
import { IToastState } from 'components/Toast/Toast';
import { schemaSchema } from './schemaSchema';
import CustomSelect from 'components/Partials/CustomSelect/CustomSelect';
import JSONParser from 'Utils/JSONParser';
import ModalFooter from 'components/Modal/ModalFooter';
import SearchInput from 'pages/Search/Components/SearchInput/SearchInput';
import TextareaWithDrop from 'components/TextareaWithDrop/TextareaWithDrop';
import CommandPreview from 'components/CommandPreview/CommandPreview';
import * as S from './CreateSchema.style';

interface ICreateSchema {
  dataPartition: string;
  handleClose: () => void;
  schemaItem?: ISchemaItem;
  handleToast: (state: IToastState) => void;
}

export default function CreateSchema({
  dataPartition,
  handleClose,
  schemaItem,
  handleToast,
}: ICreateSchema) {
  const {
    status,
    schemaIdentity: {
      authority,
      entityType,
      schemaVersionMajor,
      schemaVersionMinor,
      schemaVersionPatch,
      source,
      id,
    },
  } = schemaItem || emptySchema;
  const [authorityValue, setAuthorityValue] = useState(authority);
  const [sourceValue, setSourceValue] = useState(source);
  const [entityTypeValue, setEntityTypeValue] = useState(entityType);
  const [schemaVersionMajorValue, setSchemaVersionMajorValue] = useState(schemaVersionMajor);
  const [schemaVersionMinorValue, setSchemaVersionMinorValue] = useState(schemaVersionMinor);
  const [schemaVersionPatchValue, setSchemaVersionPatchValue] = useState(schemaVersionPatch);
  const [statusValue, setStatusValue] = useState(status);
  const [schemaValue, setSchemaValue] = useState('');
  const [isSchemaError, setIsSchemaError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const applyLabel = schemaItem
    ? schemaVersionMajor === schemaVersionMajorValue &&
      schemaVersionMinorValue === schemaVersionMinor &&
      schemaVersionPatchValue === schemaVersionPatch
      ? 'Edit'
      : 'Create'
    : 'Create';

  const handleCreate = (schema: Record<string, unknown>) => {
    API.put('api', '/api/schema-service/v1/schema', {
      headers: {
        'data-partition-id': dataPartition,
      },
      body: {
        schemaInfo: {
          schemaIdentity: {
            authority: authorityValue,
            source: sourceValue,
            entityType: entityTypeValue,
            schemaVersionMajor: schemaVersionMajorValue,
            schemaVersionMinor: schemaVersionMinorValue,
            schemaVersionPatch: schemaVersionPatchValue,
          },
          status: statusValue,
        },
        schema: schema,
      },
    })
      .then(() => {
        handleToast({
          message: id ? 'Schema updated sucessfully' : 'Schema created succesfully',
          errorSeverity: 'success',
        });
        handleClose();
      })
      .catch(
        ({
          response: {
            data: {
              error: { message },
            },
          },
        }) => handleToast({ message, errorSeverity: 'error' })
      );
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const validSchema = JSONParser(schemaValue);

    if (!validSchema) {
      return setIsSchemaError(true);
    }
    setIsSchemaError(false);
    schemaSchema
      .validate(validSchema)
      .then((validatedSchema) => handleCreate(validatedSchema))

      .catch(() => {
        return;
      });
  };

  const clearForm = () => {
    setAuthorityValue('');
    setSourceValue('');
    setEntityTypeValue('');
    setSchemaVersionMajorValue(1);
    setSchemaVersionMinorValue(0);
    setSchemaVersionPatchValue(0);
    setStatusValue('DEVELOPMENT');
    setSchemaValue('');
  };

  const handleCopyCurl = () =>
    Auth.currentSession().then((session) => {
      const parsedSchema = JSONParser(schemaValue);
      navigator.clipboard.writeText(
        `curl --request PUT \\
--url '${process.env.REACT_APP_BASE_API_URL_DISPLAY}/api/schema-service/v1/schema' \\
--header 'accept: application/json' \\
--header 'authorization: Bearer ${session.getAccessToken().getJwtToken()}' \\
--header 'content-type: application/json' \\
--header 'data-partition-id: ${dataPartition}' \\
--data '${JSON.stringify({
          schemaInfo: {
            schemaIdentity: {
              authority: authorityValue,
              source: sourceValue,
              entityType: entityTypeValue,
              schemaVersionMajor: schemaVersionMajorValue,
              schemaVersionMinor: schemaVersionMinorValue,
              schemaVersionPatch: schemaVersionPatchValue,
            },
            status: statusValue,
          },
          schema: parsedSchema ? parsedSchema : {},
        })}'
`
      );
      handleToast({ errorSeverity: 'success', message: 'Curl command copied to clipboard' });
    });

  useEffect(() => {
    const timeout = setTimeout(() => {
      const validSchema = JSONParser(schemaValue);
      if (schemaValue && !validSchema) {
        setErrorMessage('Provided schema is not a valid JSON object.');
        return setIsSchemaError(true);
      }
      schemaSchema
        .validate(validSchema)
        .then((validatedSchema) => {
          setSchemaValue(JSON.stringify(validatedSchema, null, 2));
          setErrorMessage('');
          setIsSchemaError(false);
        })
        .catch(({ errors }) => {
          setErrorMessage(JSON.stringify(`Schema error: ${errors[0]} [1/${errors.length}]`));
          setIsSchemaError(true);
          return;
        });
    }, 500);

    return () => clearTimeout(timeout);
  }, [schemaValue]);

  useEffect(() => {
    if (!id) {
      return;
    }
    API.get('api', `/api/schema-service/v1/schema/${id}`, {
      headers: {
        'data-partition-id': dataPartition,
      },
    })
      .then((response) => setSchemaValue(JSON.stringify(response, null, 2)))
      .catch(
        ({
          response: {
            data: {
              error: { message },
            },
          },
        }) => setErrorMessage(message)
      );
  }, [id]);

  return (
    <form onSubmit={handleSubmit}>
      <S.Wrapper>
        <S.FieldSetHeader>Schema identification fields</S.FieldSetHeader>
        <S.MainInputs>
          <S.Column>
            <SearchInput
              required
              disabled={Boolean(schemaItem)}
              label={'Authority'}
              tooltip={'The implementation instance designation as used for OSDU'}
              value={authorityValue}
              setValue={setAuthorityValue}
            />
            <SearchInput
              required
              disabled={Boolean(schemaItem)}
              label={'Source'}
              value={sourceValue}
              setValue={setSourceValue}
            />
            <SearchInput
              required
              disabled={Boolean(schemaItem)}
              label={'Entity type'}
              value={entityTypeValue}
              setValue={setEntityTypeValue}
            />
          </S.Column>
          <S.Column>
            <S.VersionRow>
              <S.VersionSpacer>Version:</S.VersionSpacer>
              <S.Input
                required
                placeholder="Version Major"
                value={schemaVersionMajorValue}
                type={'number'}
                step={1}
                min={1}
                onChange={({ target: { value } }: ChangeEvent<{ value: string }>) =>
                  setSchemaVersionMajorValue(+value)
                }
              />
              <S.VersionSpacer>:</S.VersionSpacer>
              <S.Input
                required
                placeholder="Version Minor"
                value={schemaVersionMinorValue}
                type={'number'}
                step={1}
                min={0}
                onChange={({ target: { value } }: ChangeEvent<{ value: string }>) =>
                  setSchemaVersionMinorValue(+value)
                }
              />
              <S.VersionSpacer>:</S.VersionSpacer>
              <S.Input
                required
                placeholder="Version Patch"
                value={schemaVersionPatchValue}
                type={'number'}
                step={1}
                min={0}
                onChange={({ target: { value } }: ChangeEvent<{ value: string }>) =>
                  setSchemaVersionPatchValue(+value)
                }
              />
            </S.VersionRow>
            <CustomSelect
              required
              tooltip="The schema status specification"
              value={statusValue}
              setValue={setStatusValue}
              options={statusOptions}
              label="Status"
            />
          </S.Column>
        </S.MainInputs>
        <TextareaWithDrop
          required
          value={schemaValue}
          setValue={setSchemaValue}
          label={'Schema'}
          isError={isSchemaError}
        >
          <></>
        </TextareaWithDrop>
        {errorMessage && <S.Message>{errorMessage}</S.Message>}
        <CommandPreview
          style={{ maxWidth: '100%' }}
          description={`*For users convenience displayed commands present only command structure with no data, tokens and full URL. To get command ready for execution, press "Copy with credentials" button.`}
          title="Curl command"
          isCollapsible
          copyCommand={handleCopyCurl}
          command={[
            { indentLevel: 0, text: 'curl --method PUT --url "/api/schema-service/v1/schema"' },
            { indentLevel: 1, text: "--header 'accept: application/json' \\" },
            { indentLevel: 1, text: `--header 'data-partition-id': "${dataPartition}"' \\` },
            { indentLevel: 1, text: "--header 'authorization: <Bearer Token>' \\" },
            { indentLevel: 1, text: "--data '{' \\" },
            { indentLevel: 2, text: '"schemaInfo": { \\' },
            { indentLevel: 3, text: '"schemaIdentity": { \\' },
            { indentLevel: 4, text: `"authority": "${authorityValue}",\\` },
            { indentLevel: 4, text: `"source": "${sourceValue}",\\` },
            { indentLevel: 4, text: `"entityType": "${entityType}",\\` },
            { indentLevel: 4, text: `"schemaVersionMajor": ${schemaVersionMajorValue},\\` },
            { indentLevel: 4, text: `"schemaVersionMinor": ${schemaVersionMinorValue},\\` },
            { indentLevel: 4, text: `"schemaVersionPatch": ${schemaVersionPatchValue},\\` },
            { indentLevel: 4, text: `},\\` },
            { indentLevel: 3, text: `"status: "${statusValue}",\\` },
            { indentLevel: 3, text: `},\\` },
            { indentLevel: 2, text: `"schema: <SCHEMA>,\\` },
            { indentLevel: 2, text: `}` },
          ]}
        />
      </S.Wrapper>
      <ModalFooter
        isForm
        applyLabel={applyLabel}
        onCancel={() => {
          clearForm(), handleClose();
        }}
      />
    </form>
  );
}
