import { useState, MouseEvent } from 'react';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as S from './ButtonWithIcon.style';

interface IButtonWithIcon {
  iconName:
    | 'edit'
    | 'delete'
    | 'group'
    | 'pretty'
    | 'code'
    | 'copy'
    | 'expand'
    | 'download'
    | 'search'
    | 'plus'
    | 'addUser'
    | 'close'
    | 'read'
    | 'history'
    | 'common';
  label: string;
  onClick?: (event: MouseEvent) => void;
  disabled?: boolean;
  width?: string;
  isWarning?: boolean;
}

export default function ButtonWithIcon({
  label,
  iconName,
  disabled,
  onClick,
  width,
  isWarning,
}: IButtonWithIcon) {
  const isLabelVisible = useMediaQuery('(min-width: 990px)', {
    defaultMatches: true,
  });
  const [isHover, setIsHover] = useState(false);

  const icon = {
    edit: S.PenIcon,
    delete: S.TrashIcon,
    group: S.UsersIcon,
    pretty: S.PrettyIcon,
    code: S.JSONIcon,
    copy: S.CopyIcon,
    expand: S.ExpandAllIcon,
    download: S.DownloadIcon,
    common: S.CommonQueriesIcon,
    search: S.SearchIcon,
    plus: S.PlusIcon,
    addUser: S.AddUser,
    close: S.Close,
    read: S.ReadIcon,
    history: S.HistoryIcon,
  };
  const SelectedIcon = icon[iconName];

  const Button = (
    <S.ButtonWithIcon
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      props={{ width: isLabelVisible ? width : undefined, isWarning, isHover }}
      disabled={disabled}
      onClick={onClick}
      type={onClick ? 'button' : 'submit'}
    >
      <SelectedIcon props={{ isHover, isWarning }} />
      <S.Label props={{ isHover, isWarning }}>{isLabelVisible && label}</S.Label>
    </S.ButtonWithIcon>
  );
  if (isLabelVisible) {
    return Button;
  }
  return (
    <Tooltip title={label} placement="top">
      <span>{Button}</span>
    </Tooltip>
  );
}
