import { ButtonClasses } from '@mui/material/Button/buttonClasses';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { palette } from '../palette';

export const muiButton: Partial<OverridesStyleRules<keyof ButtonClasses, 'MuiButton', unknown>> = {
  root: {
    padding: '8px 19px',
    textTransform: 'none',
    borderRadius: 0,
    boxShadow: 'none',
  },
  contained: {
    color: palette.primary.main,
    backgroundColor: palette.white,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: palette.grey2,
    },
  },
  containedPrimary: {
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
  outlined: {
    borderColor: palette.white,
    color: palette.white,
    padding: '8px 19px',
    '&:hover': {
      backgroundColor: '#043D6D',
    },
  },
  outlinedPrimary: {
    padding: '8px 19px',
    '&:hover': {
      backgroundColor: '#CBDFEC',
    },
  },
  text: {
    padding: '8px 19px',
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
};
