import { ChangeEvent } from 'react';
import CaretLeft from 'layout/assets/Icons/icon_caretleft.svg';
import CaretRight from 'layout/assets/Icons/icon_caretright.svg';
import { useTheme } from '@mui/material/styles';
import * as S from './Pagination.style';

interface IPagination {
  page: number;
  count: number;
  rowsPerPage: number;
  perPageOptions?: number[];
  disabled?: boolean;
  setPage: (page: number) => void;
  setRowsPerPage: (perPage: number) => void;
  isRequestView?: boolean;
}

export default function Pagination({
  count,
  disabled,
  page,
  perPageOptions = [10],
  rowsPerPage,
  isRequestView,
  setPage,
  setRowsPerPage,
}: IPagination) {
  const handleNextPage = () => setPage(page + 1);
  const handlePreviousPage = () => setPage(page - 1);
  const firstItemIndex = page * rowsPerPage + 1;
  const lastIndex = page * rowsPerPage + rowsPerPage;
  const lastItemIndex = lastIndex > count ? count : lastIndex;
  const maxResultCount = count > 9999 ? '>9999' : count;
  const resultsRangeLabel = count > 0 ? `${firstItemIndex} - ${lastItemIndex}` : '0';
  const isPreviousDisabled = page === 0;
  const isNextDisabled = lastIndex >= count;
  const {
    palette: { requestFields },
  } = useTheme();

  return (
    <S.Container props={{ isGrayedOut: isRequestView }}>
      <S.Section>
        <S.Text>Results per page:</S.Text>
        <S.Select
          props={{ color: isRequestView ? requestFields.limit : undefined }}
          disabled={isRequestView}
          aria-label="results per page"
          value={rowsPerPage}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setRowsPerPage(+e.target.value);
          }}
        >
          {perPageOptions.map((value: number, index: number) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </S.Select>
      </S.Section>
      <S.Section>
        <S.Counter>
          <S.ColorSpan props={{ color: isRequestView ? requestFields.offset : undefined }}>
            {resultsRangeLabel}
          </S.ColorSpan>{' '}
          of {maxResultCount}
        </S.Counter>
        <S.StyledButton
          aria-label="previous page"
          onClick={handlePreviousPage}
          disabled={disabled || isPreviousDisabled || isRequestView}
        >
          <img alt={'previous page'} src={CaretLeft} />
        </S.StyledButton>
        <S.StyledButton
          aria-label="next page"
          onClick={handleNextPage}
          disabled={disabled || isNextDisabled || isRequestView}
        >
          <img alt={'next page'} src={CaretRight} />
        </S.StyledButton>
      </S.Section>
    </S.Container>
  );
}
