import styled from 'layout/theme/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const StyledBox = styled(Box)(
  ({
    theme: {
      spacing,
      palette: { white, grey2 },
    },
  }) => ({
    color: white,
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${grey2}`,
  })
);

export const Title = styled(Typography)(
  ({
    theme: {
      palette: { white },
      spacing,
    },
  }) => ({ color: white, alignSelf: 'center', paddingLeft: spacing(2), fontWeight: 700 })
);
