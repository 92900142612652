import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function RPage({ route }: { route: string }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (route === 'authenticated') {
      navigate('/Home');
    }
  }, [route]);
  return <></>;
}
