import { Box, keyframes } from '@mui/material';
import styled from 'layout/theme/styled';
import { PLATFORM_STATE } from 'types/Platform';

export const Wrapper = styled(Box)(
  ({
    theme: {
      palette: { sideBar },
    },
  }) => ({
    padding: '10px',
    width: '100%',
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderBottom: '1px solid #123f6f',
    background: sideBar,
    minHeight: '64px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  })
);

export const Label = styled('p')(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    color: white,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    margin: '0 auto 0 0',
    fontSize: '13px',
    padding: '0 4px 0 0',
  })
);

export const Small = styled('p')(
  ({
    theme: {
      palette: { white },
    },
  }) => ({
    color: white,
    textTransform: 'capitalize',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '0',
    fontSize: '13px',
    margin: '0',
  })
);

export const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const blink = keyframes`
  from {
    background-color: #388E3C;
  }

  50% {
    background-color: #FBC02D;
  }

  to {
    background-color: #388E3C;
  }
`;

export const StateIndicator = styled('div')<{ props: { state: PLATFORM_STATE } }>(
  ({
    theme: {
      palette: {
        success: { main: success },
        warning: { main: warning },
        error: { main: error },
        divider: grey
      },
    },
    props: { state },
  }) => ({
    width: '16px',
    minWidth: '16px',
    height: '16px',
    minHeight: '16px',
    margin: '0 6px 0 0',
    border: `1px solid ${grey}`,
    ...{
      ...(state === PLATFORM_STATE.TURNING_ON
        ? {
            animationName: blink,
            animationDuration: '1500ms',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'steps',
          }
        : {
            backgroundColor:
              state === PLATFORM_STATE.ON
                ? success
                : state === PLATFORM_STATE.PENDING
                ? warning
                : state === PLATFORM_STATE.CHECKING
                ? 'transparent'
                : error,
          }),
    },
  })
);
