import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { selectPlatformState } from 'store/platformStateSelectors';
import usePlatformTimeRemaining from 'Utils/usePlatformTimeRemaining';
import * as S from './PlatformStatusComponent.style';

export default function PlatformStatusComponent() {
  const { platformState } = useSelector(selectPlatformState);

  const tooltipMessage = useSelector((state: RootState) => state.platformState.tooltipMessage);
  const navigate = useNavigate();

  const readablePlatformState = platformState.toLocaleLowerCase().replace(/_/g, ' ');

  let timeText = usePlatformTimeRemaining();
  timeText = timeText ? timeText : '∞';

  return (
    <Tooltip
      onClick={() => navigate('/Settings')}
      title={
        <>
          {tooltipMessage}
          <br />
          Go to Settings to change Platform State
        </>
      }
    >
      <S.Wrapper>
        <S.Row>
          <S.Label>Platform Status</S.Label>
          <S.StateIndicator props={{ state: platformState }} />
          <S.Small>{readablePlatformState}</S.Small>
        </S.Row>
        <S.Row>
          <S.Label>Time Remaining</S.Label>
          <S.Small>{timeText}</S.Small>
        </S.Row>
      </S.Wrapper>
    </Tooltip>
  );
}
