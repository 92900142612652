import { configureStore } from '@reduxjs/toolkit';
import settingsReducer from './settingsSlice';
import platformStateReducer from './platformStateSlice';
import { wellSearchReducer } from 'pages/sample-app/store/well-search';
import { trajectoryReducer } from 'pages/sample-app/store/trajectory';
import  mapViewerReducer  from "./mapViewerStateSlice";

export const store = configureStore({
  reducer: {
    settings: settingsReducer,
    platformState: platformStateReducer,
    // @ts-ignore
    wellSearch: wellSearchReducer,
    trajectory: trajectoryReducer,
    mapViewerState: mapViewerReducer
  },
});

export { actions as settingsActions } from './settingsSlice';
export { actions as platformStateActions } from './platformStateSlice';
export { actions as mapViewerStateActions } from './mapViewerStateSlice';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
