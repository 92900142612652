import { lazy } from 'react';
import Rig from 'layout/assets/Images/platform_3_desktop-min.jpg';
import Windmill from 'layout/assets/Images/mills_desktop-min.jpg';
import Load1 from 'layout/assets/Images/petrol_1_min.jpg';
import Load2 from 'layout/assets/Images/petrol_2_min.jpg';
import CodeIcon from '@mui/icons-material/Code';
import { IPageInformation } from '../Pages';
import SchemaPage from 'pages/SchemaPage/SchemaPage';
import LoadRecords, { LOAD_TYPE } from 'pages/LoadRecords/LoadRecords';
const Cards = lazy(() => import('components/Cards/Cards'));
const Search = lazy(() => import('../Search/Search'));
const LoadManifest = lazy(() => import('pages/LoadManifest/LoadManifest'));
const LoadFile = lazy(() => import('pages/LoadFile/LoadFile'));
const LoadFileCollection = lazy(() => import('pages/LoadFileCollection/LoadFileCollection'));
const LegalTagsPage = lazy(() => import('pages/LegalTagsPage/LegalTagsPage'));

const TITLE = 'Data Console';
const URL = '/'.concat(TITLE.replace(/\s+/g, ''));
const DESCRIPTION = (
  <>
    47Lining provides Search and Load pages to enable a lightweight way to start interacting with
    the data directly using the APIs without code.
  </>
);

const CARD_ITEMS: IPageInformation[] = [
  {
    title: 'Search',
    description: (
      <>Web interface that allows search through data that is already loaded into the system.</>
    ),
    image: <img src={Windmill} alt="Search" />,
    path: '/Search',
    component: Search,
  },
  {
    title: 'Load',
    description: <>Web interface that loads new data to the system.</>,
    image: <img src={Rig} alt="Load Master Data" />,
    path: '/Load/MasterData',
  },
  {
    title: 'Manage schemas',
    description: <>Manage schemas for data ingestion.</>,
    image: <img style={{ objectPosition: 'center' }} src={Load1} alt="Load master data" />,
    path: '/Schema',
  },
  {
    title: 'Manage legal tags',
    description: (
      <>
        Manage legal tags for data governance.
      </>
    ),
    image: <img style={{ objectPosition: 'center' }} src={Load2} alt="Load" />,
    path: '/LegalTags',
    component: LegalTagsPage,
  },
];

const ITEMS: IPageInformation[] = [
  ...CARD_ITEMS,
  {
    path: '/Load/MasterData',
    component: () => <LoadRecords type={LOAD_TYPE.MASTER_DATA} />,
  },
  {
    path: '/Load/ReferenceData',
    component: () => <LoadRecords type={LOAD_TYPE.REFERENCE_DATA} />,
  },
  {
    path: '/Load/Manifest',
    component: LoadManifest,
  },
  {
    path: '/Load/File',
    component: LoadFile,
  },
  {
    path: '/Load/FileCollection',
    component: LoadFileCollection,
  },
  {
    path: '/Schema',
    component: SchemaPage,
  },
];

const pages: IPageInformation[] = [
  {
    title: TITLE,
    path: URL,
    component: () => (
      <Cards header={{ title: TITLE, description: DESCRIPTION }} items={CARD_ITEMS} />
    ),
    addToSideMenu: true,
    icon: <CodeIcon />,
  },
  ...ITEMS.filter((i) => i.component).map((i) => {
    return {
      path: i.path,
      component: i.component,
    };
  }),
];

export default pages;
