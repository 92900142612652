import { ReactNode } from 'react';
import BackToMainMenu from '../SideBar/Components/BackToMainMenu';
import { PopperPlacementType } from '@mui/material/Popper';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as S from './Popper.style';

interface IPopper {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  children: ReactNode;
  placement: PopperPlacementType;
}

export default function PopperWithStyles({
  placement = 'bottom-start',
  handleClose,
  children,
  anchorEl,
}: IPopper) {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  return (
    <S.StyledPopper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      disablePortal
      placement={placement}
    >
      {!isDesktop &&
        <BackToMainMenu handleClick={handleClose} />
      }
      {children}
    </S.StyledPopper>
  );
}
