import { lazy } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Link } from 'react-router-dom';
const Cards = lazy(() => import('components/Cards/Cards'));
const AddUsers = lazy(() => import('./Components/AddUsers'));
const LoadYourOwnData = lazy(() => import('./Components/LoadYourOwnData'));
const ValidateBenefits = lazy(() => import('./Components/ValidateBenefits/ValidateBenefits'));
import { IPageInformation } from '../Pages.d';

const TITLE = 'Meet Future Needs';
const URL = '/'.concat(TITLE.replace(/\s+/g, ''));
const DESCRIPTION = (
  <>
    Once you have fully explored the OSDU® Data Platform capabilities delivered out-of-the-box, you
    can adapt the platform to meet your requirements. You can start by
    <Link className="text-link" to="/AddUsers">
      {' '}
      adding new users{' '}
    </Link>
    and changing security to meet your corporate requirements. You can also
    <Link className="text-link" to="/LoadYourOwnData">
      {' '}
      load your own data{' '}
    </Link>
    using the APIs. Once users and data are loaded, you can better evaluate the OSDU Data
    Platform&apos;s ability to
    <Link className="text-link" to="/ValidateBenefits">
      {' '}
      support your use cases
    </Link>
    . If your organization would like to accelerate these efforts, you can take advantage of
    47Lining solution prototyping professional services.
  </>
);

const ITEMS: IPageInformation[] = [
  {
    title: 'Add Users',
    description: (
      <>
        Add additional users to your Deployment so your team can collaborate on data loading, use
        case prototyping, and validating benefits.
      </>
    ),
    path: '/AddUsers',
    component: AddUsers,
  },
  {
    title: 'Load Your Own Data',
    description: (
      <>
        Understand how to prepare and load data to the data platform, load your own test data, and
        plan your data loading strategy.
      </>
    ),
    path: '/LoadYourOwnData',
    component: LoadYourOwnData,
  },
  {
    title: 'Validate Benefits',
    description: (
      <>
        Evaluate how your enterprise can benefit from an integrated upstream data platform by
        considering your specific use cases.
      </>
    ),
    path: '/ValidateBenefits',
    component: ValidateBenefits,
  },
];

const pages: IPageInformation[] = [
  {
    title: TITLE,
    path: URL,
    component: () => <Cards header={{ title: TITLE, description: DESCRIPTION }} items={ITEMS} />,
    addToSideMenu: false,
    icon: <AssignmentIcon />,
  },
  ...ITEMS.filter((i) => i.component).map((i) => {
    return {
      path: i.path,
      component: i.component,
    };
  }),
];

export default pages;
