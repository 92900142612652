import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AlertType } from 'components/Toast/Toast';

export enum MODAL {
  PLATFORM_STATE = 'PLATFORM_STATE',
}

interface IModalState {
  selectedModal: undefined | MODAL;
  modalData?: Record<string, string>;
}

export interface PlatformState {
  toastState: { errorSeverity: AlertType; message: string };
  modalState: IModalState;
  dataPartitionId: string;
  isPlatformAdmin: boolean;
  isMultiTenantHost: boolean;
}

const initialState: PlatformState = {
  toastState: {
    message: '',
    errorSeverity: 'info',
  },
  modalState: {
    selectedModal: undefined,
  },
  dataPartitionId: 'osdu',
  isPlatformAdmin: false,
  isMultiTenantHost: false,
};

export const platformStateSlice = createSlice({
  name: 'platformState',
  initialState,
  reducers: {
    setToastState: (
      state,
      { payload }: PayloadAction<{ errorSeverity?: AlertType; message: string }>
    ) => {
      state.toastState = { ...state.toastState, ...payload };
    },
    setDataPartitionId: (state, { payload }: PayloadAction<string>) => {
      state.dataPartitionId = payload;
    },
    setIsPlatformAdmin: (state, { payload }: PayloadAction<boolean>) => {
      state.isPlatformAdmin = payload;
    },
    setIsMultiTenantHost: (state, { payload }: PayloadAction<boolean>) => {
      state.isMultiTenantHost = payload;
    },
    setModalState: (state, { payload }: PayloadAction<IModalState>) => {
      state.modalState = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('platformState/logout', () => initialState);
  },
});

export const actions = platformStateSlice.actions;

export default platformStateSlice.reducer;
