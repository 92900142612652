import LeafletMapContainer from './LeafletMapContainer'
import {mapViewerStateActions, RootState, settingsActions} from 'store';
import {useEffect, useRef, useState} from 'react';
import "leaflet-loading"
import {useDispatch, useSelector} from 'react-redux';
import * as S from './MapViewerPage.style';
import {searchGeoPoints} from "../../Utils/mapViewer";
import Card from '@mui/material/Card';
import MapController from "./MapController";
import {setWells} from "../../store/mapViewerStateSlice";
import {IErrorResponse} from "../../Utils/API";

export default function MapViewerPage() {
    const dataPartitionId = useSelector((state: RootState) => state.settings.dataPartitionId);
    const coordinates = useSelector((state: RootState) => state.mapViewerState.coordinates);
    const geoPoints = useSelector((state: RootState) => state.mapViewerState.wells);
    const wellId = useSelector((state: RootState) => state.mapViewerState.wellId);
    const map = useRef(null)
    const [error, setError] = useState('');
    const {clearWells} = mapViewerStateActions;
    const dispatcher = useDispatch()
    const initialKind = '*:*:*:*';
    const [kindValue, setKindValue] = useState(initialKind);
    const [queryValue, setQueryValue] = useState('');
    const {setToastState} = settingsActions;

    const handleSearch = () => {
        fetchData(queryValue, true)
    };

    const fetchData = async (query: string, withCursor: boolean) => {
        if (geoPoints.length != 0) {
            dispatcher(clearWells())
        }
        // Add filter to ensure all results have coordinates
        let filteredQuery = query
        if (filteredQuery && filteredQuery.trim().length > 0) {
            filteredQuery = filteredQuery + " AND "
        }
        filteredQuery = filteredQuery + "_exists_:data.SpatialLocation.Wgs84Coordinates"
        let cursor = null
        try {
            let response = await searchGeoPoints(dataPartitionId, cursor, kindValue, filteredQuery, withCursor);
            dispatcher(setWells(response.points))
            while (withCursor && response.cursor !== null) {
                cursor = response.cursor;
                // we are storing  wells in redux so the map would update with each new batch
                response = await searchGeoPoints(dataPartitionId, cursor, kindValue, filteredQuery, withCursor);
                dispatcher(setWells(response.points))
            }
        } catch (error) {
            const errorStatus = (error as IErrorResponse)?.response?.status;
            if (429 == errorStatus) {
                dispatcher(
                    setToastState({
                        errorSeverity: 'error',
                        message: "Too many query requests, please re-try after some time.",
                    })
                );
            } else {
                console.error(error)
            }
        }
    };

    useEffect(() => {
        if (wellId !== null && wellId.length > 0) {
            const wellIdQuery = `id:"${wellId}"`;
            setQueryValue(wellIdQuery)
            fetchData(wellIdQuery, false)
        }
    }, [wellId])

    useEffect(() => {
        if (map.current !== null && coordinates[0] !== 0 && coordinates[1] !== 0) {
            // @ts-ignore
            map.current.flyTo(coordinates, 13)
        }
    }, [coordinates])
    return (
        <Card>
            <Card>
                <Card>
                    <MapController
                        kindValue={kindValue}
                        queryValue={queryValue}
                        setKindValue={setKindValue}
                        setQueryValue={setQueryValue}
                        handleSearch={handleSearch}
                    />
                </Card>
                {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
            </Card>
            {!error && (
                <Card>
                    <LeafletMapContainer
                        geoPoints={geoPoints}
                        coordinates={coordinates}

                    />
                </Card>
            )}

        </Card>

    );
}
