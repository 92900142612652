import BookIcon from '@mui/icons-material/Book';
import ArticleIcon from '@mui/icons-material/Article';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import SchemaIcon from '@mui/icons-material/Schema';
import UndoIcon from '@mui/icons-material/Undo';
import SearchIcon from '@mui/icons-material/Search';
import PolicyIcon from '@mui/icons-material/Policy';

export const dataConsoleLinks = [
  {
    title: 'Back to main',
    icon: <UndoIcon />,
  },
  {
    title: 'Search',
    path: '/Search',
    icon: <SearchIcon />,
  },
  {
    title: 'Load master data',
    path: '/Load/MasterData',
    icon: <BookIcon />,
  },
  {
    title: 'Load reference data',
    path: '/Load/ReferenceData',
    icon: <ArticleIcon />,
  },
  {
    title: 'Load manifest',
    path: '/Load/Manifest',
    icon: <CollectionsBookmarkIcon />,
  },
  {
    title: 'Load file',
    path: '/Load/File',
    icon: <InsertDriveFileIcon />,
  },
  {
    title: 'Load file collection',
    path: '/Load/FileCollection',
    icon: <FileCopyIcon />,
  },
  {
    title: 'Manage schemas',
    path: '/Schema',
    icon: <SchemaIcon />,
  },
  {
    title: 'Manage legal tags',
    path: '/LegalTags',
    icon: <PolicyIcon />,
  },
];
