import Box from '@mui/material/Box';
import styled from 'layout/theme/styled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const Wrapper = styled('div')({
  display: 'flex',
});

export const Container = styled(Box)<{ props: { isLoggedIn: boolean } }>(
  ({ theme, props: { isLoggedIn } }) => ({
    paddingTop: `calc(${theme.height.topBar}px + ${theme.spacing(3)})`,
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.grey1,
    minHeight: '100vh',
    maxWidth: '100vw',
    width: '100%',
    alignItems: 'baseline',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      marginLeft: isLoggedIn ? theme.width.drawer : 0,
    },
  })
);

export const Content = styled('div')({
  flexGrow: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const HelpIcon = styled(HelpOutlineIcon)({
  alignSelf: 'center',
});

export const AccountIcon = styled(AccountCircleIcon)({
  alignSelf: 'center',
});
