import IconButton from '@mui/material/IconButton';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import * as S from './BackToMainMenu.style';

export default function BackToMainMenu({ handleClick }: { handleClick: () => void }) {
  return (
    <S.StyledBox>
      <S.Title variant="body2">Main menu</S.Title>
      <IconButton color="inherit" onClick={handleClick} size="large">
        <ArrowLeft />
      </IconButton>
    </S.StyledBox>
  );
}
