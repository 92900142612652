import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGeoPoint } from "types/MapViewer";

export interface IMapViewerState {
  wells: Array<any>;
  coordinates: Array<number>;
  loading: boolean;
  wellId: null | string
}

const initialState: IMapViewerState = {
  wells: [],
  coordinates: [0,0],
  loading: false,
  wellId: null,
};


export const setWells = createAsyncThunk(
  'mapViewerState/setWells',
  async (results : Array<IGeoPoint>) => {
    return results
  }
);

export const setLoading = createAsyncThunk(
  'mapViewerState/setLoading',
  async (loading : boolean) => {
    return loading
  }
);

export const setActiveWell = createAsyncThunk(
  'mapViewerState/setActiveWell',
  async (wellId : null | string) => {
      return wellId
  }
);

export const setCoordinates = createAsyncThunk(
  'mapViewerState/setCoordinates',
  async (coordinates : Array<number>) => {
    return coordinates
  }
);

const mapViewerStateSlice = createSlice({
  name: 'mapViewerState',
  initialState,
  reducers: {
      clearWells: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      setWells.fulfilled,
      (state, {payload} : PayloadAction<Array<IGeoPoint>>) => {
        return {
          ...state,
          wells: [
            ...state.wells, ...payload
          ]
        }
      }
    );
    builder.addCase(
      setLoading.fulfilled,
      (state, {payload} : any) => {
        return {
          ...state,
          loading: payload
        }
      }
    );
    builder.addCase(
      setCoordinates.fulfilled,
      (state, {payload} : any) => {
        return {
          ...state,
          coordinates: payload
        }
      }
    );
    builder.addCase(
      setActiveWell.fulfilled,
      (state, {payload} : any) => {
        return {
          ...state,
          wellId: payload
        }
      }
    );
  }

});

export const actions = {
  ...mapViewerStateSlice.actions,
};


export default mapViewerStateSlice.reducer