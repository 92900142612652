import styled from 'layout/theme/styled';
import Button from '@mui/material/Button';

export const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 8px',
  flexWrap: 'wrap',
});

export const HiddenInput = styled('input')({
  visibility: 'hidden',
  height: 0,
  width: 0,
});

export const StyledButton = styled(Button)(
  ({
    theme: {
      palette: {
        white,
        primary: { main, light },
      },
    },
  }) => ({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    color: main,
    fontSize: '12px',
    borderRadius: '4px',
    border: `1px solid ${main}`,
    fontWeight: '600',
    padding: '8px',
    height: 'fit-content',
    minWidth: 'unset',
    margin: '0 12px 8px 0',
    '&:hover': {
      backgroundColor: light,
      color: white,
    },
    transition: '250ms',
    '&:disabled': {
      color: main,
      filter: 'saturate(0) opacity(0.5)',
    },
  })
);

export const Textarea = styled('textarea')<{ props: { isError: boolean } }>(
  ({
    props: { isError },
    theme: {
      typography: { subtitle1 },
      palette: {
        text: { secondary, primary },
        primary: { main },
        secondary: { main: red },
      },
    },
  }) => ({
    resize: 'none',
    padding: '8px',
    borderRadius: '4px',
    width: '100%',
    height: '300px',
    border: `1px solid ${secondary}`,
    outline: isError ? `2px solid ${red}` : `0px solid ${main}`,
    ':focus': {
      outline: `2px solid ${main}`,
    },
    ...subtitle1,
    color: primary,
    margin: '0 0 12px',
  })
);
