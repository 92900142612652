import styled from 'layout/theme/styled';

export const Header = styled('div')(
  ({
    theme: {
      typography: { body2 },
      palette: {
        text: { primary },
      },
    },
  }) => ({
    ...body2,
    padding: '12px 0',
    color: primary,
    margin: 0,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    minHeight: '47px',
  })
);

export const Spacer = styled('div')(
  ({
    theme: {
      palette: { black },
    },
  }) => ({
    backgroundColor: black,
    width: '2px',
    height: '16px',
    margin: '0 4px',
  })
);

export const HeaderButton = styled('button')<{ props: { isSelected: boolean } }>(
  ({
    props: { isSelected },
    theme: {
      typography: { subtitle1 },
      palette: {
        primary: { dark },
        text: { primary, secondary },
      },
    },
  }) => ({
    ...subtitle1,
    border: 'none',
    position: 'relative',
    backgroundColor: 'transparent',
    transition: '250ms',
    cursor: 'pointer',
    color: isSelected ? primary : secondary,
    fontWeight: 600,
    ':hover': {
      color: primary,
    },
    ':disabled': {
      cursor: 'default',
      color: secondary,
    },
    ':before': {
      position: 'absolute',
      opacity: isSelected ? '1' : '0',
      width: isSelected ? 'calc(100% - 12px)' : '0',
      height: '2px',
      content: '""',
      backgroundColor: dark,
      bottom: '0',
      transition: '300ms',
      left: '6px',
    },
  })
);
