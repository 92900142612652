import styled from 'layout/theme/styled';

export const Wrapper = styled('div')({
  minHeight: '100vh',
  width: '100%',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  boxSizing: 'border-box',
  justifyContent: 'center',
  visibility: 'visible',
});

export const AuthWrapper = styled('div')({
  width: '100%',
  height: 'fit-content',
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Header = styled('h3')({
  margin: 'auto 0',
  padding: '32px 32px 0',
  color: 'rgb(21, 41, 57)',
  fontSize: '18px',
});

export const Hidden = styled('div')({
  display: 'none',
  height: '0px',
  width: '0px',
  overflow: 'hidden',
});
