import styled from 'layout/theme/styled';

export const Wrapper = styled('div')({});

export const Body = styled('div')(
  ({
    theme: {
      palette: { grey4 },
    },
  }) => ({
    maxHeight: '70vh',
    overflow: 'auto',
    padding: '8px',
    boxSizing: 'border-box',
    '::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    scrollbarWidth: 'thin',
    scrollbarHeight: 'thin',
    scrollbarColor: `${grey4} transparent`,
    '::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: grey4,
    },
  })
);

export const DataRow = styled('p')(
  ({
    theme: {
      typography: { body1 },
      palette: { black },
    },
  }) => ({
    margin: '0 0 12px',
    padding: '0 16px',
    ...body1,
    color: black,
  })
);
