import styled from 'layout/theme/styled';
import Popper from '@mui/material/Popper';

export const StyledPopper = styled(Popper)(({ theme }) => ({
  backgroundColor: theme.palette.sideBarMenu,
  borderRadius: '2px',
  zIndex: theme.zIndex.popper,
  height: `calc(100vh - ${theme.height.topBar}px)`,
  width: '100%',
  boxShadow: `0px 6px 10px rgba(0, 0, 0, 0.14)`,
  overflowY: 'auto',
  transform: 'none',
  [theme.breakpoints.up('lg')]: {
    transform: 'translate(-8px, 48px) !important',
    left: '-5px',
    backgroundColor: theme.palette.white,
    height: 'auto',
    width: 'fit-content',
  },
}));
