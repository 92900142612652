import { Timefragment } from 'types/Platform';

const SecondsToTimeFragments = (seconds: number): Timefragment => {
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h < 10 ? `0${h}` : `${h}`;
  const mDisplay = m < 10 ? `0${m}` : `${m}`;
  const sDisplay = s < 10 ? `0${s}` : `${s}`;

  const display = `${hDisplay}:${mDisplay}:${sDisplay}`;
  return {
    hour: h,
    minute: m,
    seconds: s,
    display: display === '' ? '00:00:00' : display,
  };
};
export { SecondsToTimeFragments };