import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styled from 'layout/theme/styled';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  alignSelf: 'flex-end',
  marginTop: theme.spacing(2),
  alignItems: 'baseline',
}));

export const StyledLink = styled('a')(({ theme }) => ({
  marginLeft: '20px',
  fontSize: '12px',
  letterSpacing: '0.22px',
  lineHeight: '20px',
  color: theme.palette.link,
  textDecoration: 'none',
  fontFamily: theme.typography.fontFamily,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const AboutButton = styled('button')(({ theme }) => ({
  marginLeft: '20px',
  fontSize: '12px',
  letterSpacing: '0.22px',
  lineHeight: '20px',
  backgroundColor: 'transparent',
  border: 'none',
  color: theme.palette.link,
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const CopyRightText = styled(Typography)({
  marginLeft: '20px',
  fontSize: '12px',
  letterSpacing: '0.22px',
  lineHeight: '20px',
});

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: theme.palette.link,
  },
}));
