import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import * as S from './CustomSelect.style';

export interface IOptionWithGrouping {
  group?: string;
  selectOptions: { label: string; value: string }[];
}

interface ICustomSelectWithGrouping {
  value: string;
  setValue: (value: string) => void;
  options: IOptionWithGrouping[];
  label?: string;
  width?: string;
  disabled?: boolean;
  color?: string;
}

export default function CustomSelectWithGrouping({
  value,
  setValue,
  options,
  label,
  width,
  disabled,
  color,
}: ICustomSelectWithGrouping) {
  const [isOpen, setIsOpen] = useState(false);
  const selectId = `${label}SelectWithGrouping`;
  return (
    <S.CustomFormControl props={{ width }} variant="standard">
      {label && <S.Label id={label}>{label}</S.Label>}
      <S.CustomSelect
        labelId={label}
        id={selectId}
        value={value}
        onChange={(e: SelectChangeEvent<unknown>) => setValue(e.target.value as string)}
        label={label}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        disabled={disabled}
        props={{ color }}
      >
        {options.map(({ group, selectOptions }, index: number) => {
          if (group) {
            const header = <S.OptionGroupHeader key={index}>{group}</S.OptionGroupHeader>;
            const items = selectOptions.map(({ label, value }, index2: number) => (
              <S.Option value={value} key={`${index}_${index2}`}>
                {label}
              </S.Option>
            ));
            return [header, ...items];
          }
          return selectOptions.map(({ label, value }, index2: number) => (
            <S.Option value={value} key={`${index}_${index2}`}>
              {label}
            </S.Option>
          ));
        })}
      </S.CustomSelect>
    </S.CustomFormControl>
  );
}
