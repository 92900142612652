import React from "react";

function SvgMenuHome(props) {
  return (
    <svg width={24} height={24} {...props}>
      <path data-name="Path 2153" d="M0 0h24v24H0z" fill="none" />
      <path
        data-name="Path 2154"
        d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3z"
        fill="#B2BBC1"
      />
    </svg>
  );
}

export default SvgMenuHome;
