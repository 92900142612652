import { IPageInformation } from '../Pages.d';
import Cards from 'components/Cards/Cards';
import Subflow1 from './Assets/Subflow1.svg';
import Subflow2 from './Assets/Subflow2.svg';
import Subflow3 from './Assets/Subflow3.svg';
import SearchWellsPage from './Components/SearchWellsPage';
import SynthesizeCurvePage from './Components/SynthesizeCurvePage';
import SeeNewDataPage from './Components/SeeNewDataPage';
import WorkflowPage from './Components/WorkflowPage';
import WorkflowImg from './Assets/Workflow_Simple.svg';
import * as S from './CommonStyles.style';
import { Psychology } from '@mui/icons-material';

const ITEMS: IPageInformation[] = [
  {
    component: WorkflowPage,
    path: '/EDI/Workflow',
    title: 'Workflow',
    description: (
      <>
        Click to learn more about interpretation and synthesis using interoperable well data and
        multiple OSDU™ Application Providers
      </>
    ),
    image: <S.BigContainImage src={WorkflowImg} alt="workflow description" />,
  },
  {
    component: SearchWellsPage,
    path: '/EDI/SearchWells',
    title: 'Search Wells',
    image: <S.ContainImage src={Subflow1} alt="workflow description part 1" />,
    description: <>Use IVAAP to access, discover, and interpret well data</>,
  },
  {
    component: SynthesizeCurvePage,
    path: '/EDI/SynthesizeCurve',
    title: 'Synthesize Curve',
    description: <>Use Petrabytes to calculate new well log curves</>,
    image: <S.ContainImage src={Subflow2} alt="workflow description part 2" />,
  },
  {
    component: SeeNewDataPage,
    path: '/EDI/SeeNewData',
    title: 'See New Data',
    description: <>Use your newly synthesized data in other tools</>,
    image: <S.ContainImage src={Subflow3} alt="workflow description part 3" />,
  },
];

const pages: IPageInformation[] = [
  {
    title: 'EDI IQ',
    path: '/EDI',
    component: () => <SynthesizeCurvePage />,
    addToSideMenu: true,
    icon: <Psychology />,
  },
  ...ITEMS.filter((i) => i.component).map((i) => {
    return {
      path: i.path,
      component: i.component,
    };
  }),
];

export default pages;
