import { ICustomSwaggerPage } from 'pages/CustomSwaggerPage/CustomSwaggerPage';
import * as yup from 'yup';

const legalTagsSwagger: ICustomSwaggerPage = {
  header: 'Legal tags service',
  description: `APIs to help with legal data governance in the Data Lake.`,
  endpoints: [
    {
      path: '/api/legal/v1/legaltags',
      methods: [
        {
          method: 'GET',
          id: '1',
          parameters: {
            body: [],
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            params: [],
            path: [],
          },
          responses: {},
          description: `This allows for the retrieval of all LegalTags.`,
        },
        {
          method: 'POST',
          id: '2',
          parameters: {
            body: [
              { name: 'name', type: 'string', required: true },
              { name: 'description', type: 'string', required: true },
              {
                name: 'properties',
                type: 'object',
                required: true,
                defaultValue: `{
                  "countryOfOrigin": [
                      "LR"
                  ],
                  "contractId": "No Contract Related",
                  "expirationDate": "2027-06-30",
                  "originator": "test",
                  "dataType": "Public Domain Data",
                  "securityClassification": "Private",
                  "personalData": "Personally Identifiable",
                  "exportClassification": "0A998",
                  "otherRelevantDataCountries": [
                      "US"
                  ]
              }`,
                schema: yup.object().shape({
                  countryOfOrigin: yup
                    .array()
                    .of(yup.string())
                    .required('countryOfOrigin is required')
                    .typeError('countryOfOrigin should be an array of strings'),
                  contractId: yup
                    .string()
                    .required('contractId is required')
                    .typeError('contractId should be a string'),
                  expirationDate: yup
                    .string()
                    .required('expirationDate is required')
                    .typeError('expirationDate should be a string'),
                  originator: yup
                    .string()
                    .required('originator is required')
                    .typeError('originator should be a string'),
                  dataType: yup
                    .string()
                    .required('datatype is required')
                    .typeError('dataType should be a string'),
                  securityClassification: yup
                    .string()
                    .required('securityClassification is required')
                    .typeError('securityClassification should be a string'),
                  personalData: yup
                    .string()
                    .required('personalData is required')
                    .typeError('personalData should be a string'),
                  exportClassification: yup
                    .string()
                    .required('exportClassification is required')
                    .typeError('exportClassification should be a string'),
                }),
              },
            ],
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            params: [],
            path: [],
          },
          responses: {
            '201': {
              "name": "string",
              "description": "string",
              "properties": {
                "countryOfOrigin": [
                  "string"
                ],
                "contractId": "string",
                "expirationDate": "Date",
                "originator": "test",
                "dataType": "string",
                "securityClassification": "string",
                "personalData": "string",
                "exportClassification": "string"
              }
            },
            '4XX':
            {
              "message": "Request failed with status code 4XX",
              "code": "ERR_BAD_REQUEST",
              "status": 'number',
              "data": {
                "code": 'number',
                "reason": "string",
                "message": "string"
              }
            }
          },
          description: `This allows for the creation of your LegalTag. There can only be 1 LegalTag per 'name'. A LegalTag must be created before you can start ingesting data for that name.`,
        },
        {
          method: 'PUT',
          id: '3',
          parameters: {
            body: [
              { name: 'name', type: 'string', required: true },
              { name: 'contractId', type: 'string', required: true },
              { name: 'description', type: 'string', required: false },
              { name: 'expirationDate', type: 'string', required: false },
            ],
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
              { name: 'OSDU-Account-Id', type: 'string' },
              { name: 'OSDU-On-Behalf-Of', type: 'string' },
            ],
            params: [],
            path: [],
          },
          responses: {
            '200': {
              name: 'OSDU-Private-EHCData',
              description: 'string',
              properties: {
                countryOfOrigin: 'US',
                contractId: 'No Contract Related',
                expirationDate: '2025-12-25',
                originator: 'Schlumberger',
                dataType: 'Third Party Data',
                securityClassification: 'Private',
                personalData: 'No Personal Data',
                exportClassification: 'EAR99',
              },
            },
          },
          description: `This allows to update certain properties of your LegalTag using the 'name' associated with it.`,
        },
        {
          method: 'DELETE',
          id: '4',
          pathParamPattern: '/{legalTagName}',
          parameters: {
            body: [],
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            params: [],
            path: [{ name: 'legalTagName', type: 'string', required: true }],
          },
          responses: {
            '204': '',
          },
          description: `This allows to delete certain legal tag by its name.`,
        },
      ],
    },
    {
      path: '/api/legal/v1/legaltags',
      methods: [
        {
          method: 'GET',
          id: '1',
          pathParamPattern: '/{name}',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'OSDU-On-Behalf-Of',
                type: 'string',
                description: `User's email or auth token`,
              },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            params: [],
            path: [
              {
                name: 'name',
                type: 'string',
                required: true,
              },
            ],
          },
          responses: {
            '200': {
              name: 'string',
              description: 'string',
              properties: {
                countryOfOrigin: ['string'],
                contractId: 'string',
                expirationDate: 'Date',
                originator: 'string',
                dataType: 'string',
                securityClassification: 'string',
                personalData: 'string',
                exportClassification: 'string',
              },
            },
            '404': {
              message: 'Request failed with status code 404',
              code: 'ERR_BAD_REQUEST',
              status: '',
              data: {
                error: 'Not found.',
              },
            },
          },
          description: `This allows for the retrieval of your LegalTag using the 'name' associated with it.`,
        },
      ],
    },
    {
      path: '/api/legal/v1/legaltags:properties',
      methods: [
        {
          method: 'GET',
          id: '5',
          parameters: {
            body: [],
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'OSDU-On-Behalf-Of',
                type: 'string',
                description: `User's email or auth token`,
              },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            params: [],
            path: [],
          },
          responses: {
            '200': {
              countriesOfOrigin: {
                additionalProp1: 'string',
                additionalProp2: 'string',
                additionalProp3: 'string',
              },
              otherRelevantDataCountries: {
                additionalProp1: 'string',
                additionalProp2: 'string',
                additionalProp3: 'string',
              },
              securityClassifications: ['string'],
              exportClassificationControlNumbers: ['string'],
              personalDataTypes: ['string'],
            },
          },
          description: `This allows for the retrieval of all LegalTags.`,
        },
      ],
    },
    {
      path: '/api/legal/v1/legaltags:validate',
      methods: [
        {
          method: 'POST',
          id: '6',
          parameters: {
            body: [
              {
                name: 'names',
                type: 'array',
                description:
                  'This allows for the retrieval of the reason why your LegalTag is not valid. A maximum of 25 can be retrieved at once.',
                required: true,
                schema: yup.array().of(yup.string()).typeError('should be an array of strings'),
              },
            ],
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'OSDU-On-Behalf-Of',
                type: 'string',
                description: `User's email or auth token`,
              },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            params: [],
            path: [],
          },
          responses: {
            '200': {
              invalidLegalTags: [
                {
                  name: 'string',
                  reason: 'string',
                },
              ],
            },
          },
          description: `This allows for the retrieval of the reason why your LegalTag is not valid. A maximum of 25 can be retrieved at once.`,
        },
      ],
    },
    {
      path: '/api/legal/v1/legaltags:batchRetrieve',
      methods: [
        {
          method: 'POST',
          id: '8',
          parameters: {
            body: [
              {
                name: 'names',
                type: 'array',
                description:
                  'This allows for the retrieval of the reason why your LegalTag is not valid. A maximum of 25 can be retrieved at once.',
                required: true,
                schema: yup.array().of(yup.string()).typeError('should be an array of strings'),
              },
            ],
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'OSDU-On-Behalf-Of',
                type: 'string',
                description: `User's email or auth token`,
              },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            params: [],
            path: [],
          },
          responses: {
            '200': {
              legalTags: [
                {
                  name: 'string',
                  description: 'string',
                  properties: {
                    countryOfOrigin: ['string'],
                    contractId: 'string',
                    expirationDate: 'Date',
                    originator: 'string',
                    dataType: 'string',
                    securityClassification: 'string',
                    personalData: 'string',
                    exportClassification: 'string',
                  },
                },
              ],
            },
            '404': {
              message: 'Request failed with status code 404',
              code: 'ERR_BAD_REQUEST',
              status: '',
              data: {
                legalTags: [
                  {
                    name: 'string',
                    description: 'string',
                    properties: {
                      countryOfOrigin: ['string'],
                      contractId: 'string',
                      expirationDate: 'Date',
                      originator: 'string',
                      dataType: 'string',
                      securityClassification: 'string',
                      personalData: 'string',
                      exportClassification: 'string',
                    },
                  },
                ],
              },
            },
          },
          description: `This allows for the retrieval of your LegalTags using the 'name' associated with it. A maximum of 25 can be retrieved at once.`,
        },
      ],
    },
    {
      path: '/api/legal/v1/info',
      methods: [
        {
          method: 'GET',
          id: '9',
          parameters: {
            header: [
              { name: 'data-partition-id', type: 'string', required: true },
              {
                name: 'Authorization',
                required: true,
                type: 'string',
                disabled: true,
                defaultValue: '<JWT Token>',
                description: 'This parameter will be filled automatically',
              },
            ],
            params: [],
            path: [],
          },
          responses: {
            '200': {
              groupId: 'string',
              artifactId: 'string',
              version: 'string',
              buildTime: 'Date',
              branch: 'string',
              commitId: 'string',
              commitMessage: 'string',
              connectedOuterServices: 'array',
            },
          },
          description: `For deployment available public /info endpoint, which provides build and git related information.`,
        },
      ],
    },
  ],
};

export default legalTagsSwagger;
