import { lazy } from 'react';
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
const SampleApp = lazy(() => import('./SampleApp'));

export default [
  {
    title: 'Visualization App',
    path: '/OsduVisualizationApp',
    component: () => <SampleApp />,
    addToSideMenu: true,
    icon: <ImageSearchIcon />,
  },
];
