import { useEffect, useState } from 'react';
import { API } from '@aws-amplify/api';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonWithIcon from 'components/Partials/ButtonWithIcon/ButtonWithIcon';
import CustomSelect from 'components/Partials/CustomSelect/CustomSelect';
import Modal from 'components/Modal/Modal';
import Pagination from 'components/Pagination/Pagination';
import Paper from '@mui/material/Paper';
import SchemaRequestPreview from 'components/RequestPreview/SchemaRequestPreview';
import SchemaResult from './Components/SchemaResult/SchemaResult';
import SearchInput from 'pages/Search/Components/SearchInput/SearchInput';
import SearchNavigation, { VIEW_TYPE } from 'components/Partials/SearchNavigation/SearchNavigation';
import SwitchWithLabel from 'components/Partials/SwitchWithLabel/SwitchWithLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/system/useTheme';
import CreateSchema from './Components/CreateSchema/CreateSchema';
import ViewSchema from './Components/ViewSchema/ViewSchema';
import {
  ISchemaList,
  ISchemaListResp,
  ISchemaPayload,
  initialPagination,
  scopeOptions,
  statusOptions,
  ISchemaItem,
} from './SchemaPageTypes';
import compareDates from 'Utils/compareDates';
import * as S from './SchemaPage.style';
import { useDispatch, useSelector } from 'react-redux';
import { settingsActions, RootState } from 'store';

export default function SchemaPage() {
  const navigate = useNavigate();
  const [viewType, setViewType] = useState<VIEW_TYPE>(VIEW_TYPE.RESULTS);
  const { search, pathname } = useLocation();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [pagination, setPagination] = useState(initialPagination);
  const [authorityValue, setAuthorityValue] = useState('');
  const [entityTypeValue, setEntityTypeValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [sourceValue, setSourceValue] = useState('');
  const [scopeValue, setScopeValue] = useState('');
  const [versionValue, setVersionsValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLatestVersion, setIsLatestVersion] = useState(false);
  const [schemas, setSchemas] = useState<ISchemaItem[]>([]);
  const [openSchemas, setOpenSchemas] = useState<string[]>([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isForcedRefresh, setIsForcedRefresh] = useState(false);
  const [schemaForEdition, setSchemaForEdition] = useState<ISchemaItem>();

  const { setToastState } = settingsActions;
  const dispatch = useDispatch();
  const dataPartitionId = useSelector((state: RootState) => state.settings.dataPartitionId);

  const [dataPartitionValue, setDataPartitionValue] = useState(dataPartitionId);

  const handleSetPagination = (newPagination: { page?: number; rowsPerPage?: number }) => {
    const { page, rowsPerPage } = newPagination;
    setOpenSchemas([]);
    setPagination({ ...pagination, ...newPagination });
    const searchParams = new URLSearchParams(search);
    page && searchParams.set('offset', String(page));
    rowsPerPage && searchParams.set('limit', String(rowsPerPage));
    navigate(`${pathname}?${searchParams.toString()}`);
    const limit = rowsPerPage ? rowsPerPage : pagination.rowsPerPage;
    getSchemaList({ offset: page, limit });
  };

  const theme = useTheme();

  const {
    palette: { requestFields },
  } = theme;

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const isTablet = useMediaQuery('(min-width: 778px)', {
    defaultMatches: true,
  });

  const handleExpand = () => {
    if (openSchemas.length === schemas.length) {
      setOpenSchemas([]);
    } else {
      const all = schemas.map(({ schemaIdentity: { id } }) => id);
      setOpenSchemas(all);
    }
  };

  const handleOpenCloseSchema = (id: string) => {
    const isOpen = openSchemas.includes(id);
    if (isOpen) {
      const otherOpenSchemas = openSchemas.filter((currentId) => currentId !== id);
      setOpenSchemas(otherOpenSchemas);
    } else {
      setOpenSchemas([...openSchemas, id]);
    }
  };

  const getSchemaList = async (params: ISchemaList) => {
    const { page, rowsPerPage } = pagination;
    const { 0: major, 1: minor } = versionValue.split(':');
    const payloadRaw: ISchemaPayload = {
      offset: page,
      limit: rowsPerPage,
      dataPartition: dataPartitionValue,
      authority: authorityValue,
      entityType: entityTypeValue,
      status: statusValue,
      source: sourceValue,
      scope: scopeValue,
      schemaVersionMajor: major,
      schemaVersionMinor: minor,
      latestVersion: isLatestVersion,
      ...params,
    };
    const queryStringParameters: Record<string, undefined> = {};

    for (const [key, value] of Object.entries(payloadRaw)) {
      if (value || value === 0) {
        queryStringParameters[key] = value;
      }
    }
    const { limit = 10, offset = 0 } = queryStringParameters;
    setIsLoading(true);
    API.get('api', '/api/schema-service/v1/schema', {
      headers: {
        'data-partition-id': dataPartitionId,
      },
      queryStringParameters: {
        ...queryStringParameters,
        limit,
        offset: offset * limit,
      },
    })
      .then(({ schemaInfos, totalCount }: ISchemaListResp) => {
        const sorted = schemaInfos.sort((a, b) => compareDates(a.dateCreated, b.dateCreated));
        setSchemas(sorted);
        setPagination({
          count: totalCount,
          page: offset,
          rowsPerPage: payloadRaw.limit ? payloadRaw.limit : 10,
        });
        setIsLoading(false);
      })
      .catch(
        ({
          response: {
            data: {
              error: { message },
            },
          },
        }) => {
          setIsLoading(false);
          setSchemas([]);
          dispatch(setToastState({ errorSeverity: 'error', message }));
        }
      );
  };

  useEffect(() => {
    const fetchValues: ISchemaPayload = {};
    const searchParams = new URLSearchParams(search);
    const offsetTemp = searchParams.get('offset');
    const limitTemp = searchParams.get('limit');
    const tempCount = searchParams.get('count');
    if (offsetTemp || limitTemp || tempCount) {
      const validLimit = limitTemp ? +limitTemp : 10;
      const validOffset = offsetTemp ? +offsetTemp : 0;

      setPagination({
        count: tempCount ? +tempCount : 0,
        page: validOffset,
        rowsPerPage: validLimit,
      });
      fetchValues.limit = validLimit;
      fetchValues.offset = validOffset;
    }
    const tempStatus = searchParams.get('status');
    if (tempStatus) {
      setStatusValue(tempStatus);
      fetchValues.status = tempStatus;
    }
    const tempScope = searchParams.get('scope');
    if (tempScope) {
      setScopeValue(tempScope);
      fetchValues.scope = tempScope;
    }
    const authorityScope = searchParams.get('authority');
    if (authorityScope) {
      setAuthorityValue(authorityScope);
      fetchValues.authority = authorityScope;
    }
    const sourceTemp = searchParams.get('source');
    if (sourceTemp) {
      setSourceValue(sourceTemp);
      fetchValues.source = sourceTemp;
    }
    const entityTypeTemp = searchParams.get('entityType');
    if (entityTypeTemp) {
      setEntityTypeValue(entityTypeTemp);
      fetchValues.entityType = entityTypeTemp;
    }
    const versionTemp = searchParams.get('version');
    if (versionTemp) {
      const { 0: one, 1: two } = versionValue.split(':');
      setVersionsValue(versionTemp);
      if (one) {
        fetchValues.schemaVersionMajor = one;
      }
      if (two) {
        fetchValues.schemaVersionMinor = two;
      }
    }
    const latestVersionTemp = searchParams.get('latestVersion');
    if (latestVersionTemp) {
      const isLatestTemp = latestVersionTemp === 'true';
      setIsLatestVersion(isLatestTemp);
      fetchValues.latestVersion = isLatestTemp;
    }

    if (isFirstRender || isForcedRefresh) {
      getSchemaList(fetchValues);
      setIsFirstRender(false);
      setIsForcedRefresh(false);
    }
  }, [isForcedRefresh]);

  useEffect(() => {
    setOpenSchemas([]);
    const timeout = setTimeout(() => {
      const query = new URLSearchParams();
      const { rowsPerPage } = pagination;
      const newPagination = { rowsPerPage, page: 0, count: 0 };
      setPagination(newPagination);
      query.append('limit', String(rowsPerPage));
      query.append('offset', String(0));
      if (statusValue) {
        query.append('status', statusValue);
      }
      if (scopeValue) {
        query.append('scope', scopeValue);
      }
      if (authorityValue) {
        query.append('authority', authorityValue);
      }
      if (sourceValue) {
        query.append('source', sourceValue);
      }
      if (entityTypeValue) {
        query.append('entityType', entityTypeValue);
      }
      if (versionValue) {
        query.append('version', versionValue);
      }
      navigate(`${pathname}?${query.toString()}`);
      if (!isLoading && !isFirstRender) {
        getSchemaList({ limit: rowsPerPage, offset: 0 });
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [
    statusValue,
    scopeValue,
    dataPartitionValue,
    authorityValue,
    statusValue,
    sourceValue,
    scopeValue,
    entityTypeValue,
    versionValue,
    isLatestVersion,
  ]);

  return (
    <S.Container container component={Paper} spacing={3}>
      <S.Header>Schema manager</S.Header>
      <Modal
        isOpen={isCreateModalOpen}
        handleClose={() => setIsCreateModalOpen(false)}
        header={schemaForEdition ? `Edit ${schemaForEdition.schemaIdentity.id}` : 'Create schema'}
        component={
          <CreateSchema
            dataPartition={dataPartitionValue}
            handleClose={() => setIsCreateModalOpen(false)}
            schemaItem={schemaForEdition}
            handleToast={setToastState}
          />
        }
      />
      <Modal
        isOpen={isViewModalOpen}
        handleClose={() => setIsViewModalOpen(false)}
        header={schemaForEdition ? `View ${schemaForEdition.schemaIdentity.id}` : ''}
        component={
          <ViewSchema
            dataPartition={dataPartitionValue}
            handleClose={() => setIsViewModalOpen(false)}
            schemaItem={schemaForEdition}
            handleToast={setToastState}
          />
        }
      />
      <S.InputsRow>
        <SearchInput
          disabled={isLoading}
          label={'Data Partition ID'}
          tooltip={
            'Specifies the data partition to use. This should be either the partition name or crm account ID associated with the partition.'
          }
          value={dataPartitionValue}
          setValue={setDataPartitionValue}
          color={viewType === VIEW_TYPE.REQUEST ? requestFields.dataPartition : undefined}
        />
        <SearchInput
          disabled={isLoading}
          label={'Authority'}
          value={authorityValue}
          setValue={setAuthorityValue}
          color={viewType === VIEW_TYPE.REQUEST ? requestFields.kind : undefined}
        />
        <SearchInput
          disabled={isLoading}
          label={'Source'}
          value={sourceValue}
          setValue={setSourceValue}
          color={viewType === VIEW_TYPE.REQUEST ? requestFields.aggregation : undefined}
        />
        <SearchInput
          disabled={isLoading}
          label={'Entity type'}
          value={entityTypeValue}
          setValue={setEntityTypeValue}
          color={viewType === VIEW_TYPE.REQUEST ? requestFields.query : undefined}
        />
        <SearchInput
          disabled={isLoading}
          label={'Version'}
          tooltip={'Schema version. Only major and minor part are applicable for search'}
          value={versionValue}
          setValue={setVersionsValue}
          color={viewType === VIEW_TYPE.REQUEST ? requestFields.sort : undefined}
        />
        <CustomSelect
          disabled={isLoading}
          tooltip="The schema status specification"
          value={statusValue}
          setValue={setStatusValue}
          options={statusOptions}
          label="Status"
          color={viewType === VIEW_TYPE.REQUEST ? requestFields.status : undefined}
        />
        <CustomSelect
          disabled={isLoading}
          tooltip="The scope or schema visibility specification"
          value={scopeValue}
          setValue={setScopeValue}
          options={scopeOptions}
          label="Scope"
          color={viewType === VIEW_TYPE.REQUEST ? requestFields.scope : undefined}
        />
        <SwitchWithLabel
          label="Latest version"
          value={isLatestVersion}
          setValue={setIsLatestVersion}
          color={viewType === VIEW_TYPE.REQUEST ? requestFields.latest : undefined}
        />
      </S.InputsRow>
      <S.InputsRow>
        <SearchNavigation
          isFetchingData={isLoading}
          setViewType={setViewType}
          viewType={viewType}
          hasAggregations={false}
          hasNotFoundResults={pagination.count === 0}
        />
        <S.ButtonsSection>
          <S.HelpLink
            href={
              'https://community.opengroup.org/osdu/platform/system/schema-service/-/blob/master/docs/api/schema_openapi.yaml#/Schema/searchSchemaInfoRepository'
            }
            target="_blank"
          >
            <S.HelpIcon />
            Help
          </S.HelpLink>
          <ButtonWithIcon
            iconName="expand"
            label={openSchemas.length === schemas.length ? 'Collapse all' : 'Expand all'}
            disabled={isLoading}
            onClick={handleExpand}
            width={'134px'}
          />
          <ButtonWithIcon
            iconName="plus"
            label={'Create schema'}
            disabled={isLoading}
            onClick={() => {
              setSchemaForEdition(undefined);
              setIsCreateModalOpen(true);
            }}
          />
        </S.ButtonsSection>
      </S.InputsRow>
      {isLoading ? (
        <S.Loading aria-hidden={!isLoading} />
      ) : (
        <S.NotLoading aria-hidden={!isLoading} />
      )}
      <S.TableHeaderRow>
        <S.Filler aria-hidden />
        <S.WideField>ID</S.WideField>
        {isDesktop && (
          <>
            <S.NarrowField>Created by</S.NarrowField>
            <S.NarrowField>Created at</S.NarrowField>
          </>
        )}
        {isTablet && (
          <>
            <S.NarrowField>Status</S.NarrowField>
            <S.NarrowField>Scope</S.NarrowField>
          </>
        )}
        <S.StandardField>Actions</S.StandardField>
      </S.TableHeaderRow>
      <S.ResultsContainer>
        {viewType === VIEW_TYPE.RESULTS ? (
          schemas.map((item, index) => (
            <SchemaResult
              handleRefresh={() => setIsForcedRefresh(true)}
              handleToast={setToastState}
              dataPartition={dataPartitionValue}
              key={index}
              result={item}
              handleOpen={handleOpenCloseSchema}
              isOpen={openSchemas.includes(item.schemaIdentity.id)}
              handleView={() => {
                setIsViewModalOpen(true), setSchemaForEdition(item);
              }}
              handleEdit={() => {
                setIsCreateModalOpen(true), setSchemaForEdition(item);
              }}
            />
          ))
        ) : (
          <SchemaRequestPreview
            dataPartitionId={dataPartitionValue}
            entityType={entityTypeValue}
            authority={authorityValue}
            latestVersion={isLatestVersion}
            scope={scopeValue}
            source={sourceValue}
            status={statusValue}
            limit={pagination.rowsPerPage}
            offset={pagination.page * pagination.rowsPerPage}
            schemaVersionMajor={versionValue.split(':')[0]}
            schemaVersionMinor={versionValue.split(':')[1]}
            handleToast={(message: string) =>
              dispatch(setToastState({ message, errorSeverity: 'success' }))
            }
          />
        )}
      </S.ResultsContainer>
      <Pagination
        {...pagination}
        isRequestView={viewType === VIEW_TYPE.REQUEST}
        perPageOptions={[10, 15, 20, 50]}
        setPage={(page: number) => handleSetPagination({ page })}
        setRowsPerPage={(rowsPerPage: number) => handleSetPagination({ rowsPerPage })}
        disabled={isLoading}
      />
    </S.Container>
  );
}
