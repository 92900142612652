import { memo } from 'react';
import * as S from './Field.style';

export default memo(function Field({ label, value }: { label: string; value: string | number }) {
  return (
    <S.Parameter>
      <S.Label>{label}</S.Label>
      <S.Value>{value}</S.Value>
    </S.Parameter>
  );
});
