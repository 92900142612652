import styled from 'layout/theme/styled';

export const Wrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  padding: '0 6px 16px',
  boxSizing: 'border-box',
  position: 'relative',
});

export const HeaderContainer = styled('div')({
  flexGrow: 1,
});

export const BodyContainer = styled('div')({
  flexGrow: 3,
});

export const Header = styled('h2')(
  ({
    theme: {
      typography: { h5 },
    },
  }) => ({
    margin: '0 0 16px',
    ...h5,
  })
);

export const Row = styled('p')<{ props?: { color?: string } }>(
  ({
    props: { color } = {},
    theme: {
      typography: { body1 },
      palette: {
        text: { primary },
      },
    },
  }) => ({
    ...body1,
    margin: '0 0 4px',
    padding: '2px',
    borderRadius: '2px',
    color: primary,
    backgroundColor: color,
    width: 'fit-content',
  })
);

export const IndentedRow = styled(Row)({
  padding: '0 0 0 16px',
});

export const CurlWrapper = styled('div')({
  width: '100%',
});

export const CurlCommandContainer = styled('div')(
  ({
    theme: {
      palette: { grey2 },
      typography: { body1 },
    },
  }) => ({
    ...body1,
    backgroundColor: grey2,
    padding: '12px',
    boxSizing: 'border-box',
    fontFamily: 'Roboto Mono',
    whiteSpace: 'pre-line',
    margin: '12px 0 0',
  })
);

export const IndentLevelOne = styled('p')<{ props?: { color?: string } }>(
  ({ props: { color } = {} }) => ({
    margin: '0 0 4px',
    padding: '2px',
    borderRadius: '2px',
    marginLeft: '51px',
    backgroundColor: color,
    width: 'fit-content',
  })
);

export const IndentLevelTwo = styled(IndentLevelOne)({
  marginLeft: '72px',
});

export const IndentLevelThree = styled(IndentLevelOne)({
  marginLeft: '92px',
});

export const Intro = styled('p')(
  ({
    theme: {
      typography: { body1 },
      palette: {
        text: { primary },
        white,
      },
    },
  }) => ({
    ...body1,
    color: primary,
    width: '100%',
    margin: '0 0 24px',
    textAlign: 'center',
    fontStyle: 'italic',
    position: 'sticky',
    top: '0',
    padding: '16px 8px 8px',
    boxSizing: 'border-box',
    backgroundColor: white,
  })
);
