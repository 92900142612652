import { Auth } from 'aws-amplify';
import { useTheme } from '@mui/material';
import ButtonWithIcon from 'components/Partials/ButtonWithIcon/ButtonWithIcon';
import { ISchemaPayload } from '../../pages/SchemaPage/SchemaPageTypes';
import CommandPreview from 'components/CommandPreview/CommandPreview';
import * as S from './RequestPreview.style';

interface ISchemaRequestPreview extends ISchemaPayload {
  handleToast: (message: string) => void;
  dataPartitionId: string;
}

export default function SchemaRequestPreview({
  authority,
  entityType,
  latestVersion,
  limit,
  offset,
  schemaVersionMajor,
  schemaVersionMinor,
  scope,
  source,
  status,
  dataPartitionId,
  handleToast,
}: ISchemaRequestPreview) {
  const {
    palette: { requestFields },
  } = useTheme();

  const copyCurlCommand = () =>
    Auth.currentSession().then((session) => {
      navigator.clipboard.writeText(
        `curl --request GET \
  --url '${
    process.env.REACT_APP_BASE_API_URL_DISPLAY
  }/api/schema-service/v1/schema?${getParams()}' \
  --header 'accept: application/json' \
  --header 'authorization: Bearer ${session.getAccessToken().getJwtToken()}' \
  --header 'content-type: application/json' \
  --header 'data-partition-id: ${dataPartitionId}'
  `
      );
      handleToast('Curl command copied to clipboard');
    });

  const copyGetDetailsCommand = () =>
    Auth.currentSession().then((session) => {
      navigator.clipboard.writeText(
        `curl --request GET \
  --url '${process.env.REACT_APP_BASE_API_URL_DISPLAY}/api/schema-service/v1/schema/{id}' \
  --header 'accept: application/json' \
  --header 'authorization: Bearer ${session.getAccessToken().getJwtToken()}' \
  --header 'content-type: application/json' \
  --header 'data-partition-id: ${dataPartitionId}'
  `
      );
      handleToast('Curl command copied to clipboard');
    });

  const copyEditSchemaCommand = () =>
    Auth.currentSession().then((session) => {
      navigator.clipboard.writeText(
        `curl --request PUT \
  --url '${process.env.REACT_APP_BASE_API_URL_DISPLAY}/api/schema-service/v1/schema' \
  --header 'accept: application/json' \
  --header 'authorization: Bearer ${session.getAccessToken().getJwtToken()}' \
  --header 'content-type: application/json' \
  --header 'data-partition-id: ${dataPartitionId}'
  `
      );
      handleToast('Curl command copied to clipboard');
    });

  const getParams = () => {
    const query = new URLSearchParams();
    query.append('limit', String(limit));
    query.append('offset', String(0));
    query.append('latestVersion', String(latestVersion));
    if (status) {
      query.append('status', status);
    }
    if (scope) {
      query.append('scope', scope);
    }
    if (authority) {
      query.append('authority', authority);
    }
    if (source) {
      query.append('source', source);
    }
    if (entityType) {
      query.append('entityType', entityType);
    }
    if (schemaVersionMajor) {
      query.append('schemaVersionMajor', schemaVersionMajor);
    }
    if (schemaVersionMinor) {
      query.append('schemaVersionMinor', schemaVersionMinor);
    }
    return query.toString();
  };

  return (
    <S.Wrapper>
      <S.Intro>
        Request parameters are colored to match the colored fields on the search interface
      </S.Intro>
      <S.HeaderContainer>
        <S.Header>Headers</S.Header>
        <S.Row>
          <strong>Accept:</strong> application/json, text/plain, */*
        </S.Row>
        <S.Row>
          <strong>Authorization:</strong> &lt;Bearer Token&gt;
        </S.Row>
        <S.Row>
          <strong>Content-Type:</strong> application/json; charset=UTF-8
        </S.Row>
        <S.Row props={{ color: requestFields.dataPartition }}>
          <strong>data-partition-id:</strong> {dataPartitionId}
        </S.Row>
      </S.HeaderContainer>
      <S.BodyContainer>
        <S.Header>Query params</S.Header>
        <S.Row props={{ color: requestFields.kind }}>
          <strong>authority:</strong> {authority},
        </S.Row>
        <S.Row props={{ color: requestFields.query }}>
          <strong>entityType:</strong> {entityType},
        </S.Row>
        <S.Row props={{ color: requestFields.limit }}>
          <strong>limit:</strong> {limit},
        </S.Row>
        <S.Row props={{ color: requestFields.offset }}>
          <strong>offset:</strong> {offset},
        </S.Row>
        <S.Row props={{ color: requestFields.latest }}>
          <strong>latestVersion:</strong> {latestVersion},
        </S.Row>
        <S.Row props={{ color: requestFields.sort }}>
          <strong>schemaVersionMajor:</strong> {schemaVersionMajor},
        </S.Row>
        <S.Row props={{ color: requestFields.sort }}>
          <strong>schemaVersionMinor:</strong> {schemaVersionMinor},
        </S.Row>
        <S.Row props={{ color: requestFields.scope }}>
          <strong>scope:</strong> {scope},
        </S.Row>
        <S.Row props={{ color: requestFields.aggregation }}>
          <strong>source:</strong> {source},
        </S.Row>
        <S.Row props={{ color: requestFields.status }}>
          <strong>status:</strong> {status}
        </S.Row>
      </S.BodyContainer>
      <S.CurlWrapper>
        <S.Header>Curl command</S.Header>
        <ButtonWithIcon iconName="copy" label="Copy with credentials" onClick={copyCurlCommand} />
        <S.CurlCommandContainer>
          {`curl  --request GET --url '/api/schema-service/v1/schema?${getParams()}' \\`}
          <S.IndentLevelOne>{`--header 'accept: application/json' \\`}</S.IndentLevelOne>
          <S.IndentLevelOne>{`--header 'authorization: <Bearer Token>' \\`}</S.IndentLevelOne>
          <S.IndentLevelOne>{`--header 'content-type: application/json'`}</S.IndentLevelOne>
        </S.CurlCommandContainer>
      </S.CurlWrapper>
      <CommandPreview
        style={{ maxWidth: '100%' }}
        subtitle="Get schema details"
        copyCommand={copyGetDetailsCommand}
        command={[
          {
            indentLevel: 0,
            text: `curl --method GET --url "/api/schema-service/v1/schema/{id}" \\`,
          },
          { indentLevel: 0, text: `--header 'accept: application/json' \\` },
          { indentLevel: 0, text: `--header 'authorization: <Bearer Token>' \\` },
          { indentLevel: 0, text: `--header 'data-partition-id': "${dataPartitionId}"'` },
        ]}
      />
      <CommandPreview
        style={{ maxWidth: '100%' }}
        subtitle="Edit/Create schema"
        description={`Only for new schemas, or with 'development' status`}
        copyCommand={copyEditSchemaCommand}
        command={[
          { indentLevel: 0, text: `curl --method PUT --url "/api/schema-service/v1/schema" \\` },
          { indentLevel: 0, text: `--header 'accept: application/json' \\` },
          { indentLevel: 0, text: `--header 'authorization: <Bearer Token>' \\` },
          { indentLevel: 0, text: `--header 'data-partition-id': "${dataPartitionId}"'` },
          { indentLevel: 0, text: `--data '<SCHEMA_DATA>'` },
        ]}
      />
    </S.Wrapper>
  );
}
