import ModalFooter from 'components/Modal/ModalFooter';
import * as S from './Response.style';

export interface IResponse {
  recordCount: number;
  recordIds: string[];
  skippedRecordIds: [];
  recordIdVersions: string[];
}

export default function Response({
  response: { recordCount, recordIds, skippedRecordIds, recordIdVersions } = {
    recordCount: 0,
    recordIds: [],
    skippedRecordIds: [],
    recordIdVersions: [],
  },
  onClose,
  onApply,
}: {
  response?: IResponse;
  onClose: () => void;
  onApply: () => void;
}) {
  return (
    <S.Wrapper>
      <S.Body>
        <S.DataRow>
          Modified records: <strong>{recordCount}</strong>
        </S.DataRow>
        <S.DataRow>
          Record Ids: <strong>{recordIds.join(', ')}</strong>
        </S.DataRow>
        {skippedRecordIds.length > 0 && (
          <S.DataRow>
            Skipped records: <strong>{skippedRecordIds.join(', ')}</strong>
          </S.DataRow>
        )}
        <S.DataRow>
          Record versions: <strong>{recordIdVersions.join(', ')}</strong>
        </S.DataRow>
      </S.Body>
      <ModalFooter onApply={onApply} applyLabel={'Copy'} onCancel={onClose} />
    </S.Wrapper>
  );
}
