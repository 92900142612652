import styled from 'layout/theme/styled';

export const Wrapper = styled('div')({
  minHeight: 'calc(100vh - 224px)',
  padding: '12px 6px 0',
  margin: '8px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const ErrorMessage = styled('p')(({
    theme: {
      palette: { error },

    },
  })=>({
        margin: 'auto',
        color: error.main,
        fontSize: '32px',
        fontWeight: 600,
      }));
