import styled from 'layout/theme/styled';

export const Wrapper = styled('div')<{ props: { color?: string } }>(({ props: { color } }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: color,
  padding: '0 0 0 8px',
  height: '32px',
  margin: '6px 0 0'
}));

export const Label = styled('label')(
  ({
    theme: {
      typography: { body1 },
    },
  }) => ({
    ...body1,
    margin: '0 4px 0 0',
  })
);
