import { PLATFORM_STATE, IServiceData } from 'types/Platform';
import { API } from '@aws-amplify/api';

interface IStatusResponse {
  Seconds: number;
  Timestamp?: string;
  Status: 'On' | 'Off' | 'Pending';
}

const tooltipMessage = {
  [PLATFORM_STATE.ON]: 'All services are running correctly',
  [PLATFORM_STATE.OFF]: 'OSDU Data Platform is turned off',
  [PLATFORM_STATE.ERROR]: 'Platform state is unknown due to error',
  [PLATFORM_STATE.TURNING_ON]: 'OSDU Data Platform is running, but some services are still booting',
  [PLATFORM_STATE.PENDING]: '',
  [PLATFORM_STATE.CHECKING]: '',
};

export interface IPlatformStatusResponse {
  PlatformState: PLATFORM_STATE;
  Timestamp?: string;
  tooltipMessage: string;
  serviceStatuses: {
    status: number;
    name: string;
    isHidden?: boolean | undefined;
  }[];
}

export async function getPlatformStatus(): Promise<IPlatformStatusResponse> {
  try {
    const response: IStatusResponse = await API.post('platform-state-api', '/', {
      body: { Switch: 'Get_Timer' },
    });
    const { Status, Timestamp } = response;

    const serviceStatuses: IServiceData[] = await API.get('platform-state-api', '/healthcheck', {});
    const runningServices = serviceStatuses.reduce(
      (accumulator, { status }) => (status === 200 ? accumulator + 1 : accumulator),
      0
    );
    const areAllServicesOn = serviceStatuses.length === runningServices;
    let PlatformState = Status.toUpperCase() as PLATFORM_STATE;
    if (PlatformState === PLATFORM_STATE.ON && !areAllServicesOn) {
      PlatformState = PLATFORM_STATE.TURNING_ON;
    }
    return {
      PlatformState: PlatformState,
      serviceStatuses,
      Timestamp: Timestamp,
      tooltipMessage: tooltipMessage[PlatformState],
    };
  } catch (err: unknown) {
    return {
      PlatformState: PLATFORM_STATE.ERROR,
      serviceStatuses: [],
      tooltipMessage: tooltipMessage[PLATFORM_STATE.ERROR],
    };
  }
}

export async function ChangePlatformState(state: 'On' | 'Off', hours?: number) {
  let body = {};
  if (state === 'Off') {
    body = { Switch: state };
  }

  if (state === 'On' && hours === undefined) {
    body = { Switch: state };
  }

  if (state === 'On' && hours !== undefined) {
    body = { Switch: 'Set_Timer', Hours: Number(hours) };
  }
  const response = await await API.post('platform-state-api', '/', { body });
  const updatedState = response['Status'].toLowerCase();
  const currentState = {
    PlatformState: updatedState === 'on' ? PLATFORM_STATE.ON : PLATFORM_STATE.OFF,
  };
  return currentState;
}
