import {
    FIND_WELLBORES_FAIL,
    FIND_WELLBORES_START,
    FIND_WELLBORES_SUCCESS,
    FIND_WELLS_BY_NAME_FAIL,
    FIND_WELLS_BY_NAME_START,
    FIND_WELLS_BY_NAME_SUCCESS,
    WellSearchActionTypes,
} from './types';
import {GeoJSON, LatLng} from 'leaflet';

export interface WellSearchState {
    /** initial or last searched name. */
    searchName: string;

    /** well search api request progress flag */
    areWellsSearching: boolean;

    /** proof that a search request was made */
    areWellsSearched: boolean;

    /** any error, occured during a search request */
    searchError?: Error;

    /** a resulted list of wells after a successful rearch request */
    foundWells: WellSearchResponse[];
}

const initialState: WellSearchState = {
    searchName: 'BIR',
    areWellsSearching: false,
    areWellsSearched: false,
    foundWells: [],
    searchError: undefined,
};

export interface WellSearchResponse {
    facilityName: string;
    resourceId: string;

    /** a geo location, will be useful to reveal the well on the map */
    location: LatLng;

    /** wellbores fetch api request progress flag */
    areWellboresLoading: boolean;

    /** proof that a wellbores fetch request was made */
    areWellboresLoaded: boolean;

    /** any error, occured during a wellbores fetch request */
    wellboresError?: Error;

    /** a list of wellbores fetched from the backend for a certain well */
    wellbores: Wellbore[];
}

export interface Wellbore {
    facilityName: string;
    resourceId: string;
}

export const wellSearchReducer = (
    state: WellSearchState = initialState,
    action: WellSearchActionTypes
): WellSearchState => {
    switch (action.type) {
        case FIND_WELLS_BY_NAME_START:
            return {
                ...state,
                searchName: action.payload,
                areWellsSearching: true,
                areWellsSearched: false,
                searchError: undefined,
            };
        case FIND_WELLS_BY_NAME_SUCCESS:
            return {
                ...state,
                areWellsSearching: false,
                areWellsSearched: true,
                foundWells: action.payload.wells.map(
                    (well: any): WellSearchResponse => ({
                        resourceId: well.resource_id,
                        facilityName: well.facility_name,
                        location: GeoJSON.coordsToLatLng(well.location.coordinates),
                        areWellboresLoading: false,
                        areWellboresLoaded: false,
                        wellbores: [],
                        wellboresError: undefined,
                    })
                ),
            };
        case FIND_WELLS_BY_NAME_FAIL:
            return {
                ...state,
                areWellsSearching: false,
                areWellsSearched: true,
                searchError: action.payload,
            };

        case FIND_WELLBORES_START:
            return {
                ...state,
                foundWells: state.foundWells.map(w => {
                    return action.payload.includes(w.resourceId)
                        ? {
                            ...w,
                            wellbores: [],
                            areWellboresLoading: true,
                            areWellboresLoaded: false,
                            wellboresError: undefined,
                        }
                        : w;
                }),
            };
        case FIND_WELLBORES_SUCCESS:
            return {
                ...state,
                foundWells: state.foundWells.map(w => {
                    return action.payload.wellId === w.resourceId
                        ? {
                            ...w,
                            wellbores: action.payload.result.wellbores.map(
                                (d: any): Wellbore => ({
                                    resourceId: d.resource_id,
                                    facilityName: d.facility_name,
                                })
                            ),
                            areWellboresLoading: false,
                            areWellboresLoaded: true,
                        }
                        : w;
                }),
            };
        case FIND_WELLBORES_FAIL:
            return {
                ...state,
                foundWells: state.foundWells.map(w => {
                    return action.payload.wellId === w.resourceId
                        ? {
                            ...w,
                            areWellboresLoading: false,
                            areWellboresLoaded: true,
                            wellboresError: action.payload.err,
                        }
                        : w;
                }),
            };
        default:
            return state;
    }
};
